import React from "react";
import { Link } from "react-router-dom";

import SearchIcon from "../../assets/images/mhc-search-icon.svg";

const Iprops = {
  placholder: "",
  btnDisplay: true || false,
  hospitalList: null,
  specialitiesList: null,
  clinicList: null
};

class SearchForm extends React.Component {
  constructor(props = Iprops) {
    super(props);
    this.state = {
      dropdown: false,
      closebtn: false,
      searchvalue: ""
    };
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handledropDown.bind(this);
    this.handleChange.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handledropDown() {
    this.setState({ dropdown: true });
    this.setState({ closebtn: true });
  }

  handleCloseDropdown() {
    this.setState({ dropdown: false });
    this.setState({ closebtn: false });
    // this.setState(this.state.searchvalue = '');
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({
        dropdown: false,
        closebtn: false,
        searchvalue: ''
      });
    }
  }

  handleChange(e) {
    this.setState({ searchvalue: e.target.value });
    // this.props.hospitalList = this.props.hospitalList.filter((el) => {
    //   return el.hospitals.some((f) => {
    //     return f.name.trim().toLowerCase() === this.state.searchvalue.trim().toLowerCase();
    //   });
    // });
  }

  render() {
    return (
      <React.Fragment>
        <div className="Mhc-search w-100" ref={this.wrapperRef}>
          <div className="input-group ">
            <span className="input-group-text">
              <img src={SearchIcon} alt="" />
            </span>
            <input
              type="text"
              onKeyUp={() => this.handledropDown()}
              value={this.state.searchvalue}
              onChange={(e) => this.handleChange(e)}
              className="form-control"
              id=""
              placeholder={this.props.placeholder} />
          </div>
          <button className={`btn btn-primary  ${this.props.btnDisplay === true ? "d-block" : "d-none"}`} onClick={() => this.handledropDown()}>
            Search
          </button>
          <button className={`search-close mrr ${this.state.closebtn === false ? "d-none" : "d-block"}`} onClick={() => this.handleCloseDropdown()}>
            <i className="mhc-icon icon-cancel text-primary"></i>
          </button>

          <div className={`mhc-search-dropdown search-for-booking ${this.state.dropdown === false ? "d-none" : "d-block"}`}>
            <div className="search-header">Hospitals</div>
            <div className="search-body">
              <ul>
                {
                  this.props.hospitalList && this.props.hospitalList.map((hospital, index) => {
                    return (
                      <li key={index}>
                        <Link
                          to={`/hospital-consult/${hospital.hospitals[0].id}`}
                          onClick={() => this.handleCloseDropdown()}>
                          <div className="d-flex">
                            <div className="text-cricle">{hospital.hospitals[0].name.charAt(0)}</div>
                            <div>
                              <p className="m-0">{hospital.hospitals[0].name}</p>
                              <span>{hospital.name}</span>
                            </div>
                          </div>
                        </Link>
                      </li>
                    )
                  })
                }
              </ul>
            </div>

            <div className="search-header">Clinics</div>
            <div className="search-body">
              <ul>
                {
                  this.props.clinicList && this.props.clinicList.map((clinic, index) => {
                    return (
                      <li key={index}>
                        <Link
                          to={`/book-consult/consult-doctor/15/${clinic.id}`} onClick={() => this.handleCloseDropdown()}>
                          <div className="d-flex align-items-center">
                            <div className="text-cricle">{clinic.name.charAt(0)}</div>
                            <div>
                              <p className="m-0">{clinic.name}</p>
                            </div>
                          </div>
                        </Link>
                      </li>
                    )
                  })
                }
              </ul>
            </div>

            <div className="search-header">Doctor</div>
            {/* <div className="search-body">
              <ul>
                <li>
                  <a href="#" onClick={() => this.handleCloseDropdown()}>
                    <div className="d-flex">
                      <div className="text-cricle">A</div>
                      <div>
                        <p className="m-0"> Ashima</p>
                        <span>Neuro Physiotherapist</span>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="#" onClick={() => this.handleCloseDropdown()}>
                    <div className="d-flex">
                      <div className="text-cricle">A</div>
                      <div>
                        <p className="m-0"> Ashoo Agarwal</p>
                        <span>Orthopedist</span>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SearchForm;
