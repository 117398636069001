import $ from 'jquery';

// For Check value is Number
export function isNumeric(value) {
    return /^-?\d+$/.test(value);
}
/// function for capitalize word
export let capitalize = str => {

    // str.charAt(0).toUpperCase() +  str.slice(1)

    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};


export let capitalizeFirst = str => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

// age Calculater
  export function calculateAge(dob) {

    if(dob != null){
        let date = dob.split("/");
        let getDOB = date[1]+'/'+date[0]+'/'+date[2];
        var today = new Date();
        var birthDate = new Date(getDOB);
        var age_now = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        let yrs = ' Yr';

        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) age_now--;

        if(age_now > 0){
            yrs = ' Yrs';
        } 
        return age_now + yrs;
    }
  };



  export const jQueryLabels = thisElem => {
    $(document).find('.form-select').each(function () {
        var Slabel = $(this).find('option:selected').attr('label');
        if (typeof Slabel === 'undefined') {
            $(this).parent().addClass("notselected");
        } else {
            $(this).parent().removeClass("notselected");
        }
    })
    $(document).on('focusin', '.form-select', function () {
        var getEle = $(this).prop('tagName');
        if (getEle === 'SELECT') {
            $(this).on('change', function () {
                var Slabel = $(this).find('option:selected').attr('label');
                if (typeof Slabel === 'undefined') {
                    $(this).parent().addClass("notselected");
                } else {
                    $(this).parent().removeClass("notselected");
                }
            });
        }
    })



}


