import axios from "axios";
import { primaryToken, secondaryToken } from "../constants/app-constants";
import axiosClient from "./api-handlers";
import axiosCMS from "./cms-api-handlers";
import axiosMHClient from "./second-api-handlers";

const httpHeaderWithPrimaryToken = {
  headers: {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "aggregatorType": "market_place",
    "apitoken": primaryToken,
  },
};

const httpHeaderWithSecondaryToken = {
  headers: {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "aggregatorType": "opd_emr",
    "apitoken": secondaryToken,
  },
};

export async function getImageFromCMS(page, section) {
  const response = await axiosCMS.get(`imgs/${page}/${section}`);
  return response;
}

export async function getTextFromCMS(page, section) {
  const response = await axiosCMS.get(`txts/${page}/${section}`);
  return response;
}

export async function countries() {
  const response = await axiosClient.get(`/template-parts/order-medicine/countries.json`);
  return response;
}

export async function indianCities(diagnostic, page) {
  const response = await axiosClient.get(`/patients/web/v2/patients/indian-city?is_diagnostic=${diagnostic}&page=${page}`);
  return response;
}
export async function searchindianCities(search) {
  const response = await axiosClient.get(`/patients/web/v2/patients/get-indian-city?search=${search}`,httpHeaderWithPrimaryToken);
  return response;
}

export async function configurations() {
  const response = await axiosClient.get(`/patients/web/v2/configurations`);
  return response;
}

export async function hospitalByCity(lat, lng) {
  const response = await axiosClient.get(`/patients/web/v2/hospitals-by-city?lat=${lat}&lng=${lng}`);
  return response;
}

export async function specialitiesListByHospital(id) {
  const response = await axiosClient.get(`/patients/web/v2/specialities?hospital_id=${id}`);
  return response;
}
export async function getAllSpecialities(page){
  let url = `/patients/web/v2/patients/all-specialities?page=${page}`
  const response = await axiosClient.get(url,httpHeaderWithPrimaryToken);
  return response;

}
export async function getAllDoctors(keyword,type){
  
  let url = `/patients/web/v2/specialities/doctors/all-doctors?keyword=${keyword}&type=${type}`
  const response = await axiosClient.get(url);
  return response;
}
export async function getAllDoctorsBySpecialitiesId(specialId,keyword=''){
  let url = `/patients/web/v2/specialities/${specialId}/doctors?`
  const response = await axiosClient.get(url,httpHeaderWithPrimaryToken);
  return response;
}
export async function searchDoctorBySpecility(specialId,keyword=''){
  let url = `/patients/web/v2/specialities/${specialId}/doctors?keyword=${keyword}`
  const response = await axiosClient.get(url,httpHeaderWithPrimaryToken);
  return response;
}
export async function getAllClinics(){
  let url = `/patients/web/v2/patients/all-clinics`
  const response = await axiosClient.get(url,httpHeaderWithPrimaryToken);
  return response;

}

export async function getHospitals(id) {
  const response = await axiosClient.get(`/patients/web/v2/hospitals/${id}`);
  return response;
}

export async function clinicDistance() {
  const response = await axiosClient.get(`/patients/web/v2/patinets/clinic-distance`);
  return response;
}

export async function getDocAvailability() {
  const response = await axiosClient.get(`/patients/web/v2/doctors/2054/availability`);
  return response;
}

export async function getProfile(JWT) {
  const response = await axiosClient.get(
    `/patients/web/v2/patients/profile`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}

export async function getProfileWithPayload(payload, token) {
  const response = await axiosClient.get(
    `/patients/web/v2/patients/profile` + payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    }
  );
  return response;
}

export async function familyMember(familyMember) {
  const response = await axiosClient.get(`/specialities/75/doctors/2057/user/account/${familyMember}`);
  return response;
}

export async function timeslots(time) {
  const response = await axiosClient.get(`/patients/web/v2/doctors/1630/availability-timeslots?${time}`);
  return response;
}

export async function pincodeByCity(city) {
  const response = await axios.get(`https://api.postalpincode.in/postoffice/${city}`);
  return response;
}

export async function indianPincode(pincode, vendor_id) {
  const response = await axiosClient.get(
    vendor_id ? `/patients/web/v2/patients/get-indian-pincode?pincode=${pincode}&vendor_id=${vendor_id}` : `/patients/web/v2/patients/get-indian-pincode?pincode=${pincode}`,
    httpHeaderWithPrimaryToken);
  return response;
}

export async function getLatLongDetail(lat, long) {
  if (!lat || !long) {
    return;
  }
  const response = await axiosClient.get(`/patients/web/v2/maps/geolatlong/${lat}/${long}`,
    httpHeaderWithSecondaryToken
  );
  return response;
}

export async function getTimeslotByHospital(vendor_id, city_id, visit_date, pincode, locality = "") {
  const response = await axiosClient.get(`/patients/web/v2/patients/lalpath-slots-availability?vendor_id=${vendor_id}&city_id=${city_id}&visit_date=${visit_date}&pincode=${pincode}&zipcode=${pincode}&locality=${locality}`,
    httpHeaderWithPrimaryToken);
  return response;
}

export async function thyrocareDetail(id, pincode) {
  const response = await axiosClient.get(`/patients/web/v2/patients/thyrocare-test-Details?diagnosis_id=${id}&pincode=${pincode}`,
    httpHeaderWithPrimaryToken);
  return response;
}

export async function thyrocareList(type, category, pincode, page, keyword) {
  keyword = keyword ? "&keyword=" + keyword : type === 2 ? "&category=" + category : "";
  const response = await axiosClient.get(`/patients/web/v2/patients/thyrocare-list-products?type=${type}&pincode=${pincode}&page=${page}${keyword}`,
    httpHeaderWithPrimaryToken);
  return response;
}

export async function testDetails(payload) {
  const searchParams = new URLSearchParams(payload);
  const response = await axiosClient.get(`/patients/web/v2/patients/get-test-details?` + searchParams,
    httpHeaderWithPrimaryToken);
  return response;
}

export async function vendorCities(id) {
  const response = await axiosClient.get(`/patients/web/v2/patients/lalpath-cities?vendor_id=${id}`,
    httpHeaderWithPrimaryToken);
  return response;
}

export async function getOneMGPincode(pincode) {
  const response = await axiosClient.get(`/patients/ios/v2/website/get-onemgpincode?pincode=${pincode}`,
    httpHeaderWithPrimaryToken);
  return response;
}

export async function getOrderMedicinePincode(pincode) {
  const response = await axiosClient.get(`/patients/ios/v2/website/get-medicine-order-pincode?pincode=${pincode}`,
    httpHeaderWithPrimaryToken);
  return response;
}

export async function getHomecareCategory() {
  const response = await axiosClient.get(`/patients/ios/v2/test/homecare-category`);
  return response;
}

export async function getHospitalListingByCat(catId) {
  const response = await axiosClient.get(`/patients/ios/v2/hospitals-by-city?category_id=${catId}&is_homecare=1`);
  return response;
}

export async function packageByHospital(catId, hospitalId, page, type) {
  const response = await axiosClient.get(`/patients/ios/v2/test-list-by-category?category_id=${catId}&hospital_id=${hospitalId}&page=${page}&type=${type}`);
  return response;
}

export async function packageDetails() {
  const response = await axiosClient.get(`/patients/ios/v2/test/hospital_id/28/test/75`);
  return response;
}

export async function packageAvailability(hospital_id,test_id) {
  const response = await axiosClient.get(`/patients/ios/v2/test?hospital_id=28&test_id=75`,httpHeaderWithPrimaryToken);
  return response;
}

export async function doctorAvailability(specialId, hospitalId, keyword) {
  keyword = keyword !== "" ? "&keyword=" + keyword : "";
  const response = await axiosClient.get(`/patients/web/v2/specialities/${specialId}/doctors?hospital_id=${hospitalId}${keyword}`);
  return response;
}

export async function doctorImmediateAvailability(specialId, hospitalId) {
  let url = `/patients/web/v2/specialities/${specialId}/immediate-doctors`
  if(hospitalId){
    url+= `?hospital_id=${hospitalId}`
  }
  const response = await axiosClient.get(url);
  return response;
}

export async function specialityInfoById(Id) {
  const response = await axiosClient.get(`/patients/web/v2/specialities/${Id}`);
  return response;
}

export async function doctorProfileById(docId, hospitalId) {
  const response = await axiosClient.get(`/patients/web/v2/doctors/${docId}?hospital_id=${hospitalId}`);
  return response;
}

export async function doctorAvailabilityById(Id) {
  const response = await axiosClient.get(`/patients/web/v2/doctors/${Id}/availability`);
  return response;
}

export async function doctorTimeSlots(docId, hospitalId, date, consultT) {
  const response = await axiosClient.get(`/patients/web/v2/doctors/${docId}/availability-timeslots?hospital_id=${hospitalId}&date=${date}&consult_type=${consultT}`);
  return response;
}

export async function carePackages() {
  const response = await axiosMHClient.get(`/patients/ios/v2/special-health-offers`);
  return response;
}
export async function getPackageDetail(hospitalId,testId) {
  // https://dev.appapis.desktop.myhealthcare.co/api/patients/ios/v2/test/hospital_id/28/test/75
  const response = await axiosClient.get(`/patients/ios/v2/test/hospital_id/${'1800'}/test/${'1555'}`,httpHeaderWithPrimaryToken);
  return response;
}

export async function getClinicDistance() {
  const response = await axiosMHClient.get(`/patients/web/v2/patinets/clinic-distance`);
  return response;
}
export async function fetchClinicList(){
  // https://prerelease.mhappapis.vc.myhealthcare.co/api/patients/web/v2/specialities?hospital_id=15 - api given by sarthak to integrate.
  const response = await axiosMHClient.get(`/patients/web/v2/specialities?hospital_id=15/`);
  return response
}
export async function fetchPartnerHospitals(){
  // https://prerelease.mhappapis.vc.myhealthcare.co/api/patients/web/v2/specialities?hospital_id=15 - api given by sarthak to integrate.
  const response = await axiosCMS.get(`https://betacms.myhealthcare.co/api/partner_hospitals`);
  return response
}
export async function getAllBlogs() {
  const response = await axiosClient.get(`https://betacms.myhealthcare.co/api/show_allblogs/`);
  return response;
}
export async function getAllInsights() {
  const response = await axiosClient.get(`https://betacms.myhealthcare.co/api/imgs/7/1`);
  return response;
}
export async function getAboutUs() {
  const response = await axiosClient.get(`https://betacms.myhealthcare.co/api/show_aboutus/`);
  return response;
}
export async function getBoardMembers() {
  const response = await axiosClient.get(`https://betacms.myhealthcare.co/api/imgs/2/2`);
  return response;
}
export async function getTeamMembers() {
  const response = await axiosClient.get(`https://betacms.myhealthcare.co/api/imgs/2/6`);
  return response;
}
export async function getDoctorReviews() {
  const response = await axiosClient.get(`https://betacms.myhealthcare.co/api/txts/2/5`);
  return response;
}
export async function getBannerData() {
  const response = await axiosClient.get(`https://betacms.myhealthcare.co/api/imgs/2/1`);
  return response;
}

export async function getInvestors() {
  const response = await axiosClient.get(`https://betacms.myhealthcare.co/api/imgs/2/3`);
  return response;
}

export async function getAllFAQs() {
  const response = await axiosClient.get(`https://betacms.myhealthcare.co/api/show_faqs`);
  return response;
}

export async function getAllCareer() {
  const response = await axiosClient.get(`https://betacms.myhealthcare.co/api/show_landingpage/lp-1`);
  return response;
}
export async function getAllJobListing() {
  const response = await axiosClient.get(`https://betacms.myhealthcare.co/api/alljobs_listing`);
  return response;
}
export async function getJobDataBySlug(slug) {
  const response = await axiosClient.get(`https://betacms.myhealthcare.co/api/singlejobs_listing/${slug}`);
  return response;
}
export async function getLandingPageBySlug(slug) {
  const response = await axiosClient.get(`https://betacms.myhealthcare.co/api/show_landingpage/${slug}`);
  return response;
}
export async function getBlogDetailByTitle(title) {
  const response = await axiosClient.get(`https://betacms.myhealthcare.co/api/show_singleblog/${title}`);
  return response;
}
export async function getLatestBlogs() {
  const response = await axiosClient.get(`https://betacms.myhealthcare.co/api/show_letestblog`);
  return response;
}
export async function getPopularBlogs() {
  const response = await axiosClient.get(`https://betacms.myhealthcare.co/api/show_allblogs`);
  return response;
}
export async function getHealthCareBlogs() {
  const response = await axiosClient.get(`https://betacms.myhealthcare.co/api/show_healthblogs`);
  return response;
}
export async function getDownloadURLs() {
  let url = 'https://betacms.myhealthcare.co/api/myhealthcare_downloads'
  const response = await axiosClient.get(url);
  return response;
}
export async function clinicSearchAPI(keyword, type) {
  if (keyword === "") return false;
  

  const response = await axiosMHClient.get(
    `/patients/web/v2/search?hospital_id=15&keyword=${keyword}`,
    
  );
  return response;
}