import React, { useState } from "react";
import { Accordion, Button, Form, Modal } from "react-bootstrap";
import SearchForm from "../../component/search/index";
import { dateFormatIntoDDMMYYYY } from "../../constants/app-constants";
import moment from "moment";
import { searchindianCities } from "../../actions/get-calls";
const FilterDoctorModal = ({
  filterModal,
  onHide,
  allCities,
  listInnerRef,
  changeDistance,
  onScroll,
  allDistances,
  onAvailibilityChange,
  onGenderChange,
  closeModal,
  setStartDate,
  setEndDate,
  filterDoctor,
  onCityChange,
  startDate,
  endDate,
  values,
  setAvailibilityType,
  availabilityType,
  resetValues,
}) => {
  console.log("availabilityType: ", availabilityType);
  console.log(values);
  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
  // const [allCities, setAllCities] = useState([]);
  const [searchedCity, setSearchedCity] = useState("");
  const [searchedValues, setSearchedValues] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // async function getCities() {
  //   if (currentCityPage && hasMore) {
  //     const cityList = await indianCities(1, currentCityPage);

  //     if (cityList && cityList.data) {
  //       setAllCities([...allCities, ...cityList.data.cities]);
  //       if (cityList.data.pagination.links.next) {
  //         let cPage = currentCityPage + 1;
  //         setCurrentCityPage(cPage);
  //         setHasMore(true);
  //       } else {
  //         setHasMore(false);
  //       }
  //     }
  //   }
  // }
  const handleCitySearch = async (e) => {
    let searchValue = e.target.value;
    setSearchedCity(searchValue);
    if (searchValue && searchValue.length > 2) {
      // console.log("HERE");
      setIsLoading(true);
      const cityList = await searchindianCities(searchValue);
      console.log("cityList: ", cityList);
      if (cityList && cityList.data && cityList.data.cities) {
        setSearchedValues(
          cityList.data.cities.map((e) => {
            return {
              ...e,
              label: e.name,
              value: e.id,
              latitude: e.lat,
              longitude: e.long,
            };
          })
        );
      } else {
        setSearchedValues([]);
      }
      setIsLoading(false);
    }else {
      setSearchedValues([]);
    }
  };
  return (
    <Modal
      show={filterModal}
      onHide={() => {
        setShowCustomDatePicker(false);
        onHide();
      }}
      size="xl"
      onShow={() => {
        // setStartDate(null);
        // setEndDate(null)
        setShowCustomDatePicker(false);
      }}
    >
      <Modal.Body closeButton>
        <Accordion defaultActiveKey="0" className="mhc-mobile-tabs bg-white">
          <Form onSubmit={filterDoctor}>
            <div className="row mhc-fliter-border-bottom">
              <div className="col-lg-3 mhc-fliter-border-right">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>City</Accordion.Header>
                  <Accordion.Body className="bg-white border-top-0">
                    <h3>City</h3>
                    {/* <SearchForm
                      placeholder="Search Specialities/Doctors"
                      btnDisplay={false}
                    /> */}
                    <input
                      type="text"
                      placeholder="Search City..."
                      onChange={handleCitySearch}
                    />
                    <div
                      className="mhc-filter mt-4"
                      onScroll={onScroll}
                      ref={listInnerRef}
                    >
                      
                      {
                    [...(searchedValues.length>0 ? searchedValues: allCities)].map((item, index) => {
                            return (
                              <div
                                className="form-check checkboxRadiobtn mb-3"
                                key={index}
                              >
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={item.id}
                                  name="city[]"
                                  id={`city${index}`}
                                  checked={values.cities.includes(item.id)}
                                  onChange={(e) => {
                                    onCityChange(e, item);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`city${index}`}
                                >
                                  {item.name}
                                </label>
                              </div>
                            );
                          })
                        }
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </div>
              {/* end row */}
              <div className="col-lg-3 mt-3 mt-lg-0 mhc-fliter-border-right">
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Distance</Accordion.Header>
                  <Accordion.Body className="bg-white border-top-0">
                    <h3 className="mb-3">Distance</h3>

                    <div className="mhc-filter">
                      {allDistances
                        ? allDistances.map((item, index) => {
                            return (
                              <div
                                className="form-check checkboxRadiobtn mb-3"
                                key={item.id}
                              >
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="distance"
                                  onChange={(e) => {
                                    changeDistance(e, item);
                                  }}
                                  id={`distance${item.id}`}
                                  checked={values.distance === index}
                                />
                                <label
                                  className="form-check-label"
                                  for={`distance${item.id}`}
                                >
                                  Up to {item.value} kms
                                </label>
                              </div>
                            );
                          })
                        : ""}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </div>
              {/* end row */}
              <div className="col-lg-3 mt-3 mt-lg-0 mhc-fliter-border-right">
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Availability</Accordion.Header>
                  <Accordion.Body className="bg-white border-top-0">
                    <h3>Availability</h3>
                    <div className="">
                      <div className="form-check checkboxRadiobtn mb-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Availability"
                          id="Availability1"
                          checked={availabilityType === "now"}
                          onChange={(e) => {
                            setShowCustomDatePicker(false);
                            setAvailibilityType("now");
                            if (e.target.checked) {
                              const now = new Date();
                              setStartDate(dateFormatIntoDDMMYYYY(now));
                              setEndDate(dateFormatIntoDDMMYYYY(now));
                            }
                          }}
                        />
                        <label className="form-check-label" for="Availability1">
                          Now
                        </label>
                      </div>
                      <div className="form-check checkboxRadiobtn mb-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Availability"
                          checked={availabilityType === "today"}
                          onChange={(e) => {
                            setShowCustomDatePicker(false);
                            if (e.target.checked) {
                              setAvailibilityType("today");
                              const today = new Date();
                              setStartDate(dateFormatIntoDDMMYYYY(today));
                              setEndDate(dateFormatIntoDDMMYYYY(today));
                            }
                          }}
                          id="Availabilit2"
                        />
                        <label className="form-check-label" for="Availabilit2">
                          Today
                        </label>
                      </div>
                      <div className="form-check checkboxRadiobtn mb-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Availability"
                          checked={availabilityType === "tomorrow"}
                          onChange={(e) => {
                            setShowCustomDatePicker(false);
                            setAvailibilityType("tomorrow");
                            if (e.target.checked) {
                              const tomorrow = new Date(
                                Date.now() + 1 * 24 * 60 * 60 * 1000
                              );
                              setStartDate(dateFormatIntoDDMMYYYY(tomorrow));
                              setEndDate(dateFormatIntoDDMMYYYY(tomorrow));
                            }
                          }}
                          id="Availability3"
                        />
                        <label className="form-check-label" for="Availability3">
                          Tomorrow
                        </label>
                      </div>
                      <div className="form-check checkboxRadiobtn mb-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Availability"
                          onChange={(e) => {
                            setAvailibilityType("next-2");

                            setShowCustomDatePicker(false);
                            onAvailibilityChange(e);
                          }}
                          checked={availabilityType === "next-2"}
                          id="Availability4"
                        />
                        <label className="form-check-label" for="Availability4">
                          Next 2 days
                        </label>
                      </div>
                      <div className="form-check checkboxRadiobtn">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Availability"
                          checked={availabilityType === "custom"}
                          id="Availability5"
                          onChange={(e) => {
                            setShowCustomDatePicker(e.target.checked);
                            if (e.target.checked) {
                              setAvailibilityType("custom");
                              setStartDate(moment().format("DD/MM/yyyy"));
                              setEndDate(moment().format("DD/MM/yyyy"));
                            }
                          }}
                        />
                        <label htmlFor="Dob" className="dob">
                          Custom Date
                        </label>

                        {availabilityType === "custom" && (
                          <div className="bg-grey p-3">
                            <p
                              className="text-muted"
                              style={{ fontWeight: 500 }}
                            >
                              Select Availability Date
                            </p>
                            <div className="row mb-2 ">
                              <label>From</label>
                              <div className="form-group col-12">
                                <input
                                  type="date"
                                  className="form-control"
                                  name="Sdate"
                                  placeholder="Start Date"
                                  value={moment(startDate, "DD/MM/YYYY").format(
                                    "YYYY-MM-DD"
                                  )}
                                  onChangeCapture={(e) => {
                                    setStartDate(
                                      moment(e.target.value).format(
                                        "DD/MM/yyyy"
                                      )
                                    );
                                  }}
                                />
                              </div>
                              <label>To</label>
                              <div className="form-group col-12">
                                <input
                                  type="date"
                                  className="form-control"
                                  name="Edate"
                                  placeholder="End Date"
                                  value={moment(endDate, "DD/MM/YYYY").format(
                                    "YYYY-MM-DD"
                                  )}
                                  onChangeCapture={(e) => {
                                    setEndDate(
                                      moment(e.target.value).format(
                                        "DD/MM/yyyy"
                                      )
                                    );
                                  }}
                                />
                              </div>
                            </div>

                            {/* <p className="text-muted" style={{ fontWeight: 500 }}>
                            Appointment Time
                          </p>
                          <div className="row mb-3 ">
                            <div className="form-group col-6">
                              <label>From</label>
                              <input
                                type="time"
                                className="form-control"
                                name="Stime"
                                placeholder="Start Time"
                              />
                            </div>
                            <div className="form-group col-6">
                              <label>To</label>
                              <input
                                type="time"
                                className="form-control"
                                name="Etime"
                                placeholder="End Time"
                              />
                            </div>
                          </div> */}
                          </div>
                        )}
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </div>
              {/* end row */}
              <div className="col-lg-3 mt-3 mt-lg-0">
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Doctor’s Gender</Accordion.Header>
                  <Accordion.Body className="bg-white border-top-0">
                    <h3>Doctor’s Gender</h3>
                    <div className="mhc-filter">
                      <div className="form-check checkboxRadiobtn mb-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Gender"
                          onChange={(e) => {
                            onGenderChange(e, 1);
                          }}
                          id="Gender1"
                          checked={values.gender === 1}
                        />
                        <label className="form-check-label" for="Gender1">
                          Male
                        </label>
                      </div>
                      <div className="form-check checkboxRadiobtn mb-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Gender"
                          onChange={(e) => {
                            onGenderChange(e, 2);
                          }}
                          checked={values.gender === 2}
                          id="Gender2"
                        />
                        <label className="form-check-label" for="Gender2">
                          Female
                        </label>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </div>
              {/* end row */}
            </div>
            <div className="d-flex flex-column flex-sm-row align-items-center justify-content-end mt-3">
              <button
                className="btn btn-outline-secondary rounded-1 fliter-btn"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                className="btn btn-outline-primary rounded-1 fliter-btn ms-0 ms-sm-3 mt-3 mt-sm-0"
                onClick={() => {
                  resetValues();
                  closeModal();
                }}
              >
                Reset
              </button>
              <Button
                className="btn btn-primary fliter-btn rounded-1 ms-0 ms-sm-3 mt-3 mt-sm-0"
                // onClick={(e) => filterDoctor()}
                type="submit"
              >
                Apply Filter!!
              </Button>
            </div>
          </Form>
        </Accordion>
      </Modal.Body>
    </Modal>
  );
};

export default FilterDoctorModal;
