import React, { useEffect, useState } from "react";
import patientWallet from '../../assets/images/patientWallet.svg';
import { useSelector, useDispatch } from "react-redux";
import {WalletHistoryCall} from "../../actions/dashboard-api";
const WalletBalnce=({token})=> {

    const switchToken = useSelector((state) => state.switchToken.value);

    useEffect(() => {
        if (switchToken) {
            WalletHistoryCall(switchToken).then(function (response) {   
                setWalletHistory(response.data.walletBalance);
            })
            .catch(function (err) {
                console.log(err);
            });
        }
      }, [switchToken]);


    const[walletHistory, setWalletHistory]=useState();
    useEffect(()=>{
     WalletHistoryCall(switchToken).then(function (response) {   
         setWalletHistory(response.data.walletBalance);
     })
     .catch(function (err) {
         console.log(err);
     });
    },[])

    return(
        <div className="wallet-balance d-flex align-items-center pt-0 pt-sm-2">
                <div><img src={patientWallet} alt="" /></div>
                <div>Wallet Balance: {walletHistory===undefined? "":   <span className="fw-bolder">{walletHistory}</span>}</div>
        </div>
    )
}

export default WalletBalnce