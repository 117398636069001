import React, { useEffect, useState } from "react";
import { getHealthCareBlogs } from "../../actions/get-calls";
import businesswoman from "../../assets/dust/businesswoman-using-tablet-analysis-graph-company.webp";
import { Link } from "react-router-dom";
import { decodeString, encodeString } from "../../constants/app-constants";

const ShowHealthBlogs = () => {
  const [healthCareblogs, setHealthCareBlogs] = useState([]);
  const fetchHealthCareBlogs = async () => {
    const response = await getHealthCareBlogs();
    console.log("response: ", response);
    setHealthCareBlogs(response.data || []);
  };
  useEffect(() => {
    fetchHealthCareBlogs();
  }, []);
  return (
    <div>
      <section className="mhc-latest-news">
        <div className="container">
          <h1 className="text-left text-lg-center">Health Blogs</h1>
          <p className="text-left text-lg-center">
            At MyHealthcare, we focus on complete healthcare ecosystem
            solutions. Our numbers are just a reflection of the same!
          </p>

          <div className="row justify-content-center mt-4">
            {healthCareblogs && Array.isArray(healthCareblogs) && healthCareblogs.map((healthCareBlog) => {
              console.log('healthCareBlog: ', healthCareBlog);
              return (
                <div className="col-md-6 col-lg-6 col-xl-3">
                  <div className="mhc-latest-new-card">
                    <figure className="m-0">
                      <img src={healthCareBlog.blog_img} className="icon" />
                    </figure>
                    <div className="card-body">
                      <h2>{healthCareBlog.title}</h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: healthCareBlog?.blog?.substring(0, 100),
                        }}
                      ></div>

                      <Link
                        to={`/health-blogs/${healthCareBlog.slug}/${encodeString(healthCareBlog.meta_title)}}`}
                        className="btn btn-outline-primary rounded-1  "
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ShowHealthBlogs;
