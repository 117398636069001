import React from "react";

const MyReportComingSoon=()=>{


    return(
        <div className="row justify-content-center mt-4"><div className="col-12">
                                <h5 className="text-secondary text-center mb-1">Coming Soon</h5>
                         
                              
                             </div> </div>
    )
}

export default MyReportComingSoon

{/* <div className="row justify-content-center mt-4"><div className="col-12">
                                <h6 className="text-secondary text-center ">Coming Soon</h6>
                                <p  className="text-secondary mb-2 text-center  lh-base">Alternatively you can approach the institutes directly, for assistance!</p>
                                <p  className="text-secondary mb-2 text-center  lh-base">Sakra World Hospital<br className="d-block d-sm-none" /><a className="text-primary text-decoration-none fw-bolder" href="mailto:enquiry@sakraworldhospital.com "> <span class="mhc-icon icon-mail"></span> enquiry@sakraworldhospital.com </a> <span className="d-none d-sm-inline-block">|</span> <br className="d-block d-sm-none" /> <a className="text-primary text-decoration-none fw-bolder" href="tel:0804964969"><span class="mhc-icon icon-phone"></span> 0804964969</a> </p>
                                <p  className="text-secondary mb-2 text-center  lh-base">Sakra Premium Clinic<br className="d-block d-sm-none" /> <a className="text-primary text-decoration-none fw-bolder" href="mailto:enquiry.spc@sakraworldhospital.com"> <span class="mhc-icon icon-mail"></span> enquiry.spc@sakraworldhospital.com </a> <span className="d-none d-sm-inline-block">|</span>  <br className="d-block d-sm-none" /> <a className="text-primary text-decoration-none fw-bolder" href="tel:0804964969"><span class="mhc-icon icon-phone"></span> 08068909090</a> </p>
                             </div> </div> */}