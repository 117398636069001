import React, { useState, useEffect, useLayoutEffect } from "react";
import { Container } from "react-bootstrap";
import { getTextFromCMS } from "../../actions/get-calls";
import Breadcrumb from "../../component/breadcrumb";
import BreadcrumbWithUrl from "../../component/breadcrumb/breadcrumb-with-url";

const TermsOfUse = () => {
    const [content, setContent] = useState(null);

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    useEffect(async () => {
        // Content
        const _setContent = await getTextFromCMS(8, 1);
        if (_setContent) setContent(_setContent.data[0]);
    }, []);
    const breadCrumbData = [
        { type: 1, url: "/", name: "Home" },
        { type: 2, name: 'Terms of Use' },
      ];
    return (
        <React.Fragment>
            <Container >
      <BreadcrumbWithUrl breadcrumbs={breadCrumbData} />

                <div className="mhc-text">
                    <h1 className="fs-3">{content ? content.heading : 'Terms of Use'}</h1>
                    {content ? <div dangerouslySetInnerHTML={{ __html: content.text }}></div> : ''}
                </div>
            </Container>
        </React.Fragment>
    )
}

export default TermsOfUse;