import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Investorshunch from "../../assets/images/Investors-hunch.webp";
import Investorssixihsence from "../../assets/images/Investors-sixih-sence.webp";
import Investorsisd from "../../assets/images/Investors-isd.webp";
import InvestorsAjay from "../../assets/images/Investors-ajay.webp";
import { getBoardMembers, getInvestors } from "../../actions/get-calls";

const investorsettings = {
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 1000,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const OurInvestors = () => {
  const [investors, setInvestors] = useState([]);
  const fetchAboutUsData = async () => {
    const response = await getInvestors();
    console.log("response: ", response);
    setInvestors(response.data);
  };
  useEffect(() => {
    fetchAboutUsData();
  }, []);
  return (
    <section className="about-investor">
      <div className="container">
        <h1 class="text-left text-lg-center fs30">Our Investors</h1>
        <div className="row mt-4 justify-content-center">
          <div className="col-xxl-12 col-xl-12 ">
            <Slider {...investorsettings}>
              {investors && Array.isArray(investors) && investors.map((investor)=><div className="investor-img">
                <img src={investor.image} alt="" />
              </div>)}
              
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurInvestors;
