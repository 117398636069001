import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";

import sliderBg from "../../assets/images/home-banner.webp";
import BookConsult from "../../assets/images/book-consult.svg";
import bookTests from "../../assets/images/book-tests.svg";
import orderMedicines from "../../assets/images/order-medicines.svg";
import homecareServices from "../../assets/images/homecare-services.svg";
import carePckages from "../../assets/images/care-packages.svg";
import partnersDigitalJourney from "../../assets/images/digital-journey.webp";
import businesswoman from "../../assets/dust/businesswoman-using-tablet-analysis-graph-company.webp";
import DownloadApp from "../../component/download-app/downloadApp";
import MHCLocation from "../../component/mhc-location";
import SearchFormWidget from "../../component/search/search-form-widget";
import { searchTextAPI } from "../../actions/post-call";
import { getImageFromCMS } from "../../actions/get-calls";
import ShowHealthBlogs from "./ShowHealthBlogs";
import CountUp, { useCountUp } from 'react-countup';
import OurJourney from "./OurJourney";

const Home = () => {
  
  const settings = {
    dots: false,
    infinite: true,
    speed: 2500,
    slidesToShow: 5,
    slidesToScroll: 5,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
    ],
  };

  const searchType = ['all'];
  const [keyword, setKeyword] = useState("");
  const [searchResult, setSearchedResult] = useState(null);
  const [bgImage, setBgImage] = useState(null);
  const [moduleIcon, setModuleIcon] = useState(null);
  const [ourPartner, setOurPartner] = useState(null);
  const [journeyBg, setJourneyBg] = useState(null);
  const [journeyBullet, setJourneyBullet] = useState(null);
  const [journeyCounter, setJourneyCounter] = useState(null);
  const [specializedDoctors, setSpecializedDoctors] = useState(null);
  const [blog, setBlog] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(async () => {
    if (keyword === ''|| keyword.length < 3) return false;
    setIsProcessing(true);
    const searchedData = await searchTextAPI(keyword, 'all');

    if (searchedData && searchedData.data && searchedData.data.status === 200) {
      setIsProcessing(false);
      setSearchedResult(searchedData.data);
    }
  }, [keyword]);

  useEffect(async () => {
    // BgImage
    const _setBgImage = await getImageFromCMS(1, 1);
    if (_setBgImage) setBgImage(_setBgImage.data[0]);
    else setBgImage([]);

    // ModuleMenuIcon
    const _setModuleMenuIcon = await getImageFromCMS(1, 2);
    if (_setModuleMenuIcon) setModuleIcon(_setModuleMenuIcon.data);
    else setModuleIcon([]);

    // OurPartner
    const _setOurPartner = await getImageFromCMS(1, 3);
    if (_setOurPartner) setOurPartner(_setOurPartner.data);
    else setOurPartner([]);

    // SpecializedDoctors
    const _setSpecializedDoctors = await getImageFromCMS(1, 7);
    if (_setSpecializedDoctors) setSpecializedDoctors(_setSpecializedDoctors.data);
    else setSpecializedDoctors([]);

    // Journey BG
    const _setJourneyBg = await getImageFromCMS(1, 4);
    if (_setJourneyBg) setJourneyBg(_setJourneyBg.data[0]);
    else setJourneyBg([]);

    // Journey Bullets
    const _setJourneyBullet = await getImageFromCMS(1, 5);
    if (_setJourneyBullet) setJourneyBullet(_setJourneyBullet.data);
    else setJourneyBullet([]);

    // Journey Counter
    const _setJourneyCounter = await getImageFromCMS(1, 6);
    if (_setJourneyCounter) setJourneyCounter(_setJourneyCounter.data);
    else setJourneyCounter([]);

    // Blog
    // const _setBlog = await getImageFromCMS(1, 6);
    // if (_setBlog) setJourneyCounter(_setJourneyCounter.data);
  }, []);

  return (
    <>
      <section className="mhc-home-banner" style={{ backgroundImage: `url(${bgImage ? bgImage.image : sliderBg})` }}>
        <div className="mhc-banner-text">
          <div className="container">
            <h1>
              Speciality Healthcare <br className="d-block d-lg-none" />
              Services
            </h1>
            <div className="row justify-content-start justify-content-lg-center mt-4 mb-4">
              <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-2">
                <MHCLocation isNavbar={false} />
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-6 col-xxl-6 position-relative mt-4 mt-lg-0">
                <SearchFormWidget
                  placeHolder="Search Specialities/Doctors"
                  btnDisplay={true}
                  searchType={searchType}
                  searchResult={searchResult}
                  isProcessing={isProcessing}
                  setKeyword={setKeyword}
                />
              </div>
            </div>

            <div className="row justify-content-center mt-4 d-none d-lg-flex">
              <div className="col-lg-12 col-xl-9 col-xxl-8">
                <div className="bg-white rounded-3">
                  <ul className="list-inline d-flex mb-0">
                    <li className="list-inline-item">
                      <Link
                        className="d-flex align-items-center"
                        to="/book-consult"
                        target={(moduleIcon && moduleIcon[0].target_blank) ? "_blank" : ""}
                        rel={(moduleIcon && moduleIcon[0].target_blank) ? "noopener noreferrer" : ""}
                      >
                        <div className="image-holder">
                          <img src={moduleIcon ? moduleIcon[0].image : BookConsult} alt="" />
                        </div>
                        <span>{moduleIcon ? moduleIcon[0].title : 'Book Consult'}</span>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link
                        className="d-flex align-items-center"
                        to="/book-package-or-test"
                        target={(moduleIcon && moduleIcon[1].target_blank) ? "_blank" : ""}
                        rel={(moduleIcon && moduleIcon[1].target_blank) ? "noopener noreferrer" : ""}
                      >
                        <div className="image-holder">
                          <img src={moduleIcon ? moduleIcon[1].image : bookTests} alt="" />
                        </div>
                        <span>{moduleIcon ? moduleIcon[1].title : 'Book Tests'}</span>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link
                        className="d-flex align-items-center"
                        to="/order-medicine"
                        target={(moduleIcon && moduleIcon[2].target_blank) ? "_blank" : ""}
                        rel={(moduleIcon && moduleIcon[2].target_blank) ? "noopener noreferrer" : ""}
                      >
                        <div className="image-holder">
                          <img src={moduleIcon ? moduleIcon[2].image : orderMedicines} alt="" />
                        </div>
                        <span>{moduleIcon ? moduleIcon[2].title : 'Order Medicines'}</span>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link
                        className="d-flex align-items-center"
                        to="/homecare-services"
                        target={(moduleIcon && moduleIcon[3].target_blank) ? "_blank" : ""}
                        rel={(moduleIcon && moduleIcon[3].target_blank) ? "noopener noreferrer" : ""}
                      >
                        <div className="image-holder">
                          <img src={moduleIcon ? moduleIcon[3].image : homecareServices} alt="" />
                        </div>
                        <span>{moduleIcon ? moduleIcon[3].title : 'Homecare Services'}</span>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link
                        className="d-flex align-items-center"
                        to="/care-package-categories"
                        target={(moduleIcon && moduleIcon[4].target_blank) ? "_blank" : ""}
                        rel={(moduleIcon && moduleIcon[4].target_blank) ? "noopener noreferrer" : ""}
                      >
                        <div className="image-holder">
                          <img src={moduleIcon ? moduleIcon[4].image : carePckages} alt="" />
                        </div>
                        <span>{moduleIcon ? moduleIcon[4].title : 'Care Packages'}</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center mt-5 d-none d-lg-flex">
            <div className="col-md-12 call-assistance">
              <p className="m-0 d-flex justify-content-center align-items-center">
                <span>For assistance call patient services</span>
                <span className="fs-2 text-primary ms-3">011 41179678</span>
              </p>
            </div>
          </div>
        </div>
      </section>

      {
        ourPartner ?
          <section className="our-partner">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-2 d-none d-lg-block">
                  <p className="m-0">Our Partner's</p>
                </div>
                <div className="col-lg-10">
                  <Slider {...settings}>
                    {
                      ourPartner.map((partner) => {
                        return <div key={partner._id}><img src={partner.image} /></div>
                      })
                    }
                  </Slider>
                </div>
              </div>
            </div>
          </section>
          : ''
      }

      <section className="specialized-categories pb-0" style={{ minHeight: '290px' }}>
        <div className="container">
          <h1 className="text-left text-lg-center">
            Specialized Doctors Categories
          </h1>
          <p className="text-left text-lg-center mb-0">
            With Super specialist doctors and state-of-the-art technology, we
            cover the complete spectrum of medical specialties
          </p>

          <div className="row justify-content-center mt-4">
            {
              specializedDoctors ? specializedDoctors.map((category) => {
                return (
                  <div className="col-lg-2 col-md-4 col-sm-4 col-6" key={category._id}>
                    <div className="spicialize-card">
                      <div className="img-holder">
                        <img src={category.image} alt={category.img_alt_tag} />
                      </div>
                      <p className="mb-0 mt-2">{category.title}</p>
                      <a
                        href={category.redirection_url}
                        target={category.target_blank ? '_blank' : ''}
                        rel={category.target_blank ? "noopener noreferrer" : ""}
                        className="btn rounded-1  btn-light mt-2 ">
                        Consult Now
                      </a>
                    </div>
                  </div>
                )
              }) : ''
            }
          </div>
          {/* <div className="row">
            <div className=" col-md-11 text-center ">
              <Link to="/book-consult/specialities" className="btn btn-primary rounded-1">
                All Specialities
              </Link>
            </div>
          </div> */}
        </div>
      </section>
      <section className="mhc-partners-digital-journey">
        <div className="container">
          <div className="row justify-content-between align-items-end">
            <div className="col-lg-5 col-xl-4 d-none d-lg-block">
              <img src={journeyBg ? journeyBg.image : partnersDigitalJourney} className="mob-img" />
            </div>
            <div className="col-lg-7 col-xl-7 mb-3">
              <h1>
                Your partners in your <br />
                digital journey
              </h1>

              {
                journeyBullet ? journeyBullet.map((bullet) => {
                  return (
                    <div className="content" key={bullet._id}>
                      <div>
                        <img src={bullet.image} className="icon" />
                      </div>
                      <div>
                        <h4>{bullet.title}</h4>
                        <div dangerouslySetInnerHTML={{ __html: bullet.text }}></div>
                      </div>
                    </div>
                  )
                }) : ''
              }
            </div>
          </div>
        </div>
      </section>

              <OurJourney journeyCounter={journeyCounter}/>
        <ShowHealthBlogs />

      <DownloadApp />
    </>
  );
};
export default Home;
