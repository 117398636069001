
import React from "react";
// import { useSelector, useDispatch } from "react-redux";



const Iprops = {
 className:""
  };
const CallTOHelp=(props=Iprops)=>{

    // const dispatch = useDispatch();
    // const currentLocation = useSelector((state) => state.currentLocation.value);


    
    
    return(
        <div className={`mhc-phone-call ${props.className}` }>
            <a href="tel:011 41179678">
                <span className="mhc-icon icon-icon-phone"></span>
                <span>011 41179678 </span>
            </a>
    </div>
    )
}

export  default CallTOHelp