import React, { useEffect, useState } from 'react'
import { Table } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { compareHomecarePackage } from '../../actions/post-call';

import Breadcrumb from '../../component/breadcrumb'
import { clearPackageDetailReducer, setPackageDetailReducer } from '../../redux/slice/package-detail';
import BreadcrumbWithUrl from "../../component/breadcrumb/breadcrumb-with-url";
import { decodeString } from '../../constants/app-constants';

const HomecarePackageCompare = () => {

    const dispatch = useDispatch();
    const { hospitalId, compare1, compare2,categoryName,subCatName } = useParams();
    const [comparedResult, setComparedResult] = useState(null);
    console.log('comparedResult: ', comparedResult);

    useEffect(async () => {
        let payload = {
            type: 'all',
            hospital_id: hospitalId,
            test_ids: [compare1, compare2]
        };

        const comparison = await compareHomecarePackage(payload);
        if (comparison && comparison.data && comparison.data.test) {
            setComparedResult(comparison.data.test);
        }
    }, [compare1, compare2]);

    function setForwardData(packageDetail) {
        dispatch(clearPackageDetailReducer());
        dispatch(setPackageDetailReducer(packageDetail));
    }
    const breadCrumbData = [
        { type: 1, url: "/", name: "Home" },
        { type: 1,url:'/homecare-services', name: 'Home Care Services' },
        { type: 3, url: '', name: decodeString(categoryName) },
        { type: 4, url: '', name: decodeString(subCatName) },
        { type: 2, name: "Homecare Service Package Compare" },

      ];
    return (
        <React.Fragment>
            <div className='container'>
            <BreadcrumbWithUrl breadcrumbs={breadCrumbData}/>
                <div className="row">
                    <div className="col-lg-6 ">
                        <h1 className="fs-4 mb-1">Homecare Service Package Compare</h1>
                    </div>
                </div>
                {
                    !comparedResult ?
                        <div className="mhc-Loding my-5 text-center">
                            <img src="../../../loader.gif" alt="loader" />
                        </div>
                        :
                        <div className='mhc-compare'>
                            <div className='table-compare table-responsive mt-2 mb-4' >
                                <Table bordered className='table-width'>
                                    <thead>
                                        <tr>
                                            {
                                                comparedResult && comparedResult.map((item) => {
                                                    return (
                                                        <th key={item.id}>
                                                            <div className='row'>
                                                                <div className='col-8 col-sm-7 d-flex align-items-center'>
                                                                    <h6>{item.title}</h6>
                                                                </div>
                                                                <div className='col-4 col-sm-5 d-flex justify-content-end'>
                                                                    <span className='img-holder'><img src={item.image} alt={item.title} className='vendor-image'></img></span>
                                                                </div>
                                                            </div>
                                                            <div className='row mt-3'>
                                                                <div className='col-8 d-flex align-items-center'>
                                                                    <h6 className='text-danger'>INR {item.perday_price}/Day</h6>
                                                                </div>
                                                                <div className='col-4 d-flex justify-content-end'>
                                                                    <Link onClick={() => setForwardData(item)} to={`/homecare-service-booking/${compare1}/${compare2}`} className="btn btn-primary rounded-1 px-4" >Book</Link>
                                                                </div>
                                                            </div>
                                                        </th>
                                                    )
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {
                                                comparedResult && comparedResult.map((item) => {
                                                    return (
                                                        <td key={item.id}>
                                                            <div className='row mt-2'>
                                                                <div className='col-12'>
                                                                    <h6 >Package Inclusions</h6>
                                                                    {
                                                                        item.features && item.features.map((cItem, index) => {
                                                                            return <li key={index}>{cItem}</li>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                }
            </div>

        </React.Fragment>
    )
}

export default HomecarePackageCompare;