import React from "react";

// import logo from "../assets/images/logo-myhealthcare.svg";
import logo from "../assets/images/logo-sakra.svg";

// export const apiURL = "https://dev.appapis.desktop.myhealthcare.co/api";
export const apiURL = "https://prerelease.mhappapis.vc.myhealthcare.co/api";
// export const apiURL = "https://mhappapis.vc.myhealthcare.co/api";
export const apiPATH = apiURL + '/patients/web/v2/';

export const aggregatorType = "market_place";
export const apitoken = "eyJpdiI6IllkMVdCSVo5QnVacitJQlpcL3NJOTJBPT0iLCJ2YWx1ZSI6IjRERHdxWlB2V0JDYnhzcFwvOEFBbzk1MUFCWllyaFZzczZrQnZ5dmRuYk1VPSIsIm1hYyI6ImVhNGJhZWRjZjI4YmNjOTNiNzEyNmEyYTE2ZDVkYWI3NmY2YWM1NmYxZDNhZTlmNjlhNWFlNTBkY2QxNGY1M2EifQ==";

export const topLogin = true;
export const selectedCountry = "India";

export const Logo = logo;
// export const showBookConsult = {"show": true, "url":"https://patient.sakraworldhospital.com/", "target": "_blank"};
export const showBookConsult = {"show": true, "url":"https://uatpat.sakraworldhospital.com/", "target": "_blank"};
export const sakaraClinicUrl={"show": true, "url":"http://40.80.89.236/sakrapremium/", "target": "_blank"}

export const showBookTest = {"show": false, "url":"/book-consult", "target": "_self"};
export const showOrderMedicine = {"show": false, "url":"/book-consult", "target": "_self"};

export const hospitalAddressList = [
        {'hospitalID': 51, "type": "hospital", "label": "SAKRA World Hospital", "address": "52/2 & 52/3, Deverabeesanahalli,  Opposite INTEL, Outer Ring Road", "tel": "08049694969", "tfn": "080 49694969", "email": "enquiry@sakraworldhospital.com"},
        {'hospitalID': 58, "type": "clinic", "label": "SAKRA Premium Clinic", "address": "Bren Mercury, 1st Floor, Kaikondrahalli Junction, Sarjapur Main Rd", "tel": "08068909090", "tfn": "080 68909090", "email": " enquiry.spc@sakraworldhospital.com"}
    ]

export const hospitalgroupId = 1;
export const callCenterTFN = {"tel": "08049694969", "tfn": "080 49694969"};