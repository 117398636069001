import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState
} from "react";
import { Container } from "react-bootstrap";
import {
  getAllCareer,
  getAllJobListing
} from "../../actions/get-calls";
import Arrow from "../../assets/images/but-arrow.svg";
import CareerImage from "../../assets/images/careerimage.webp";
import BreadcrumbWithUrl from "../../component/breadcrumb/breadcrumb-with-url";


export default function CareerMain() {
  const [scrollBottom, setScrollBottom] = useState(false);
  const [y, setY] = useState(window.scrollY);
  const [career, setCareer] = useState([]);
  const [jobListing, setJobListing] = useState([]);
  const goBottom = () => {
    setScrollBottom(true);
  };

  // const handleNavigation = useCallback(
  //   (e) => {
  //     const window = e.currentTarget;
  //     if (y > window.scrollY) {
  //       setScrollBottom(false);
  //     }
  //     setY(window.scrollY);
  //   },
  //   [y]
  // );

  // useEffect(() => {
  //   setY(window.scrollY);
  //   window.addEventListener("scroll", handleNavigation);

  //   return () => {
  //     window.removeEventListener("scroll", handleNavigation);
  //   };
  // }, [handleNavigation]);
  const fetchCareer = async () => {
    const response = await getAllCareer();
    
  };
  const fetchJobListing = async () => {
    const response = await getAllJobListing();
    if (response && response.data) {
      setJobListing(response.data || []);
    }
    
  };
  useEffect(() => {
    fetchCareer();
    fetchJobListing();
  }, []);
  const breadCrumbData = [
    { type: 1, url: "/", name: "Home" },
    { type: 2, name: "Career" },
  ];
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <React.Fragment>
      <Container id="career">
        <BreadcrumbWithUrl breadcrumbs={breadCrumbData} />

        <div className="row mt-4">
          <div className="col-lg-12 col-md-4 col-12">
            <h3 className="text-dark">Join our Team!</h3>
          </div>
        </div>

        <div className="col-lg-10 col-md-12 px-0">
          <div className="container-fluid px-0">
            <span className="we-text">
              We are always eager to have enthusiasm, innovation and talent get
              on the journey towards the best in class healthcare delivery with
              us. If you think you fit the bill, get in touch and let’s hustle
              together!&nbsp;
            </span>
            <br />
            <a href="#loadMore" onClick={goBottom} className="text-primary">
              {" "}
              View Openings <img src={Arrow} className="arrow" />{" "}
            </a>
          </div>
        </div>

        <div className="col-lg-12">
          <div className="container-fluid image_container px-0">
            <img src={CareerImage} class="career_img" width={"100%"} />
          </div>
        </div>

        <div
          className={`col-lg-12 text-center my-3 ${
            scrollBottom === true ? "pt90" : ""
          }`}
          id="loadMore"
        >
          <h4 className="text-dark">Job Openings</h4>
        </div>

        <div class="table-responsive mb-5">
          <table class="table mhc-document-table">
            <thead>
              <tr>
                <th>Job Title</th>
                <th>Open Positions</th>
                <th>Locations</th>
                <th>Experience</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {jobListing.map((job) => {
                return (
                  <tr>
                    <td>{job.title}</td>
                    <td>
                      <span className="ps-1">{job.no_of_positions}</span>
                    </td>
                    <td>{job.location}</td>
                    <td>{job.experience}</td>
                    <td className="text-end">
                      <a
                        class="btn btn-outline-primary rounded-1 py-1 px-2"
                        href={`/career-sub/${job.slug}`}
                      >
                        View Job
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <a class="btn btn-primary rounded-1 py-1 px-2" href="#">
            Load More
          </a>
        </div>
      </Container>
    </React.Fragment>
  );
}
