import React, { useEffect, useState } from "react";
import { Button, Card, Container } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import patient from "../../assets/dust/Scroll Group 28.webp";
import patientblog from "../../assets/dust/rounded-rectangle.webp";
import Breadcrumb from "../../component/breadcrumb";
import DownloadApp from "../../component/download-app/downloadApp";
import {
  getBlogDetailByTitle,
  getLatestBlogs,
  getPopularBlogs,
} from "../../actions/get-calls";
import { decodeString, encodeString } from "../../constants/app-constants";
import BreadcrumbWithUrl from "../../component/breadcrumb/breadcrumb-with-url";
const blogtag = [
  {
    tag: "Aesthetic And Reconstructive Surgery",
  },
  {
    tag: "Anaesthesia",
  },
  {
    tag: "Dermatology",
  },
  {
    tag: "General Medicine",
  },
  {
    tag: "Fertility",
  },
  {
    tag: "Obstetrics & Gynaecology",
  },
  {
    tag: "Physiotherapy",
  },
  {
    tag: "Nephrology",
  },
  {
    tag: "Pulmonology",
  },
  {
    tag: "Dental",
  },
  {
    tag: "ENT",
  },
  {
    tag: "Neuro Surgery",
  },
  {
    tag: "Orthopaedics",
  },
  {
    tag: "Plastic Surgery",
  },
  {
    tag: "Rheumatology",
  },
  {
    tag: "Nutrition & Dietetics",
  },
  {
    tag: "Oncology",
  },
  {
    tag: "Diabetology & Endocrinology",
  },
  {
    tag: "Gastroenterology",
  },
  {
    tag: "Neurology",
  },
  {
    tag: "Paediatrics",
  },
  {
    tag: "General Surgery",
  },
  {
    tag: "Urology",
  },
  {
    tag: "Ophthalmology",
  },
  {
    tag: "Breast Surgery",
  },
  {
    tag: "Dental Care",
  },
  {
    tag: "Arthroscopic Surgery",
  },
  {
    tag: "Dietetics",
  },
  {
    tag: "Cardiac Sciences",
  },
  {
    tag: " Dermatology",
  },
  {
    tag: "Emergency Medicine",
  },
];



const BlogDetails = () => {
  const { title, slug } = useParams();
  const [blog, setBlog] = useState({});
  const [activeRecentPost, setActiveRecentPost] = useState(1);
  const [latestBlogs, setLatestBlogs] = useState([]);
  const [popularBlogs, setPopularBlogs] = useState([]);
  const active = "btn btn-primary rounded-1  px-5";
  const inactive = "btn btn-outline-primary rounded-1  px-5 ms-3";
  const fetchBlogDetail = async () => {
    if (slug) {
      const response = await getBlogDetailByTitle(slug);
      if (
        response &&
        response.data &&
        Array.isArray(response.data) &&
        response.data.length > 0
      ) {
        setBlog(response.data[0]);
      }
    }
  };
  const fetchLatestPosts = async () => {
    const response = await getLatestBlogs();
    console.log("response:getLatestBlogs ", response);
    setLatestBlogs(response.data);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const fetchPopularPosts = async () => {
    const response = await getPopularBlogs();
    console.log("response: getPopularBlogs", response);
    setPopularBlogs(response.data);
  };
  useEffect(() => {
    fetchBlogDetail();
    fetchLatestPosts();
    fetchPopularPosts();
  }, []);
  const breadCrumbData = [
    { type: 1, url: "/", name: "Home" },
    { type: 1, url: "/health-blogs", name: "Health Blogs" },
    { type: 3, url: "", name: encodeString(title) },
  ];
  return (
    <React.Fragment>
      <Container>
        <BreadcrumbWithUrl breadcrumbs={breadCrumbData} />

        {/* <Breadcrumb url="" chlidrenUrl="" primaryText="" text="blog" /> */}
        <h5 className="">Health Blogs</h5>
        <p>{blog.meta_title}</p>
        <div className="row">
          <div className="col-lg-8">
            <div className="mhc-blog-details">
              <figure className="figure">
                <img
                  src={blog.blog_img}
                  className="figure-img img-fluid rounded"
                  alt="..."
                />
                <figcaption className="figure-caption">
                  Beware of non-infectious <br />
                  fever in high spinal cord injuries{" "}
                </figcaption>
              </figure>
              <div>
                <div
                  className="blog-details-body"
                  dangerouslySetInnerHTML={{ __html: blog.blog }}
                ></div>
              </div>
              <div className="blog-details-body">
                <div className="border-bottom border-light w-100 transition mt-3"></div>
                <div className="row row-cols-1 row-cols-sm-2 align-items-center my-4">
                  <div className="col">
                    <h4 className="fs-6 text-secondary">Case Presentation</h4>
                  </div>
                  <div className="col">
                    <div className="d-flex flex-column flex-sm-row justify-content-start justify-content-sm-end align-items-start align-items-sm-center">
                      <h4 className="fs-6 text-secondary mb-0">Sort by</h4>
                      <div className="mhc-selectbox blog-search mt-3 mt-sm-0 ms-0 ms-sm-3">
                        <select className="form-select" required={true}>
                          <option defaultValue="1">Oldest</option>
                          <option defaultValue="2">Oldest</option>
                        </select>
                        <label htmlFor="floatingSelect">Oldest</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-bottom border-light w-100 transition "></div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mt-4 mt-md-0">
            <h4 className="fs-4 text-secondary">By Specialities</h4>
            <ul className="list-unstyled d-flex flex-wrap mt-4">
              {blogtag.map((item) => (
                <li>
                  <Link
                    className="btn btn-outline-secondary rounded-1  mb-3 me-3"
                    to=""
                  >
                    {item.tag}
                  </Link>
                </li>
              ))}
            </ul>

            <Card className="mhc-recent-post">
              <Card.Header>
                <h4 className="fs-4 text-secondary">Recent Posts</h4>
                <div className="d-flex mt-2">
                  <Link
                    type="link"
                    to=""
                    className={activeRecentPost === 1 ? active : inactive}
                    onClick={() => {
                      setActiveRecentPost(1);
                    }}
                  >
                    Latest
                  </Link>
                  <Link
                    type="link"
                    className={activeRecentPost === 2 ? active : inactive}
                    to=""
                    onClick={() => {
                      setActiveRecentPost(2);
                    }}
                  >
                    Popular
                  </Link>
                </div>
              </Card.Header>
              <Card.Body>
                {activeRecentPost === 1 && latestBlogs && Array.isArray(latestBlogs) &&
                  latestBlogs.map((item) => (
                    <div className="blog-post">
                      <div>
                        <img
                          src={item.blog_img}
                          alt=""
                          style={{ height: "50px", width: "50px" }}
                        />
                      </div>
                      <div className="ms-2">
                        <Link to="">{item.title}</Link>
                      </div>
                    </div>
                  ))}
                {activeRecentPost === 2 && popularBlogs && Array.isArray(popularBlogs) &&
                  popularBlogs.map((item) => (
                    <div className="blog-post">
                      <div>
                        <img
                          src={item.blog_img}
                          alt=""
                          style={{ height: "50px", width: "50px" }}
                        />
                      </div>
                      <div className="ms-2">
                        <Link to="">{item.title}</Link>
                      </div>
                    </div>
                  ))}
              </Card.Body>
            </Card>
          </div>
        </div>
      </Container>
      <DownloadApp />
    </React.Fragment>
  );
};
export default BlogDetails;