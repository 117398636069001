import React, { useEffect, useState } from "react";
import { getDownloadURLs } from "../../actions/get-calls";
import googlePlay from "../../assets/images/google-play.png";
import appStore from "../../assets/images/app-store.png";
const DownloadOurApp = () => {
  const [data, setData] = useState([]);
  const fetchData = async () => {
    const response = await getDownloadURLs();
    console.log("response: ", response);
    if (
      response &&
      response.data &&
      Array.isArray(response.data) &&
      response.data.length
    ) {
      setData(response.data);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="col-md-7 col-lg-6 text-start text-md-end mt-4 mt-md-0">
      <ul className="list-inline mb-0">
        <li className="list-inline-item me-3">
          <span>Download Our App</span>
        </li>
        <li className="list-inline-item me-3 mt-2 mt-md-0">
          <div className="d-flex mt-4">
            {data.map((download) => {
              return (
                <div >
                  <a
                    href={
                      download.play_store_url
                        ? download.play_store_url
                        : download.app_store_url
                    }
                    target="_blank"
                    
                  >
                    <img
                      src={download.play_store_url ? googlePlay : appStore}
                      className="icon-downlod"
                      style={{width:'120px',marginRight:'10px'}}

                      
                    />
                  </a>{" "}
                </div>
              );
            })}
          </div>
        </li>
      </ul>
    </div>
  );
};

export default DownloadOurApp;
