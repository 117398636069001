import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';


import videoCall from "../../assets/dust/video-call.svg";
import print from "../../assets/dust/print.svg";
import { changeDayString } from '../../constants/app-constants';

export default function Doctors({ doctors, consultType, sId ,callingType}) {
  const {hospitalSlug,specialitySlug,hospitalName,specialistName,specilityName} = useParams()
  console.log('callingType: #############', callingType);
  console.log('specialitySlug: ############# ', specialitySlug);
  console.log('hospitalSlug:  #############', hospitalSlug);
  const location = useLocation()
  const [startURL, setStartURL] = useState('')
  const params = useParams()
  console.log('params: ', params);
  console.log('location:================================ ', location);
  const [type,setType] = useState('');
  const [filteredDoctors,setFilteredDoctors] = useState([])
  const [bookURL,setBookURL] = useState('')
  useEffect(()=>{

    if (consultType === "0") setFilteredDoctors(doctors);
    if (consultType === "1") setFilteredDoctors(doctors.filter((doc) => doc.is_active_vc == 1));
    if (consultType === "2") setFilteredDoctors(doctors.filter((doc) => doc.is_active_opd == 1));
  },[doctors,consultType])
  useEffect(()=>{
    let url = ''
    if(callingType==='hospitals'){
      console.log('HERRRRRRRRRRRRRRR');
      url= `book-consult/hospitals/${hospitalSlug}/${specialitySlug}`
      setBookURL(url)
    }else if(callingType==='clinics'){
      console.log('HERRRRRRRRRRRRRRR1111111');
      url= `book-consult/clinics/${specilityName}`
      setBookURL(url)
      
    }else if(callingType==='specialities'){
      console.log('HERRRRRRRRRRRRRRR2222222');
      url= `book-consult/specialities/${specialistName}`
      setBookURL(url)
    }
    else if(callingType==='doctors'){

    }
    console.log('url: ********************', url);
  },[callingType,hospitalName,hospitalSlug,specialitySlug,specialistName])
  
  // useEffect(()=>{
  //   let url = ''
    
  //   if(callingType === 'hospitals'){
  //     console.log('locations called');
  //     url= `book-consult/${params.hospitalSlug}/${params.specialitySlug}`
  //     setType('hospitals')
  //     setStartURL(url)
  //   }else if(callingType === 'clinics'){
  //     console.log('clinics called');
  //     url= `book-consult/${params.hospitalSlug}`
  //     setType('clinics')
  //     setStartURL(url)
      
  //   }else if(callingType === 'specialities'){
  //     console.log('specialitise called');
  //     url= `book-consult/${params.specialistName}`
  //     setType('specialities')
  //     setStartURL(url)
  //   }
  //   else if(callingType === 'doctors'){
  //     console.log('doctors called');
  //     url= `book-consult`
  //     setType('doctors')
  //     setStartURL(url)
      
  //   }
  // },[callingType,params])
  const displayDoctors = () => {
    if (!doctors) return (<div className="mhc-Loding"><img src="../loader.gif" alt="loader" /></div>);
console.log(callingType,':::::::::::::::::::::::::::::::::>>>>>>>>>>>>>>>>>>>>>>>>>');

    return filteredDoctors.map((item) => {
      console.log('item: ', item);
      return (
        <div className="col-md-6 col-lg-6 col-xl-4 mb-4" key={item.id}>
          <div className="mhc-dector-card">
            <div className="row">
              <div className="col-9">
                <div className="d-flex align-items-center">
                  <div className="img-holder">
                    <img src={item.profile_image}></img>
                  </div>
                  <div className="ms-3">
                    <p className="m-0 fw-bolder  text-secondary text-tittle">
                      {item.name}
                    </p>
                    <p className="text-secondary sub-text">
                      {item.designation} <br />
                      Exp. {item.experience}+ Yrs <br />
                      {callingType!=='specialities' && Array.isArray(item.specialities) && item.specialities.join(',')}
                      {callingType!=='hospitals' && item.hospital && item.hospital.name}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-3 text-end">
                <ul className="list-unstyled mb-0 d-flex justify-content-end">
                  {item.is_active_vc === 1 && (
                    <li>
                      <img src={videoCall} className="icon me-3"></img>
                    </li>
                  )}
                  {item.is_active_opd === 1 && (
                    <li>
                      <img src={print} className="icon"></img>
                    </li>
                  )}
                </ul>
              </div>
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <div>
                <ul className="list-unstyled d-flex flex-wrap mb-0 text-capitalize fs-6">
                  {item.availability && item.availability.length > 0 && (
                    <li className="px-1">
                      <span className="text-secondary  me-2">Available</span>
                    </li>
                  )}
                  {item && item.availability && Array.isArray(item.availability) &&item.availability.map((day, index) => {
                    return (
                      <li className="px-1" key={day}>
                        <span className="text-primary fw-bolder">
                          {changeDayString(day)}
                          {index + 1 !== item.availability.length && ", "}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div>
                {/* // <Link to={`/doctor-profile/${item.id}/${item.hospital.id}/${sId}/${consultType}`} */}
                {item.availability && item.availability.length > 0 ? (
                  <Link
                    to={
                      
                      (callingType && callingType === "doctors")
                        ? `/book-consult/doctors/${item.slug}/${item.id}/${item.hospital.id}/${item.specialities_slug[0].id}/${consultType}?consultType=${callingType}`
                        : `/${bookURL}/${item.slug}/${item.id}/${item.hospital.id}/${sId}/${consultType}?consultType=${callingType}`
                    }
                    className="btn btn-outline-primary rounded-1"
                  >
                    Book
                  </Link>
                ) : (
                  <button
                    className="btn btn-outline-primary rounded-1"
                    disabled
                  >
                    Book
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    });
  };
  return displayDoctors()
}