import React, { Component, useState } from "react";
import {apiPATH} from "../../utils/constant";
import Pickadate from 'pickadate/builds/react-dom'
import moment  from 'moment';
import { Modal} from "react-bootstrap";
import { capitalizeFirst } from "../../utils/fliters";
import LodingComponent from "../../component/loding";
import { homecareParametersDetails } from "../../actions/dashboard-api";

    const PatientHomeCare=({PatientHomeCareData})=> {
        const [modal, setModal] = useState(false);
        const [packageName, setPackageName] = useState()
        const [packageGroupData, setpackageGroupData] = useState([])
        const handleClose = () => setModal(false);
  

        const openUrl = (url)=>{
            const urlPath = apiPATH + url;
            window.open(urlPath, "_self");
        }

        const handleInclusionData = (e, packageName, hospital_id, test_id) =>{
            setpackageGroupData([]);
            setPackageName(packageName);
            homecareParametersDetails(hospital_id, test_id).then(function (response) {
                setpackageGroupData(response.data.test.features);
                setModal(true)
            })
                .catch(function (err) {
                    console.log(err);
                });
            
        }

        return(
            <React.Fragment>
                 <div className="row d-none">
                <div className="col-xl-5 col-lg-6 col-md-8">
                 <div className="d-flex flex-column flex-md-row">
                 <div className="form-floating mhc-dob date mb-4">
              <Pickadate.InputPicker  required    readOnly={false} />
              <i className="mhc-icon icon-calendar"></i>
             <label htmlFor="Dob">Start Date</label>
                    </div>
                    <div className="form-floating mhc-dob date mb-4 ms-0 ms-md-3">
              <Pickadate.InputPicker  required    readOnly={false} />
              <i className="mhc-icon icon-calendar"></i>
             <label htmlFor="Dob">End Date</label>
</div>
                 </div>
                 </div>
                 </div>
{
    PatientHomeCareData?.length > 0 ?
    PatientHomeCareData.map((item, i)=>{
        let date=moment(item.booking_date).format('DD MMM, YYYY')
        let day=moment(item.booking_date).format('ddd')
        // let time=moment(item.booking_date).format('LT')
        let bookDate = moment(item.booking_date).unix();
        let CurrentDate = moment().unix();
      
        return(
<div className="mhc-patient-details-card mhc-homecare" key={1+1}>
    <div className="row">
        <div className="col-md-3 col-lg-3">
            <p className="mb-1 custom-text fw-bold">Start Date: {day} {date}</p>
            <p className="mb-0 tittle ">{item.patient_name}</p>
        </div>
        <div className="col-md-5 col-lg-5 mt-3 mt-md-0 text-start ">       
            <p className="mb-0 tittle ">{item.test.title} <i class="mhc-icon icon-mhc-info cursor-pointer text-primary" onClick={(e) => handleInclusionData(e, item.test.title, item.hospital.id, item.test.id)}></i></p>
            <p className="mb-0 ">{item.hospital.name}</p>
        </div>
        <div className="col-md-2 col-lg-2  mt-3 mt-md-0 text-start text-md-center ">   
          <img src={item.hospital.image} className="hopsital-logo" alt="hospitalLogo" />
        </div>
        <div className="col-md-2  col-lg-2  mt-3 mt-md-0 text-start text-md-end">
            
            <p className={`mb-1 fw-bolder text-capitalize ${item.booking_status==='Pending' || item.booking_status==='PENDING'?'text-danger':item.booking_status==='Canceled' || item.booking_status==='CANCELLED' ?'text-danger':item.booking_status==='Missed' || item.booking_status==='MISSED' ? 'text-missed':'text-success'}` }>{capitalizeFirst(item.booking_status.replace("Canceled", "Cancelled").replace("Upcoming", "Paid"))}</p>
            <p className="mb-0 ">INR {item.amount}</p>
        </div>
    </div>
    <div className="row">
        <div className="col-12 text-start text-md-end">
         {
            item.actions.map((action)=>{
                return(
                    // action.status === 1 && 
                    // (item.booking_status !='Canceled' && item.booking_status !='Missed' && (item.booking_status === 'Active' && bookDate >= CurrentDate)) 
                    
                    item.booking_status =='Pending' && action.name =='Pay Now'? 
                    <button onClick={() => openUrl(action.uri)} className="btn btn-outline btn-outline-secondary  py-0 mt-2 rounded-1">{action.name}</button>
                    : ''
                )
                   
            })
         }
       
        </div>
    </div>
  
</div>
        )
    })
    : <div className="w-100 text-center my-4">Data not found</div>
}


            <Modal show={modal} onHide={handleClose} size="md" className="mhc-package-modal">
                <Modal.Header closeButton className="pb-0">
                    <Modal.Title className="fs-6">{packageName}</Modal.Title>
                </Modal.Header>
                <Modal.Body> 
                    <ul>
                    {
                        packageGroupData?.map((item) =>{
                                return ( <li className="mb-2">{item}</li>)
                        })
                    }
                    {

                        packageGroupData?.length == 0? <div className="w-100 text-center my-4">Data not found</div> : null
                    }
                    </ul>
                </Modal.Body>
            </Modal>



            </React.Fragment>
        )
    }

    export default PatientHomeCare