import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Tabs, Tab } from "react-bootstrap";
import Breadcrumb from "../../component/breadcrumb";
import dashboard from '../../assets/images/dashboard.svg'
import { Link, useNavigate } from "react-router-dom";
import SwitchProfile from "../../component/login-register/switchProfile";
import PatientMyDocumentExternalDocument from "../patientMyDocument/patientMyDocumentExternalDocument";
import PatientMyDocumentMyAppointments from "../patientMyDocument/patientMyDocumentMyAppointments";
import { externalDocumentsCall, myAppointmentsCall } from "../../actions/dashboard-api";

import InfiniteScroll from 'react-infinite-scroll-component';
import LodingComponent from "../../component/loding";
import NoMoreData from "../../component/no-more-data/noMoreData";
import { Dropdown, DropdownButton, Col, Form, Button, Popover } from "react-bootstrap";
import ImageUploading from "react-images-uploading";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ShareIcon from "../../assets/dust/share-icon.png"
import { Modal } from "react-bootstrap";
import { configurations } from "../../actions/get-calls";
import { ToastContainer, toast } from 'react-toastify';
import { addExternalDocumentUpload } from "../../actions/dashboard-api";
import moment from 'moment';
import { array } from "yup";
const breadcrumArrry=[
    {
      name:'My Dashboard',
      url:'/patient-dashboard'
    }, 
    {
        name:'My Documents',
        url:''
      }, 
  ]
const PatientMyDocument = () => {
    const switchToken = useSelector((state) => state.switchToken.value);
    const selectedUser = useSelector((state) => state.selectedUser.value);
    const selectedUserCard = useSelector((state) => state.selectedUserCard.value);
    let patient_id = selectedUserCard.id;


    const [doccurrentTab, setDocCurrentTab] = useState("ExternalDocuments");

    // For External Documents Hooks
    const [externalDocuments, setExternalDocuments] = useState([]);
    const [extPagination, setExtPagination] = useState(1);
    const [extMore, setExtMore] = useState(true);
    const [extPage, setextPage] = useState(1);
    const [extLoder, setExtLoder] = useState(false);

//filer and Add Document Hooks
const [modal, setModal] = useState(false);
const [addDocument, setAddDocument]=useState()
const [category_id, setCategory_id]=useState()
const [filterDocType, setfilterDocType] = useState(null)
const [filterList, setfilterList] = useState()
const [PopClose, setPopClose]=useState(false)
const [hasCheckBox, setHasCheckBox]=useState([])
const [isChecked, setIsChecked] = useState(false);
const maxNumber = 15;
const [docAgree, setDocAgree] = useState(0);

    // For My Appointments Hooks
    const [myAppointments, setMyAppointments] = useState();
    const [pagination, setPagination] = useState();
    const [hasMore, setHasMore] = useState(true);
    let [myAppointmentsPage, setMyAppointmentsPage] = useState(1);
    const [myAppointmentsLoader, setMyAppointmentsLoader] = useState(false);


    const allUsers = useSelector((state) => state.allUsers.value);
    const navigate = useNavigate();
      useEffect(() => {
        if (!allUsers) {
          navigate('/');
        }
      },[]);


    useEffect(() => {
        // For External Documents 
        let patient_id = selectedUserCard.id;
        externalDocumentsCall(patient_id, extPage, '', switchToken).then(function (response) {
            setExtPagination(response.data.pagination)
            setExternalDocuments(response.data.records);
        }).catch(function (err) {
                console.log(err);
            });
        // For My Appointments 
        myAppointmentsCall(myAppointmentsPage, switchToken).then(function (response) {
            setPagination(response.data.pagination);
            setMyAppointments(response.data.appointments);

        }).catch(function (err) {
                console.log(err);
            });


    }, [switchToken, selectedUserCard]);

    useEffect(() => {
      setExternalDocuments(externalDocuments)
    },[externalDocuments])




    useEffect(() => {
        // For Document Type Filter List
        configurations().then(function (response) {
          setfilterDocType(response.data);          
        }).catch(function (err) { });
    }, [])



    // For External Documents  More Data
    const fetchMoreData = () => {
                //  alert("hello")
        if (doccurrentTab === "MyAppointments" && myAppointmentsPage < pagination.total_pages) {
            setMyAppointmentsLoader(true);
            let nextPage;
            nextPage = myAppointmentsPage + 1;
            setMyAppointmentsPage(nextPage)     
            myAppointmentsCall(nextPage, switchToken).then(function (response) {
                setMyAppointments(myAppointments.concat(response.data.appointments));

                setMyAppointmentsLoader(false)
            })

        } else {
            setHasMore(false);
        }
    }

    // For My Appointments More Data
    const fetchExternalData = () => {
    
        if (doccurrentTab === "ExternalDocuments" && externalDocuments < pagination.total_pages) {
            setExtLoder(true)       
            let extNextPage;
            extNextPage = extNextPage + 1;
            setMyAppointmentsPage(extNextPage)
            externalDocumentsCall(patient_id, extNextPage, '', switchToken).then(function (response) {
                setExternalDocuments(response.data.records);
                setExtLoder(false)
            })

        } else {
            setExtMore(false);
        }
    }


    /// add document and filter functon
    const handleAddDocumentShow = () => {  
      setAddDocument(); 
      setCategory_id();
      setModal(true);
  }

  const handlefilterList = () => { 
    setPopClose(true)
}


  const handleAddDocumentClose = (e) => {
    setModal(false);
  }
     
  const onChange = (imageList, addUpdateIndex) => {
    setAddDocument(imageList);   
};
const getRadioButtonValue=(e)=>{ 
  setCategory_id(e.target.value)
}


const filtterReset=(e)=>{
  var clist = document.querySelectorAll('[name="filter"]');
  for (var i = 0; i < clist.length; ++i) { clist[i].checked = false; }
  setHasCheckBox([]);
  let patient_id = selectedUserCard.id;
  externalDocumentsCall(patient_id, extPage, '', switchToken).then(function (response) {  
      setExternalDocuments(response.data.records);
  })
      .catch(function (err) {
          console.log(err);
      });
}

const filterCategoryId =()=>{
    let mapCategoryID=''; 
    const catID = [];
    var clist = document.querySelectorAll('[name="filter"]:checked');
    for (var i = 0; i < clist.length; ++i) {
        mapCategoryID+="&category_id[]="+clist[i].value;
        catID.push(parseInt(clist[i].value))
        setHasCheckBox(catID)
      }
      


    externalDocumentsCall(patient_id, extPage, mapCategoryID, switchToken).then(function (response) {
        setExternalDocuments(response.data.records);
    })
        .catch(function (err) {
            console.log(err);
        });
        setPopClose(false)
}

const onDocAgreeHandle=(e)=>{
  if(e.target.checked == true){
    setDocAgree(1)     
    }else{
      setDocAgree(0)
    }
  }


  const uploadNewDocument =(e)=>{   
    e.preventDefault(); 
    var payload = new FormData();
    if(document.querySelector('#document_name').value == ''){
      showError("Document name is required")
      return false;
    }
    if(addDocument == undefined){
      showError("Please Upload one or more files to proceed")
      return false;
    }
    if(category_id == undefined){
      showError("Document Category is required")
      return false;
    }
    payload.append('record_type', 1);
    payload.append('document_name', e.target.elements.document_name.value);
    payload.append('category_id', category_id);
    payload.append('patient_id', selectedUser.id);
    payload.append('name', selectedUser.full_name);
    payload.append('record_date', moment(new Date()).format("YYYY-MM-DD"));

    addDocument?.map((item)=>{
      if(item.file !=undefined){
          payload.append('documents[]', item.file);
      }
    })

    addExternalDocumentUpload(payload, switchToken).then(function (response) {   
      console.log("response response",  response) 
      if(response?.statusText == "OK"){

          externalDocumentsCall(patient_id, 1, '', switchToken).then(function (response) {
                setExternalDocuments(response.data.records);
          })
          
          .catch(function (err) {
              console.log(err);
          });
          setPopClose(false)

          setModal(false)      
          return showToast("Documents added successfully");

      }else{

      }

      

})
  .catch(function (err) {
      console.log(err);
  });

  }
      function showError(str) {
        toast.error(str, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false
        });
      }
      function showToast(str) {
        toast.success(str, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false
        });
      }
    

    return (
        <React.Fragment>
            <Container className="min-body-height">
                <div className="row  align-items-center breadcrumb_sticky">
                    <div className="col-md-9">   <Breadcrumb textUrl={breadcrumArrry} />        </div>
                    <div className="col-md-3 d-none d-md-block">
                        <Link to="/patient-dashboard" className="mhc-patient-back">
                            <div className="d-flex align-items-center justify-content-start justify-content-md-end">
                                <div><img src={dashboard} alt="" /></div>
                                <div className="fw-bolder ms-2">My Dashboard</div>
                            </div>
                        </Link>

                    </div>

                </div>
                
                <SwitchProfile />

                <div className="row mt-4">
                    <div className="col-12">
                        <h1 className="fs-4 mb-1 text-secondary">My Documents</h1>
                        <p className="m-0 fs-6">Self-upload your health documents</p>
                    </div>
                </div>
                <div className="mhc-tabs position-relative mt-4">

                
                    <Tabs                 
                     activeKey={doccurrentTab}
                     onSelect={(key) => setDocCurrentTab(key)}
                    id="controlled-tab-example" className="mb-3">
                        <Tab eventKey="ExternalDocuments" title="External Documents">
                        <div className="row">
        <div className="col-11 text-end">
          <a className="btn btn-outline-primary py-1 btn-outline-custom text-decoration-none fs-7" onClick={handleAddDocumentShow}>
            Add Document</a>
        </div>
        <div className="col-1 d-flex align-self-center justify-content-center">
        

          <OverlayTrigger
            trigger="click"
            key={"1"}
            placement={'left'}
            rootClose={true}
            show={PopClose}
            overlay={
              <Popover id='popover-positioned-bottom' className="filter_pop">
                <Popover.Body>
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <h6 className="mb-0">Filter</h6>
                      </div>
                    </div>
                    <div className="row">  
                            {
                              filterDocType?.document_categories?.map((item, i) =>{
                              return(
                                <div className="col-12 mt-3" key={i}>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="filter" defaultChecked={hasCheckBox.length > 0 && Array.from(hasCheckBox).includes(item.id) == true ? 'checked': ''} defaultValue={item.id} id={"doc_"+ item.id} />
                                    <label className="form-check-label d-block d-sm-inline" htmlFor={"doc_"+ item.id} > {item.category_name}</label>
                                  </div>
                                </div>
                              )
                              })
                            }
                    </div>

                    <div className="row mt-3">
                      <div className="col-5">
                        <button type="button" className="btn btn-outline-secondary" onClick={(e)=>filtterReset(e)}>Reset</button>
                      </div>
                      <div className="col-7">
                        <button type="button" className="btn btn-primary " onClick={filterCategoryId}>Apply Filter</button>
                      </div>
                    </div>
                  </div>
                </Popover.Body>
              </Popover>
            }
          >
            <Button variant="" className="btn-filters" onClick={(e)=>handlefilterList(e)}>
              <img src={ShareIcon} alt={"share"} className="share-icon-size" /></Button>
          </OverlayTrigger>



        </div>

      </div>
                            {
                                externalDocuments?.length ==  0 ? <h6 className="text-center mt-2 mb-4">No Data Found</h6> :
                                externalDocuments === undefined ? <LodingComponent className="mhc-Loding-minHeight" /> : <div id="ExternalDocuments" className="mhc-scrollDiv"><InfiniteScroll
                                    dataLength={externalDocuments.length}
                                    next={fetchExternalData}
                                    hasMore={extMore}
                                    loader={extLoder == true ? <LodingComponent className="text-center" /> : ''}
                                    scrollableTarget="ExternalDocuments"
                                    endMessage={extPagination.total_pages > 1 ? <NoMoreData className="text-center mb-3" /> : ''}
                                >
                                    {
                                        <PatientMyDocumentExternalDocument externalDocuments={externalDocuments}  />
                                    }

                                </InfiniteScroll>
                                </div>
                            }

                        </Tab>
                        <Tab eventKey="MyAppointments" title="My Appointments">
                            {
                                myAppointments?.length == 0 ? <h6 className="text-center mt-2 mb-4">No Data Found</h6> :  
                                myAppointments === undefined ? <LodingComponent className="mhc-Loding-minHeight" /> : <div id="myAppointments" className="mhc-scrollDiv"><InfiniteScroll
                                    dataLength={myAppointments.length}
                                    next={fetchMoreData}
                                    hasMore={hasMore}
                                    loader={myAppointmentsLoader == true ? <LodingComponent className="text-center" /> : ''}
                                    scrollableTarget="myAppointments"
                                    endMessage={pagination.total_pages > 1 ? <NoMoreData className="text-center mb-3" /> : ''}
                                >
                                    {
                                        <PatientMyDocumentMyAppointments documentList={myAppointments} />
                                    }

                                </InfiniteScroll>

</div>
                            }


                        </Tab>

                    </Tabs>


                </div>

            </Container>
            <Modal show={modal} size="lg" onHide={handleAddDocumentClose} >
        <Modal.Header closeButton>
          <Modal.Title className="fs-6">Add Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form onSubmit={(e) => uploadNewDocument(e)}>
          <div className="row">
            <div className="col-12">
              <div className="form-floating mb-4">
                <input type="text" className="form-control" id="document_name" name="document_name" autoComplete="off" />
                <label htmlFor="document_name">Enter Document Name</label>
              </div>
            </div>
          </div>


          <div className="mt-0">
          <div className="mhc-multiImage">
      <ImageUploading
        multiple
        name="file_upload[]"
        value={addDocument}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        acceptType={["jpg", "gif", "png", "pdf"]}
        allowNonImageType={true}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <div className="d-flex flex-wrap">
              {imageList.map((image, index) => (
                <div key={index} className="image-item">
                  <embed src={image["data_url"]} alt="" width="100" />
                  <div className="image-item__btn-wrapper">
                    <button
                      onClick={() => onImageRemove(index)}
                      className="close"
                    ></button>
                  </div>
                </div>
              ))}

              <button
                className="btn mhc-add-more"
                type="button"
                style={isDragging ? { color: "red" } : undefined}
                onClick={onImageUpload}
                {...dragProps}
              >
                <br /> Add  More
              </button>
            </div>
          </div>
        )}
      </ImageUploading>
    </div>

          </div>
          <p className="fs-7  fw-bolder mb-0 mt-2 mt-md-3">Select Document Category</p>
          <ul className="list-unstyled d-flex flex-wrap mt-4">
            {
              filterDocType?.document_categories?.map((item) =>{
                    return(
                      <>
                      {/* <li><button type="button" name="category_id" id={item.id} className="btn btn-outline-secondary py-1  mb-3 me-3">{item.category_name}</button></li>   */}
                      <li className="me-2 mb-2 document_category">
                        <div className={`form-check btn btn-outline-secondary position-relative   ${item.id==category_id ? 'active':''}`}>
                          <input className="form-check-input maxking-radio"  type="radio" name="category_id" id={item.id} required="" defaultValue={item.id} onChange={(e)=>getRadioButtonValue(e)} />
                            <label className="form-check-label d-block d-sm-inline cursor-pointer" htmlFor={item.id}>{item.category_name} </label>
                            </div>
                      </li>   
                      </>  
                    )
              })
            }
          </ul>
          <p className="fs-7  fw-bolder mb-2 mt-3">Patient Consent</p>
                    <p>By confirming the order i consent to my prescription being shared with Sakra pharmacies for home delivery of medicines.</p>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" defaultValue={docAgree} id="docAgree" name="docAgree" onChange={(e)=>onDocAgreeHandle(e)} />
                        <label className="form-check-label d-block d-sm-inline " htmlFor="docAgree">
                            I Agree
                        </label>
                    </div>
          <button type="submit" className="btn btn-primary rounded-1  mt-3" disabled={docAgree==1?false:true}>Confirm </button>
          </form>
        </Modal.Body>
      </Modal>
        </React.Fragment>
    )
}


export default PatientMyDocument