import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { fetchClinicList, fetchPartnerHospitals } from '../../actions/get-calls'

const PartnerHospitals = () => {
    const [hospitals,setHospitals] = useState([])
    const getHospitals = useCallback(async()=>{
       const response =  await fetchPartnerHospitals();
       if(response && response.data && Array.isArray(response.data)){
           setHospitals(response.data)

       }
    },[])
    useEffect(()=>{
        getHospitals()
    },[])
  return (
        <div className="col-sm-6  col-md-6 col-lg-3 mt-4 mt-lg-0">
              <h2>Partner Hospitals</h2>
              <ul className="list-unstyled mt-3 mb-0">
                {hospitals.map((hospital)=>{
                    return                 <li>
                    <Link to={`/book-consult/hospitals/${hospital.slug}/${hospital.id}`}>{hospital.name}</Link>
                  </li>
                })}
              </ul>
            </div>
  )
}

export default PartnerHospitals