import axiosClient from "./api-handlers";

//get home care category
export async function homeCareCat() {
  const response = await axiosClient.get(
    `/patients/ios/v2/test/homecare-category`
  );
  return response;
}

//get home care cat id list
export async function hospitalListByCat(Id) {
  const response = await axiosClient.get(
    `patients/ios/v2/hospitals-by-city?category_id=${Id}&is_homecare=1`
  );
  return response;
}

//package list by hospital

export async function packageListByHospital(cId, hId) {
  const response = await axiosClient.get(
    `/patients/ios/v2/test-list-by-category?category_id=${cId}&hospital_id=${hId}&page=1&type=all`
  );
  return response;
}

//homecare requeste submission
export async function homcareSubmission(payload) {
  const response = await axiosClient.get(
    `https://dev.appapis.desktop.myhealthcare.co/api/patients/ios/v2/test/homecare-request-patient`,
    payload
  );
  return response;
}

//
