import React, { useEffect, useState } from 'react'
import { getBoardMembers,getBannerData } from '../../actions/get-calls';

const Banner = () => {
    const [bannerData,setBannerData] = useState({});
    const fetchAboutUsData = async () => {
      const response = await getBannerData();
      console.log("response:banner data ", response);
      if(response && response.data && Array.isArray(response.data) && response.data.length>0){
        setBannerData(response.data[0])
      }
    };
    useEffect(() => {
      fetchAboutUsData();
    }, []);
    console.log('bannerData: ', bannerData);
  return (
    <section className="about-banner" style={{background:`url(${bannerData.image})`}}>
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <h1>{bannerData.title}</h1>
          <div
                dangerouslySetInnerHTML={{ __html: bannerData.text }}
              ></div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Banner