import axiosClient from "./api-handlers";
import {aggregatorType, apitoken} from "../utils/constant";
// Dev
// const JWT = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvZGV2LmFwcGFwaXMuZGVza3RvcC5teWhlYWx0aGNhcmUuY28iLCJpYXQiOjE2NTMzNzE4ODMsIm5iZiI6MTY1MzM3MTg4MywiZXhwIjoxNjU1OTYzODgzLCJ1aWQiOjI4NzE5MCwidCI6InAifQ.Na1rtEcGdv9Lh2iMhxXkd3UTQuj3lIBhIws_wp1sxx4';
// Prod
//const JWT = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvbWhhcHBhcGlzLnZjLm15aGVhbHRoY2FyZS5jb1wvIiwiaWF0IjoxNjU1MTIyNDA2LCJuYmYiOjE2NTUxMjI0MDYsImV4cCI6MTY2Mjg5ODQwNiwidWlkIjoxMjA0NiwidCI6InAifQ.p4BvxAGqHhpgwybAkvjUQT1AmP0Sqrp5S7qCur9gftc';

export async function appointmentCall(current_page, startDate, endDate, isFamilyMember, JWT) {
  //&hospital_group_id=1
  let date = '';
  if(startDate != null && endDate !=null){
      date = `&start_date=${startDate}&end_date=${endDate}`
  }
  const response = await axiosClient.get(
    `/patients/web/v2/patients/appointments?type=all&${date}&page=${current_page}${isFamilyMember}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}
//delete image in dector consult

// export async function deletDectorConsult(id, JWT) {
//   // console.log("current_page", current_page)
//   const response = await axiosClient.post(
//     `patients/ios/v2/doctors/delete-documents/${id}&hospital_group_id=1&group_id=1`,
//     {
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: `${JWT}`,
//       },
//     }
//   );
//   return response;
// }
///Add Notes/ Documents


export async function addNotesDocuments(payload, id, JWT) {
  const response = await axiosClient.post(
    `/patients/web/v2/doctors/upload-documents/${id}`,
    payload,
    {
      "mimeType": "multipart/form-data",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  
  return response;
}


/// show add not details
export async function addNotesDetails(id, JWT) {
  const response = await axiosClient.get(
    `/patients/ios/v2/doctors/appointment-status/${id}`,
  
    {
      "mimeType": "multipart/form-data",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  
  return response;
}



export async function addNotesImgDelete(doc_id, JWT) {

  const payload='';
  const response = await axiosClient.post(
    `/patients/android/v2/doctors/delete-documents/${doc_id}`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}

// sendFeedBack
export async function sendFeedBack(payload, JWT) {
  const response = await axiosClient.post(
    `/patients/android/v2/call`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
        aggregatorType: aggregatorType,
        apitoken: apitoken
      },
    }
  );
  return response;
}
/// booking
export async function cancelBookingReason(payload, JWT) {
  const response = await axiosClient.post(
    `/patients/android/v2/doctors/cancel-appointment`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
        aggregatorType: aggregatorType,
        apitoken: apitoken
      },
    }
  );
  return response;
}
// add My Prescriptions Order Test
export async function myPrescriptionsOrderTest(payload, JWT) {
  const response = await axiosClient.post(
    `/patients/web/v2/patients/diagnostic-orders-via-prescription`,
    payload,
    {
      "mimeType": "multipart/form-data",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  
  return response;
}

export async function homeCareCall(current_page, isFamilyMember, JWT) {
  // console.log("current_page", current_page)
  const response = await axiosClient.get(
    `/patients/web/v2/tests/appointments?type=all&page=${current_page}${isFamilyMember}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}


export async function diagnosticsCall(current_page, isFamilyMember, JWT) {
  const response = await axiosClient.get(
    `/patients/web/v2/patients/throcare-appointment?page=${current_page}${isFamilyMember}`,

    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}


export async function diagnosticsShowDetails(booking_code, JWT) {
  const response = await axiosClient.get(
    `/patients/web/v2/patients/diagnostic-booking-showtestdetails?booking_code=${booking_code}`,

    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}


// For Diagnostic Parameters details
export async function diagnosticsParametersDetails(diagnosis_id, pincode) {
  const response = await axiosClient.get(
    `/patients/web/v2/patients/thyrocare-test-Details?diagnosis_id=${diagnosis_id}&pincode=${pincode}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        aggregatorType: aggregatorType,
        apitoken: apitoken
      },
    }
  );
  return response;
}

// For Homecare Parameters details
export async function homecareParametersDetails(hospital_id, test_id) {
  const response = await axiosClient.get(
    `/patients/android/v2/test/hospital_id/${hospital_id}/test/${test_id}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        aggregatorType: aggregatorType,
        apitoken: apitoken
      },
    }
  );
  return response;
}

export async function medicinesCall(current_page, isFamilyMember, JWT) {
  // console.log("current_page", current_page)
  const response = await axiosClient.get(
    `/patients/web/v2/patients/medicine-orders?page=${current_page}${isFamilyMember}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}

///Medicine track order

export async function MedicineTrackOrder(order_id, vendor_id, contact_number, JWT) { 
  let payload = {'order_id':order_id, 'vendor_id': vendor_id, 'contact_number':contact_number}
  const response = await axiosClient.post(
    `/patients/android/v2/patients/track-order?order_id=${order_id}&vendor_id=${vendor_id}&contact_number=${contact_number}`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}

///Diagnostic track order

export async function DiagnosticTrackOrder(book_code, JWT) { 
  const response = await axiosClient.get(
    `/patients/web/v2/patients/throcare-appointment-tracking-order-status?booking_id=${book_code}`,
    
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}

////My Documents
export async function externalDocumentsCall(patient_id, current_page, category_id, JWT) {

  const response = await axiosClient.get(
    `/patients/web/v2/getPhrDetails?patient_id=${patient_id}&page=${current_page}${category_id}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}

///add Document externalDocument
export async function addExternalDocumentUpload(payload, JWT) {
  const response = await axiosClient.post(
    `/patients/ios/v2/uploadDocuments`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}

// delete External Documents Data in my Document
export async function ExternalDataDelete(doc_id, JWT) {
  const response = await axiosClient.get(
    `patients/android/v2/delete-record/${doc_id}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}

export async function myAppointmentsCall(current_page, JWT) {
  // console.log("current_page > ", current_page)
  const response = await axiosClient.get(
    `/patients/web/v2/patients/appointments?type=all&is_documents=1&page=${current_page}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}
// myu Repoart
export async function myReportsCall(current_page, JWT) { 
  const response = await axiosClient.get(
    `/patients/web/v2/patients/throcare-report?page=${current_page}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}
// my Report Date Filter

export async function myReportsDateFilter(start_date, end_date, JWT) {
  const response = await axiosClient.get(
    `/patients/android/v2/patients/throcare-report?start_date=${start_date}&end_date=${end_date}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}

/// WalletHistory
export async function WalletHistoryCall(JWT) { 
  const response = await axiosClient.get(
    `/patients/web/v2/patients/wallet-history`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}

export async function MemberProfileDelete(payload, JWT) {
  const response = await axiosClient.post(
    `/patients/web/v2/patients/family-members/delete`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}

export async function MemberProfileEdit(payload, JWT) {
  const response = await axiosClient.post(
    `/patients/web/v2/patients/profile/update`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}

//// myPrescription
export async function myPrescriptionHomecareCall(current_page, JWT) { 
  const response = await axiosClient.get(
    `/patients/web/v2/patient-documents-by-hospital?is_homecare=1&age=${current_page}`,
    
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}

export async function myPrescriptionDocConsultCall(current_page, JWT) { 
  const response = await axiosClient.get(
    `/patients/web/v2/patient-documents-by-hospital?page=${current_page}`,
    
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}

/// order Track
export async function myPrescriptionTrackOrder(book_code, JWT) { 
  const response = await axiosClient.get(
    `/patients/web/v2/patients/throcare-appointment-tracking-order-status?booking_id=${book_code}`,
    
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
      },
    }
  );
  return response;
}


// For Sakra State
export async function sakraState() { 
  const response = await axiosClient.get(
    `/patients/web/v2/patients/state?&country_id=21`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `${JWT}`,
      },
    }
  );
  return response;
}


// For Sakra City
export async function sakraCity(id) { 
  const response = await axiosClient.get(
    `/patients/web/v2/patients/city?&state_id=${id}`,    
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `${JWT}`,
      },
    }
  );
  return response;
}


export async function SendMedicinePrescription(payload, JWT) {
  const response = await axiosClient.post(
    `/patients/web/v2/patients/partner-diagnostic-orders-via-prescription`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${JWT}`,
        aggregatorType: aggregatorType,
        apitoken: apitoken
      },
    }
  );
  return response;
}