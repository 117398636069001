import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { hospitalListByCat } from "../../actions/home-care-api";
import { useParams } from "react-router-dom";

import Breadcrumb from "../../component/breadcrumb";
import { decodeString, encodeString } from "../../constants/app-constants";
import BreadcrumbWithUrl from "../../component/breadcrumb/breadcrumb-with-url";

const HomecareCategory = () => {

  const { categoryName, categoryId } = useParams();
  const [homecare, setHomecare] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedCatName, setSelectedCatName] = useState("");

  useEffect(() => {
    if (categoryName) {
      setSelectedCatName(decodeString(categoryName));
    }
  }, [categoryName])

  useEffect(() => {
    hospitalListByCat(categoryId)
      .then(function (response) {
        let res = response.data;
        if (res && res.cities) {
          setHomecare(res.cities);
        }
        setLoading(false);
      })
      .catch(function (err) { });
  }, []);

  const breadCrumbData = [
    { type: 1, url: '/', name: 'Home' },
    { type: 1, url: '/homecare-services', name: 'Homecare Services' },
    { type: 3, url: '', name: selectedCatName }
  ];

  return (
    <React.Fragment>
      <section className="homecare-category">
        <div className="container">
          <BreadcrumbWithUrl breadcrumbs={breadCrumbData} />

          <div className="row">
            <div className="col-lg-6 ">
              <h1 className="fs-4 mb-1">{selectedCatName}</h1>
            </div>
          </div>
          <div className="row mt-3">
            {loading === true ? (
              <div className="mhc-Loding my-5 text-center">
                <img src="../../loader.gif" alt="loader" />
              </div>
            ) : (
              <>
                {homecare.map((item) => {
                  return (
                    <div key={item.id} className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-4">
                      <Card className="homecare-category-card">
                        <Card.Body>
                          <div className="row">
                            <div className="col-8">
                              <h6>{item.hospitals[0].name}</h6>

                            </div>
                            <div className="col-4 text-end">
                              <p className="text-secondary fs-13 mb-0">Starting at </p>
                              <p className="text-primary fs-13 mb-0 fw-bolder">INR {item.test[0].min_price}</p>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-8">
                              <Link to={`/homecare-service-packages/${categoryId}/${item.hospitals[0].id}/${categoryName}/${encodeString(item.hospitals[0].name)}`} className="btn btn-outline-primary rounded-1 py-1 px-2" >View Details</Link>
                            </div>
                            <div className="col-4 text-end">
                              <Card.Img variant="top" src={item.hospitals[0].image} alt={item.id} className="hopistal-icon" />
                            </div>
                          </div>

                        </Card.Body>
                      </Card>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default HomecareCategory;
