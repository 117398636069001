import { useFormik } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { doctorFormData } from "../../actions/post-call";

const DoctorForm = (props) => {
  const [values, setValues] = useState({
    gender: "Male",
    state: "Karnataka",
    country: "India",
  });
  const [errors, setErrors] = useState({});
  const formik = useFormik({
    initialValues: {
      doctor_name: "",
      speciality: "",
      current_org: "",
      country: "",
      state: "",
      city: "",
      mobile: "",
      email: "",
      preferred_time: "",
      capcha: "",
    },

    onSubmit: (helpers) => {
      let errors = {};
      if (!values.doctor_name) {
        errors.doctor_name = "Name is mandatory";
      }
      if (props.type === "doctor" && !values.speciality) {
        errors.speciality = "Speciality is mandatory";
      }
      if (props.type === "organisation" && !values.organization) {
        errors.organization = "Organization is mandatory";
      }
      if (props.type === "doctor" && !values.current_org) {
        errors.current_org = "Current Organization is mandatory";
      }
      if (!values.country) {
        errors.country = "Country is mandatory";
      }
      if (!values.state) {
        errors.state = "State is mandatory";
      }
      if (!values.city) {
        errors.city = "City is mandatory";
      }
      if (!values.mobile) {
        errors.mobile = "mobile is mandatory";
      }
      if (!values.email) {
        errors.email = "email is mandatory";
      }
      if (!values.preferred_time || values.preferred_time === 'PT') {
        errors.preferred_time = "preferred time is mandatory";
      }
      if (!values.capcha) {
        errors.capcha = "Please enter the capcha ";
      }
      if (props.type === "organisation" && !values.designation) {
        errors.designation = "Please enter the designation ";
      }
      if (props.type === "patient" && !values.age) {
        errors.age = "Please enter the age ";
      }
      console.log(errors, "errrors>>>>>>>>>>>>>>>>");
      console.log(values, "values>>>>>>>>>>>>>>>>");
      setErrors(errors);
      if (!Object.keys(errors).length) {
        const formData = new FormData();
        formData.append("name", values.doctor_name);
        formData.append("email", values.email);
        formData.append("phone", values.mobile);
        formData.append("speciality", values.speciality);
        formData.append("organization", values.current_org);
        formData.append("country", values.country);
        formData.append("city", values.city);
        formData.append("state", values.state);
        formData.append("preffered_time", values.preffered_time);
        formData.append("message", values.query || "");
        formData.append("designation", "doctor");
        formData.append("type", "doctor");
        doctorFormData(formData)
          .then(function (response) {
            console.log("response: ", response);
            let res = response.data;
            
            if (res && res.errors) {
              res.errors.map((error) => {
                // return showToast(error.message);
              });
            }else{
              showToast(`Query Sent Successfully`);
              setValues({
                country:'India',
                state:'Karnataka',
                preferred_time:'PT',
                age:'',
                capcha:''
              });
              formik.resetForm();
              setErrors({});
            }
          })
          .catch(function (err) {});
      }
    },
  });
  function showToast(str) {
    toast.success(str, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
    });
  }
  const changeHandler = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: "",
    });
  };
  return (
    <div>
      <form className={props.type} onSubmit={formik.handleSubmit}>
        <div className="form-row">
          {props.type === "organisation" && (
            <div className="form-floating col-md-4 col-sm-12 col-12 mb-3">
              <input
                type="text"
                className="form-control"
                id="org_name"
                autoComplete="off"
                placeholder="Name"
                name="organization"
                value={values.organization || ""}
                onChange={changeHandler}
              />
              {errors.organization && (
                <p style={{ color: "red" }}>{errors.organization}</p>
              )}
              <label htmlFor="org_name">Organisation Name </label>
            </div>
          )}
          <div className="form-floating col-md-4 col-sm-12 col-12 mb-3">
            <input
              type="text"
              className="form-control"
              id="name"
              autoComplete="off"
              placeholder="Name"
              name="doctor_name"
              value={values.doctor_name || ""}
              onChange={changeHandler}
            />
            {errors.doctor_name && (
              <p style={{ color: "red" }}>{errors.doctor_name}</p>
            )}
            <label htmlFor="name">Name </label>
          </div>
          {props.type === "doctor" && (
            <div className="form-floating col-md-4 col-sm-12 col-12 mb-3">
              <input
                type="text"
                className="form-control"
                id="speciality"
                autoComplete="off"
                placeholder="Speciality"
                name="speciality"
                value={values.speciality || ""}
                onChange={changeHandler}
              />
              {errors.speciality && (
                <p style={{ color: "red" }}>{errors.speciality}</p>
              )}

              <label htmlFor="name">Speciality </label>
            </div>
          )}
          {props.type === "organisation" && (
            <div className="form-floating col-md-4 col-sm-12 col-12 mb-3">
              <input
                type="text"
                className="form-control"
                id="desig"
                autoComplete="off"
                placeholder="Name"
                name="designation"
                value={values.designation || ""}
                onChange={changeHandler}
              />
              {errors.designation && (
                <p style={{ color: "red" }}>{errors.designation}</p>
              )}
              <label htmlFor="desig">Designation </label>
            </div>
          )}

          {props.type === "doctor" && (
            <div className="form-floating col-md-4 col-sm-12 col-12 mb-3">
              <input
                type="text"
                className="form-control"
                id="current_org"
                autoComplete="off"
                placeholder="Current Organisation"
                name="current_org"
                value={values.current_org || ""}
                onChange={changeHandler}
              />
              {errors.current_org && (
                <p style={{ color: "red" }}>{errors.current_org}</p>
              )}

              <label htmlFor="name">Current Organisation </label>
            </div>
          )}
          {props.type === "patient" && (
            <div className="form-floating col-md-4 col-sm-12 col-12 mb-3">
              <input
                type="number"
                className="form-control"
                id="pat_age"
                min="1"
                max="100"
                autoComplete="off"
                placeholder="Age"
                name="age"
                value={values.age}
                onChange={changeHandler}
              />
              {errors.age && <p style={{ color: "red" }}>{errors.age}</p>}
              <label htmlFor="name">Age </label>
            </div>
          )}
          {props.type === "patient" && (
            <div
              className="col-md-4 col-sm-12 col-12 mhc-selectbox mb-4"
              name="gender"
              value={values.gender}
              onChange={changeHandler}
            >
              <select className="form-select">
                <option>Male</option>
                <option>Female</option>
                <option>Other</option>
              </select>
              <label htmlFor="floatingSelect">Gender</label>
            </div>
          )}
        </div>
        <div className="form-row">
          <div className="col-md-4 col-sm-12 col-12 mhc-selectbox mb-4">
            <select
              className="form-select"
              value={values.country}
              name="country"
              onChange={changeHandler}
            >
              <option>India</option>
              <option>US</option>
            </select>
            {errors.country && <p style={{ color: "red" }}>{errors.country}</p>}

            <label htmlFor="floatingSelect">Select Country</label>
          </div>
          <div className="col-md-4 col-sm-12 col-12 mhc-selectbox mb-4">
            <select
              className="form-select"
              onChange={changeHandler}
              name="state"
              value={values.state}
            >
              <option>Karnataka</option>
              <option>Haryana</option>
            </select>
            {errors.state && <p style={{ color: "red" }}>{errors.state}</p>}

            <label htmlFor="floatingSelect">Select State</label>
          </div>

          <div className="form-floating col-md-4 col-sm-12 col-12 mb-3">
            <input
              type="text"
              className="form-control"
              id="doc_city"
              autoComplete="off"
              placeholder="City"
              name="city"
              value={values.city || ""}
              onChange={changeHandler}
            />
            {errors.city && <p style={{ color: "red" }}>{errors.city}</p>}

            <label htmlFor="name">City </label>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-4 col-sm-12 col-12">
            <div className="d-flex contactPhone">
              <div className="w-25">
                <select
                  className="form-select preaddon "
                  aria-label="Default select example"
                >
                  <option defaultValue="">+91</option>
                  <option defaultValue="">+92</option>
                </select>
              </div>
              <div className="w-75">
                <div className="form-floating ">
                  <input
                    type="text"
                    className="form-control"
                    id="doc_phone"
                    autoComplete="off"
                    placeholder="Phone"
                    name="mobile"
                    value={values.mobile || ""}
                    onChange={changeHandler}
                  />
                  {errors.mobile && (
                    <p style={{ color: "red" }}>{errors.mobile}</p>
                  )}

                  <label htmlFor="name">Phone </label>
                </div>
              </div>
            </div>
          </div>
          <div className="form-floating col-md-4 col-sm-12 col-12 mb-3">
            <input
              type="text"
              className="form-control"
              id="doc_email"
              autoComplete="off"
              placeholder="Email"
              name="email"
              value={values.email || ""}
              onChange={changeHandler}
            />
            {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}

            <label htmlFor="name">Email </label>
          </div>

          <div className="col-md-4 col-sm-12 col-12 mhc-selectbox mb-4">
            <select
              className="form-select"
              name="preferred_time"
              value={values.preferred_time}
              onChange={changeHandler}
            >
              <option selected disabled value={'PT'}>
                Preferred Time
              </option>
              <option value="8:00 AM - 12:00 PM">8:00 AM - 12:00 PM </option>
              <option value="12:00 PM - 4:00 PM">12:00 PM - 4:00 PM</option>
              <option value="4:00 PM - 8:00 PM">4:00 PM - 8:00 PM</option>
            </select>
            {errors.preferred_time && (
              <p style={{ color: "red" }}>{errors.preferred_time}</p>
            )}

            <label htmlFor="floatingSelect">Preferred Time</label>
          </div>
        </div>
        <div className="form-group ">
          <span className="Query">
            <textarea
              cols="4"
              rows="4"
              className="form-control contact-textarea"
              id="message"
              placeholder="Query"
              name="query"
              value={values.query || ""}
              onChange={changeHandler}
            ></textarea>
          </span>
        </div>

        <div className="form-row">
          <div className="col-xs-12 col-sm-8 col-md-7">
            <div className="text-dark">
              Please enter the characters you see in the box
            </div>
            <div className="captcha-text">
              <img
                className="form-control captcha_width"
                width="84"
                height="25"
                alt="captcha"
                src={props.capcha}
              />
            </div>
            <span className="captcha-field">
              <span className=" captch">
                <input
                  type="text"
                  placeholder="Enter Captcha"
                  name="capcha"
                  onChange={changeHandler}
                  size="40"
                  value={values.capcha}
                  className="form-control"
                  id="captcha"
                />
                {errors.capcha && (
                  <p style={{ color: "red" }}>{errors.capcha}</p>
                )}
              </span>
            </span>
          </div>
          <div className="col-xs-12 col-sm-4   col-md-5 text-center d-flex align-self-center">
            <button
              className="btn btn-primary rounded-1 mb-2 mb-sm-0 "
              type="submit"
            >
              Let's Connect
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DoctorForm;
