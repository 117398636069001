import React, { useEffect, useLayoutEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useLocation } from "react-router";
import capcha from "../../assets/images/capcha.png";
import Breadcrumb from "../../component/breadcrumb";
import BreadcrumbWithUrl from "../../component/breadcrumb/breadcrumb-with-url";
import RegistrationForm from "./RegistrationForm";

const ContactUs = (props) => {
  const location = useLocation();
  const [activeKey,setActiveKey] = useState("doctor")
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const type = params.get("type");
    console.log('type: ', type);
    if(type && (type === 'doctor'|| type==='organization' || type==='patient')){
      console.log('Claeeddffff');
        setActiveKey(type)
    }
  }, [location]);
  const breadCrumbData = [
    { type: 1, url: "/", name: "Home" },
    { type: 2, name: 'Contact Us' },
  ];
  console.log(activeKey,'Activekye>>>>>>>>');
  return (
    <React.Fragment>
      <Container id="contacts">
      <BreadcrumbWithUrl breadcrumbs={breadCrumbData} />

        <div className="row">
          <div className="col-lg-4 col-md-12 col-sm-12 d-flex align-items-stretch aos-init aos-animate">
            <div className="info">
              <div className="address">
                <h3>Contact Us</h3>
                <p>We're happy to answer your questions</p>
                <p className="call-info d-flex align-items-center">
                  <span className="icon">
                    <i
                      className="mhc-icon  icon-icon-phone"
                      aria-hidden="true"
                    ></i>
                  </span>

                  <span>011 41179678</span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-12 ">
            <div className="radiotab">
              <Tabs
                activeKey={activeKey}
                defaultActiveKey={activeKey}
                id="uncontrolled-tab-example"
                className="mb-0"
                onSelect={(key)=>{
                  setActiveKey(key)

                }}
              >
                <Tab
                  eventKey="doctor"
                  title={
                    <>
                      <label>
                        <input
                          className="form-check-input mt-1"
                          type="radio"
                          id="doctor"
                          name="doctor"
                          value="doctor"
                          checked={activeKey==='doctor'}
                          // defaultChecked
                        />{" "}
                        I’m A Doctor{" "}
                      </label>
                    </>
                  }
                >
                  <RegistrationForm capcha={capcha} type="doctor" />
                </Tab>
                <Tab
                  eventKey="patient"
                  title={
                    <>
                      <label>
                        <input
                          className="form-check-input mt-1"
                          type="radio"
                          id="patient"
                          name="patient"
                          value="patient"
                          checked={activeKey==='patient'}

                        />{" "}
                        I’m A Patient{" "}
                      </label>
                    </>
                  }
                >
                  <RegistrationForm capcha={capcha} type="patient" />
                </Tab>
                <Tab
                  eventKey="organization"
                  title={
                    <>
                      <label>
                        <input
                          className="form-check-input mt-1"
                          type="radio"
                          id="organization"
                          name="organization"
                          value="organization"
                          checked={activeKey==='organization'}

                        />{" "}
                        I’m An Organisation{" "}
                      </label>
                    </>
                  }
                >
                  <RegistrationForm capcha={capcha} type="organisation" />

                  {/* <form className="organisation">
                    <div className="form-row">
                      <div className="form-floating col-md-4 col-sm-12 col-12 mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="org_name"
                          autoComplete="off"
                          placeholder="Name"
                        />
                        <label htmlFor="org_name">Organisation Name </label>
                      </div>
                      <div className="form-floating col-md-4 col-sm-12 col-12 mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="my_name"
                          autoComplete="off"
                          placeholder="Name"
                        />
                        <label htmlFor="my_name">My Name </label>
                      </div>
                      <div className="form-floating col-md-4 col-sm-12 col-12 mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="desig"
                          autoComplete="off"
                          placeholder="Name"
                        />
                        <label htmlFor="desig">Designation </label>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-4 col-sm-12 col-12 mhc-selectbox mb-4">
                        <select className="form-select">
                          <option defaultValue="1">India</option>
                          <option defaultValue="2">US</option>
                        </select>
                        <label htmlFor="floatingSelect">Select Country</label>
                      </div>
                      <div className="col-md-4 col-sm-12 col-12 mhc-selectbox mb-4">
                        <select className="form-select">
                          <option defaultValue="1">Karnataka</option>
                          <option defaultValue="2">Haryana</option>
                        </select>
                        <label htmlFor="floatingSelect">Select State</label>
                      </div>

                      <div className="form-floating col-md-4 col-sm-12 col-12 mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="doc_city"
                          autoComplete="off"
                          placeholder="City"
                        />
                        <label htmlFor="name">City </label>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-4 col-sm-12 col-12">
                        <div className="d-flex contactPhone">
                          <div className="w-25">
                            <select
                              className="form-select preaddon"
                              aria-label="Default select example"
                            >
                              <option defaultValue="">+91</option>
                              <option defaultValue="">+91</option>
                            </select>
                          </div>
                          <div className="w-75">
                            <div className="form-floating ">
                              <input
                                type="text"
                                className="form-control"
                                id="doc_phone"
                                autoComplete="off"
                                placeholder="Phone"
                              />
                              <label htmlFor="name">Phone </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-floating col-md-4 col-sm-12 col-12 mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="doc_email"
                          autoComplete="off"
                          placeholder="Email"
                        />
                        <label htmlFor="name">Email </label>
                      </div>

                      <div className="col-md-4 col-sm-12 col-12 mhc-selectbox mb-4">
                        <select className="form-select">
                          <option selected disabled></option>
                          <option value="1">8:00 AM - 12:00 PM </option>
                          <option value="2">12:00 PM - 4:00 PM</option>
                          <option value="3">4:00 PM - 8:00 PM</option>
                        </select>
                        <label htmlFor="floatingSelect">Preferred Time</label>
                      </div>
                    </div>
                    <div className="form-group ">
                      <span className="Query">
                        <textarea
                          cols="4"
                          rows="4"
                          className="form-control contact-textarea"
                          id="message"
                          placeholder="Query"
                        ></textarea>
                      </span>
                    </div>

                    <div className="form-row">
                      <div className="col-xs-12 col-sm-8 col-md-7">
                        <div className="text-dark">
                          Please enter the characters you see in the box
                        </div>
                        <div className="captcha-text">
                          <img
                            className="form-control captcha_width"
                            width="84"
                            height="28"
                            alt="captcha"
                            src={capcha}
                          />
                        </div>
                        <span className="captcha-field">
                          <span className=" captch">
                            <input
                              type="text"
                              placeholder="Enter Captcha"
                              name="Name"
                              size="40"
                              className="form-control"
                              id="captcha"
                            />
                          </span>
                        </span>
                      </div>
                      <div className="col-xs-12 col-sm-4   col-md-5 text-center d-flex align-self-center">
                        <a
                          href="#"
                          className="btn btn-primary rounded-1 mb-2 mb-sm-0 "
                        >
                          Let's Connect
                        </a>
                      </div>
                    </div>
                  </form> */}
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </Container>

      <section className="maparea">
        <div className="map_location">
          <div className="container-fluid px-0 map mt-5 ">
            {/* <div className='row'>
    <div className='col-12'>
    <iframe height="785" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.690524740723!2d77.07010681516029!3d28.458744082486703!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d198fee7fc3e9%3A0xa5bbac47dc9f48ad!2sMyHealthcare%20Technologies%20Private%20Limited(Gurgaon)!5e0!3m2!1sen!2sin!4v1612432824611!5m2!1sen!2sin" style={{ border: '0', width: '100%', height: '785px' }} ></iframe>
    </div>
    </div> */}
            <div className="row">
              <div className="col-lg-12 ">
                <Tabs
                  defaultActiveKey="gurgaon"
                  id="uncontrolled-tab-example"
                  className="mb-0 d-flex flex-column"
                >
                  <Tab
                    eventKey="gurgaon"
                    title={
                      <>
                        <h4>GURGAON (INDIA)</h4>
                        <p>
                          MyHealthcare, Huda City Centre, Sector 29, Gurugram –
                          122002, Haryana
                        </p>
                      </>
                    }
                  >
                    <div className="row">
                      <div className="col-lg-12 details">
                        <iframe
                          height="785"
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.690524740723!2d77.07010681516029!3d28.458744082486703!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d198fee7fc3e9%3A0xa5bbac47dc9f48ad!2sMyHealthcare%20Technologies%20Private%20Limited(Gurgaon)!5e0!3m2!1sen!2sin!4v1612432824611!5m2!1sen!2sin"
                          style={{
                            border: "0",
                            width: "100%",
                            height: "785px",
                          }}
                          frameBorder={"0"}
                          allowFullScreen={"allowfullscreen"}
                        ></iframe>
                      </div>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="bengaluru"
                    title={
                      <>
                        <h4>Bangalore (India)</h4>
                        <p>
                          91 Springboard George Thangaiah Complex 13, 80 Feet
                          Road, Near C V Raman Hospital Indiranagar
                        </p>
                      </>
                    }
                  >
                    <div className="row">
                      <div className="col-lg-12 details">
                        <iframe
                          height="785"
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.8473659651972!2d77.64345731482214!3d12.981612590849311!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xca127d2ca2a56ae9!2sMyHealthcare%20Technologies%20Private%20Limited!5e0!3m2!1sen!2sin!4v1612434112697!5m2!1sen!2sin%22"
                          style={{
                            border: "0",
                            width: "100%",
                            height: "785px",
                          }}
                          frameBorder={"0"}
                          allowFullScreen={"allowfullscreen"}
                        ></iframe>
                      </div>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="kuala"
                    title={
                      <>
                        <h4>Kuala Lumpur (Malaysia)</h4>
                        <p>
                          Publika, C-2-2-6, Solaris Dutamas, No. 1, Jalan
                          Dutamas, 50480, Kuala Lumpur, Malaysia
                        </p>
                      </>
                    }
                  >
                    <div className="row">
                      <div className="col-lg-12 details">
                        <iframe
                          width="100%"
                          height="785"
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1991.8537399473637!2d101.66652945790382!3d3.171516599422687!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc485df80d8671%3A0x7c10d968f8b806cf!2sPublika+Dutamas%2C+1%2C+Jalan+Dutamas%2C+Solaris+Dutamas%2C+50480+Kuala+Lumpur%2C+Wilayah+Persekutuan+Kuala+Lumpur%2C+Malaysia!5e0!3m2!1sen!2sin!4v1496899498542"
                          style={{
                            border: "0",
                            width: "100%",
                            height: "785px",
                          }}
                          frameBorder={"0"}
                          allowFullScreen={"allowfullscreen"}
                        ></iframe>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ContactUs;
