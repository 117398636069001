import React, { useEffect, useState } from "react";
import CountUp, { useCountUp } from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { Link } from "react-router-dom";
function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting)
  );

  useEffect(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
}
const OurJourney = ({ journeyCounter }) => {
  const countUpRef = React.useRef(null);
  const { start, pauseResume, reset, update } = useCountUp({
    ref: countUpRef,
    start: 0,
    end: 1234567,
    delay: 1000,
    duration: 5,
    onReset: () => console.log("Resetted!"),
    onUpdate: () => console.log("Updated!"),
    onPauseResume: () => console.log("Paused or resumed!"),
    onStart: ({ pauseResume }) => console.log(pauseResume),
    onEnd: ({ pauseResume }) => console.log(pauseResume),
  });
  var countDecimals = function (value) {
    console.log('value: uuuuuuuuuuuuuuuu', value);
    if(Math.floor(value) === value) return 0;
    let arr = value.toString().split(".")
    if(arr.length>1){
      return arr[1].length ; 

    }
    return 0
}
  return (
    <section className="mhc-our-journey">
      <div className="container">
        <h1 className="text-left text-lg-center">Our Journey</h1>
        <p className="text-left text-lg-center">
          At MyHealthcare, we focus on complete healthcare ecosystem solutions.
          Our numbers are just a reflection of the same!
        </p>

        <div className="row justify-content-start justify-content-lg-center mt-4">
          {journeyCounter
            ? journeyCounter.map((counter, index) => {
                return (
                  <div
                    className={`col-md-12 col-lg-4 col-xl-3 ${
                      index !== 0 ? "mt-4 mt-lg-0" : ""
                    }`}
                    key={counter._id}
                  >
                    <div className="mhc-journey-card">
                      <div>
                        <div>
                          <img src={counter.image} className="icon" />
                        </div>
                      </div>
                      <div className="d-flex flex-row flex-lg-column justify-content-between justify-content-lg-start w-100">
                        <span>{counter.title}</span>
                        <div className="fs-2 fw-bolder d-flex flex-wrap">
                          {/* {counter.number && <>{counter.number} <div dangerouslySetInnerHTML={{__html:counter.text}}></div></>} */}
                          {counter.number && <CountUp end={parseFloat(counter.number)} redraw={true}  decimals={countDecimals(counter.number)}>
                            {({ countUpRef, start }) => (
                              <VisibilitySensor onChange={start} delayedCall>
                                <span ref={countUpRef} />
                              </VisibilitySensor>
                            )}
                          </CountUp>}
                          {counter.number && <div dangerouslySetInnerHTML={{__html:counter.text}}></div>}
                          {!counter.number && <CountUp end={counter.text.replace(/\D/g, "")} redraw={true}>
                            {({ countUpRef, start }) => (
                              <VisibilitySensor onChange={start} delayedCall>
                                <span ref={countUpRef} />
                              </VisibilitySensor>
                            )}
                          </CountUp>}
                          {/* <div dangerouslySetInnerHTML={{ __html: counter.text }}></div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            : ""}
        </div>

        <div className="row mt-4">
          <div className="col-12 text-center">
            <Link className="btn btn-primary rounded-1" to="/about-us">
              About us
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurJourney;
