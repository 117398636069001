import React from "react";
import { Container } from "react-bootstrap";
import img1 from '../../assets/dust/Image 30.webp'
import img2 from '../../assets/dust/Image 31.webp'
import Breadcrumb from "../../component/breadcrumb";
export default class PurchaseDevice extends React.Component{
    render(){
        return(
           <Container>
                       <Breadcrumb url="" chlidrenUrl="" text="purchase device" />
                       <h1 className="fs-4">Purchase Device</h1>
            <div className="row py-4">
                <div className="col-xxl-4 col-xl-5 col-lg-6">
                   <div className="purchase-Device-card">
            <div className="d-flex align-items-center     justify-content-center">
                <div>
                    <img src={img1} alt="" className="device-img" />
                </div>
                <div className="ms-5">
                    <img src={img2} alt="" className="device-logo" />
                    <p>Kardia ECG Device</p>
                    <button type="submit" className="btn btn-primary">Click Here</button>
                </div>
            </div>
                   </div>
                </div>
                <div className="col-xxl-4 col-xl-5 col-lg-6 mt-4 mt-lg-0">
                   <div className="purchase-Device-card">
            <div className="d-flex align-items-center     justify-content-center">
                <div>
                    <img src={img1} alt="" className="device-img" />
                </div>
                <div className="ms-5">
                    <img src={img2} alt="" className="device-logo" />
                    <p>Kardia ECG Device</p>
                    <button type="submit" className="btn btn-primary">Click Here</button>
                </div>
            </div>
                   </div>
                </div>
            </div>

            <br  className="d-none d-xl-block"/>
            <br  className="d-none d-xl-block"/>
            <br  className="d-none d-xl-block"/>
            <br  className="d-none d-xl-block"/>
            <br  className="d-none d-xl-block"/>
            <br  className="d-none d-xl-block"/>
            <br  className="d-none d-xl-block"/>
            <br  className="d-none d-xl-block"/>
            <br  className="d-none d-xl-block"/>
            <br  className="d-none d-xl-block"/>
            <br  className="d-none d-xl-block"/>
            <br  className="d-none d-xl-block"/>
            <br  className="d-none d-xl-block"/>
            <br  className="d-none d-xl-block"/>
            <br  className="d-none d-xl-block"/>
           </Container>
        )
    }
}