import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { compareThyrocare } from "../../actions/post-call";

import { setVendorReducer, clearVendorReducer } from "../../redux/slice/vendor";
import { clearPackageDetailReducer, setPackageDetailReducer } from "../../redux/slice/package-detail";
import BreadcrumbWithUrl from "../../component/breadcrumb/breadcrumb-with-url";
import {  decodeString, encodeString } from "../../constants/app-constants";

const PackageComparison = () => {

    const dispatch = useDispatch();
  
    const bookingSource = useSelector((state) => state.bookingSource.value);
    const [catName, setCatName] = useState("");

    const { compare1, compare2 } = useParams();
    const [comparedResult, setComparedResult] = useState(null);
    const [testList, setTestList] = useState(null);
    const [parentLocation, setParentLocation] = useState(null);
    const {categoryName} = useParams()
    useEffect(() => {
        setCatName(decodeString(categoryName));

        let currentBookingSource = window.location.pathname.split('/');
        if (currentBookingSource) {
            setParentLocation(currentBookingSource[1]);
        }

        if (bookingSource && bookingSource !== 'package-booking') {
            dispatch(clearVendorReducer());
        }
    }, [])

    useEffect(async () => {
        let payload = { diagnosis_ids: [compare1, compare2] };
        const comparison = await compareThyrocare(payload);
        if (comparison && comparison.data && comparison.data.PACKAGES) {

            for (let index = 0; index < comparison.data.PACKAGES.length; index++) {
                if (comparison.data.PACKAGES[index].childs) {
                    let modifiedChild = comparison.data.PACKAGES[index].childs.reduce(function (r, a) {
                        r[a.group_name] = r[a.group_name] || [];
                        r[a.group_name].push(a);
                        return r;
                    }, Object.create(null));

                    let sortedChild = [];
                    for (var x in modifiedChild) {
                        sortedChild.push(modifiedChild[x]);
                    }

                    let mChild = { mData: sortedChild };
                    comparison.data.PACKAGES[index] = { ...comparison.data.PACKAGES[index], ...mChild };
                }
            }

            let packageDetail = [];
            for (let index = 0; index < comparison.data.PACKAGES.length; index++) {
                const aElement = comparison.data.PACKAGES[index].mData;

                for (let bIndex = 0; bIndex < aElement.length; bIndex++) {
                    const bElement = aElement[bIndex];

                    let testName = '';
                    let groupName = '';
                    let newArrayIndex = -1;
                    for (let cIndex = 0; cIndex < bElement.length; cIndex++) {
                        const cElement = bElement[cIndex];

                        groupName = cElement['group_name'];
                        let newTestName = testName + cElement['name'];
                        testName = (bElement.length === (cIndex + 1)) ? newTestName : newTestName + ', ';
                    }

                    if (index === 0) {
                        packageDetail.push({
                            group_name: groupName,
                            test1: testName,
                            test2: ''
                        });
                    } else {
                        newArrayIndex = packageDetail.map(function (x) { return x.group_name; }).indexOf(groupName.toString());
                        if (newArrayIndex === -1) {
                            packageDetail.push({
                                group_name: groupName,
                                test1: '',
                                test2: testName
                            });
                        } else {
                            packageDetail[newArrayIndex]['test2'] = testName;
                        }
                    }
                }
            }
            setTestList(packageDetail);
            setComparedResult(comparison.data.PACKAGES);
        }
    }, [compare1, compare2])

    function setForwardData(id, packageDetail) {
        dispatch(setVendorReducer(id));
        dispatch(clearPackageDetailReducer());
        dispatch(setPackageDetailReducer(packageDetail));
    }

    function setForwardDataForCP(id ,packageDetails) {
        dispatch(setVendorReducer(id));
        dispatch(clearPackageDetailReducer());
        dispatch(setPackageDetailReducer(packageDetails));
    }

    const breadCrumbData = [
        { type: 1, url: '/', name: 'Home' },
        { type: 1, url: '/care-package-categories', name: 'Care Packages' },
        { type: 4, url: '', name: catName.replaceAll('_', ' ') },
        { type: 3, url: '', name: 'Package Comparison' },
        
    ];

    return (
        
        <React.Fragment>
            <div className='container'>
                <BreadcrumbWithUrl breadcrumbs={breadCrumbData} />
                <div className="row">
                    <div className="col-lg-6 ">
                        <h1 className="fs-4 mb-1">Package Comparison</h1>
                    </div>
                </div>
                {!comparedResult ?
                    <div className="mhc-Loding text-center my-5">
                        <img src="../../loader.gif" alt="loader" />
                    </div>
                    :
                    <div className='mhc-compare'>
                        <div className='table-compare table-responsive mt-2 mb-4' >
                            <Table bordered className='table-width'>
                                <thead>
                                    <tr>
                                        <th></th>
                                        {
                                            comparedResult.map((item) => {
                                                return (
                                                    <th key={item.id}>
                                                        <div className='row'>
                                                            <div className='col-8 col-sm-7 d-flex align-items-center'>
                                                                <h6>{item.name}</h6>
                                                            </div>
                                                            <div className='col-4 col-sm-5 d-flex justify-content-end'>
                                                                <span className='img-holder'><img src={item.image} alt={item.name} className='vendor-image'></img></span>
                                                            </div>
                                                        </div>
                                                        <div className='row mt-3'>
                                                            <div className='col-8 d-flex align-items-center'>
                                                                <span><del>INR {item.rates.pay_amt1}</del></span> <span className="text-primary ms-3">INR {item.rates.pay_amt}</span>
                                                            </div>
                                                            <div className='col-4 d-flex justify-content-end'>
                                                                {
                                                                    parentLocation === 'care-package-comparison' ?
                                                                        <Link onClick={setForwardDataForCP(item.vendor_id, item)} to={`/care-packages/${compare1}/${compare2}/care-package-booking/${encodeString(item.name)}`} className="btn btn-primary px-4">Book</Link>
                                                                        :
                                                                        <Link onClick={setForwardData(item.vendor_id, item)} to={`/package-booking`} className="btn btn-primary px-4">Book</Link>
                                                                }
                                                            </div>
                                                        </div>
                                                    </th>
                                                )
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        // <td className=`${index ? 'bg-cell' : 'bg-cell-last'}`>{item.group_name}</td>
                                        testList.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className='bg-cell-last'>{item.group_name}</td>
                                                    <td>{item.test1}</td>
                                                    <td>{item.test2}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                }
            </div>

        </React.Fragment>
    )
}

export default PackageComparison;