import React, { useState, useEffect, useRef } from "react";
import { Accordion, Container, Modal } from "react-bootstrap";
import Breadcrumb from "../../component/breadcrumb/index";
import SearchForm from "../../component/search/index";
import Fliter from "../../assets/dust/fliter.svg";
import { Link, useParams } from "react-router-dom";
import {
  clinicDistance,
  doctorAvailability,
  getAllDoctors,
  indianCities,
} from "../../actions/get-calls";
import { specialityInfoById } from "../../actions/get-calls";
import {
  doctorAvailabilityByFilter,
  searchTextAPI,
} from "../../actions/post-call";
import SearchFormWidget from "../../component/search/search-form-widget";
import {
  dateFormatIntoDDMMYYYY,
  decodeString,
} from "../../constants/app-constants";
import BreadcrumbWithUrl from "../../component/breadcrumb/breadcrumb-with-url";
import SearchTextWidget from "../../component/search/search-text-widget";
import FilterDoctorModal from "./FilterDoctorModal";
import Doctors from "../consult-doctor/doctors";

export default function DoctorsTab({keyword,type,callingType}) {
  console.log('type: ', type);
  const listInnerRef = useRef();
  const { hospitalName, Id, sId } = useParams();
  const [filterModal, setFilterModal] = useState(false);
  const [doctor, setDoctor] = useState([]);
  const [allDoctors, setAllDoctors] = useState([]);
  const [specialInfo, setSpecialInfo] = useState();
  const [consultType, setConsultType] = useState("0");

  const [allDistances, setAllDistances] = useState(null);
  const [allCities, setAllCities] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [currentCityPage, setCurrentCityPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [distance, setDistance] = useState(0);
  const [dateType, setDateType] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [gender, setGender] = useState(0);
  const [specialityName, setSpecialityname] = useState();
  const [availabilityType,setAvailibilityType] = useState('');
  const [filterApplied,setFilterApplied] = useState(false);
  useEffect(async () => {
    getDoctors();

    const specials = await specialityInfoById(sId);
    setSpecialInfo(specials.data);

    setSpecialityname(specials.data.speciality.name);
    const distanceData = await clinicDistance();
    if (distanceData) {
      setAllDistances(distanceData.data.distance);
    }
  }, [type]);

  useEffect(() => {
    if(keyword.length===0 && callingType==='doctors'){
      getDoctors();
    }
    if(keyword.length>2 && callingType==='doctors'){
      getDoctors();
    }
  }, [keyword,callingType,type]);

  function getDoctors() {
    const delayDebounceFn = setTimeout(async () => {
      setLoading(true);
      const doctors = await getAllDoctors(
        keyword,type===1?'doctor':'specialties'
      );
      setDoctor(doctors.data.doctors);
      setAllDoctors(doctors.data.doctors)
      setLoading(false);
    }, 500);
    
    return () => clearTimeout(delayDebounceFn);
  }

  useEffect(async () => {
    if (filterModal) {
      getCities();
    } else {
      setAllCities([]);
      setHasMore(true);
      setCurrentCityPage(1);
    }
  }, [filterModal]);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        getCities();
      }
    }
  };

  async function getCities() {
    if (currentCityPage && hasMore) {
      const cityList = await indianCities(1, currentCityPage);

      if (cityList && cityList.data) {
        setAllCities([...allCities, ...cityList.data.cities]);
        if (cityList.data.pagination.links.next) {
          let cPage = currentCityPage + 1;
          setCurrentCityPage(cPage);
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      }
    }
  }

  async function filterDoctor(event) {
    event.preventDefault();
    
    
    
    
    
    if (
      distance ||
      (cities && cities.length) ||
      startDate ||
      endDate ||
      gender
    ) {
      
      let payLoad = {
        radius: distance,
        city: cities,
        start_date: startDate,
        end_date: endDate,
        gender,
        status: "1",
      };
      const response = await doctorAvailabilityByFilter(sId, payLoad);
      setDoctor(response.data.doctors);
      setFilterApplied(true)
      
      setFilterModal(false);
    } else {
      setFilterModal(false);
    }
  }

  // const breadCrumbData = [
  //   { type: 1, url: "/", name: "Home" },
  //   { type: 1, url: "/book-consult/hospitals", name: "Book Consult" },
  //   {
  //     type: 1,
  //     url: `/hospital-consult/${Id}`,
  //     name: decodeString(hospitalName),
  //   },
  //   { type: 3, url: "", name: specialityName },
  // ];
  const resetValues = ()=>{
    setCities([]);
    setStartDate(null);
    setEndDate(null);
    setGender(0);
    setAvailibilityType('');
    setDistance(null);
    setDoctor(allDoctors)
  }
  return (
    <React.Fragment>
      <section>
        <Container>
          {/* <BreadcrumbWithUrl breadcrumbs={breadCrumbData} /> */}

          {/* <div className="d-flex align-items-center mt-4">
            <SearchTextWidget
              placeHolder={`Search doctors`}
              btnDisplay={true}
              setKeyword={setKeyword}
            />
            <span
              className="mhc-filter-btn"
              onClick={() =>{ 
                if(!filterApplied){
                  resetValues();
                }
                setFilterModal(true)
              }}
            >
              <img src={Fliter} />
            </span>
          </div> */}
          <div className="mhc-consult-type d-flex bd-highlight flex-column flex-lg-row align-items-start  align-items-lg-center mt-4 position-relative ">
            <div className="order-0 order-lg-0">
              <span className="fw-bold ">Consult Type</span>
            </div>
            <div className="order-2 order-lg-0">
              <ul className="list-unstyled mb-0 mt-4 mt-lg-0 d-flex align-items-center">
                <li>
                  <div className="form-check mt-1 ms-0 ms-lg-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="All"
                      value="0"
                      checked={consultType === "0"}
                      onChange={(e) => setConsultType(e.target.value)}
                    />
                    <label className="form-check-label" htmlFor="All">
                      All
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check mt-1 ms-3 ms-sm-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="Virtual"
                      value="1"
                      checked={consultType === "1"}
                      onChange={(e) => setConsultType(e.target.value)}
                    />
                    <label className="form-check-label" htmlFor="Virtual">
                      Virtual
                    </label>
                  </div>
                </li>
                <li>
                  <div className="form-check mt-1 ms-3 ms-sm-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="Hospital"
                      value="2"
                      checked={consultType === "2"}
                      onChange={(e) => setConsultType(e.target.value)}
                    />
                    <label className="form-check-label" htmlFor="Hospital">
                      At Hospital/ Clinic
                    </label>
                  </div>
                </li>
              </ul>
            </div>

            {/* <div className="ms-lg-auto order-1 order-lg-0">
              <Link
                to={`/book-consult/consult-doctor-earlist-slot/${Id}/${sId}`}
                className="btn btn-outline-primary rounded-1"
              >
                Earliest Slots
              </Link>
            </div> */}
          </div>
          {loading?<div className="mhc-Loding text-center my-5">
              <img src="../loader.gif" alt="loader" />
            </div>:<div className="row mt-4">
            <Doctors doctors={doctor} consultType={consultType} sId={sId}  callingType={callingType}/>
          </div>}
        </Container>
      </section>

      <FilterDoctorModal
        filterModal={filterModal}
        values={
          {
            gender,
            cities,
            startDate,
            endDate,
            distance,
          }
        }
        onHide={() => {
          setStartDate(null);
          setEndDate(null);
          setFilterModal(false);
        }}
        allCities={allCities}
        listInnerRef={listInnerRef}
        onScroll={() => onScroll()}
        allDistances={allDistances}
        changeDistance={(e, item) => {
          if (e.target.checked) setDistance(item.id);
        }}
        onAvailibilityChange={(e) => {
          if (e.target.checked) {
            const next2Days = new Date(Date.now() + 2 * 24 * 60 * 60 * 1000);
            setStartDate(dateFormatIntoDDMMYYYY(next2Days));
            setEndDate(dateFormatIntoDDMMYYYY(next2Days));
          }
        }}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        onGenderChange={(e, gender) => {
          if (e.target.checked) setGender(gender);
        }}
        closeModal={() => setFilterModal(false)}
        filterDoctor={filterDoctor}
        onCityChange={(e, item) => {
          if (e.target.checked) {
            setCities([...cities, item.id]);
          } else {
            setCities((prevCities) => {
              return prevCities.filter((cityId) => cityId !== item.id);
            });
          }
        }}
        startDate={startDate}
        endDate={endDate}
        availabilityType={availabilityType}
        setAvailibilityType={(type)=>{
          setAvailibilityType(type)
        }}
        resetValues={resetValues}
      />
    </React.Fragment>
  );
}
