import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import countries from "../../assets/data/countries.json";
import UserProfile from "../../assets/dust/user-profile.jpg";

import antiClock from "../../assets/images/anticlock.png";
import {
  getOTP,
  getOTPWithPayload,
  registerOTP,
  switchPatient,
  verifyMobileOTP,
} from "../../actions/post-call";
import { getProfile } from "../../actions/get-calls";

import { setCallTokenReducer } from "../../redux/slice/call-token";
import { setSwitchTokenReducer } from "../../redux/slice/switch-token";
import { setAllUsersReducer } from "../../redux/slice/all-users";
import { setSelectedUserReducer } from "../../redux/slice/selected-user";
import { setUserProfileReducer } from "../../redux/slice/user-profile";
import {
  dateFormatIntoDDMMYYYY,
  genderList,
  maritalStatusList,
} from "../../constants/app-constants";

const UserRegister = () => {
  const [DOB, setDOB] = useState(new Date());
  const [allState, setAllState] = useState(null);
  const [allCity, setAllCity] = useState(null);
  const [isTerm, setIsTerm] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [payload,setPayload] = useState({})

  const [isVerify, setIsVerify] = useState(false);
  const [currentOTP, setCurrentOTP] = useState("");
  const [reOtpSend,setReOtpSend] = useState(false)

  const [timeLeft, setTimeLeft] = useState(30);
  const [isResend, setIsResend] = useState(false);

  const dispatch = useDispatch();
  const selectedUser = useSelector((state) => state.selectedUser.value);

  useEffect(() => {
    if (selectedUser) {
      return (window.location.href = "/");
    }
  }, [selectedUser]);

  function verifyOTP() {
    verifyMobileOTP({
      isd_code: "91",
      otp: currentOTP,
      uid: formik.values.mobile,
    })
      .then(function (login_response) {
        let login_res = login_response.data;
        let firstToken = login_response.headers.authorization;

        if (login_res.errors && login_res.errors.length > 0) {
          return showError(login_res.errors[0].message);
        }

        dispatch(setCallTokenReducer(firstToken));
        dispatch(setAllUsersReducer(login_res.users));

        if (login_res && login_res.users && login_res.users.length > 1) {
          switchPatient({ patient_id: login_res.users[0].id }, firstToken)
            .then(function (switch_response) {
              let switch_token = switch_response.headers.authorization;
              let switch_res = switch_response.data;

              if (
                switch_response &&
                switch_res &&
                switch_res.errors &&
                switch_res.errors.length > 0
              ) {
                return showError(switch_res.errors[0].message);
              }

              dispatch(setSwitchTokenReducer(switch_token));
              dispatch(setSelectedUserReducer(switch_res.user));

              getProfile(switch_token)
                .then(function (profile_response) {
                  let profile_res = profile_response.data;

                  if (
                    profile_response &&
                    profile_res &&
                    profile_res.errors &&
                    profile_res.errors.length > 0
                  ) {
                    return showError(profile_res.errors[0].message);
                  }

                  if (profile_response && profile_res) {
                    dispatch(setUserProfileReducer(profile_res.user));
                  }
                })
                .catch(function (err) {});
            })
            .catch(function (err) {});
        } else {
          getProfile(firstToken)
            .then(function (profile_response) {
              let profile_res = profile_response.data;

              if (
                profile_res &&
                profile_res.errors &&
                profile_res.errors.length > 0
              ) {
                return showError(profile_res.errors[0].message);
              }

              if (profile_res) {
                dispatch(setSelectedUserReducer(profile_res.user));
              }
            })
            .catch(function (err) {});
        }

        setIsResend(false);
        timeLeft(30);
        window.location.href = "/";
      })
      .catch(function (err) {});
  }

  useEffect(() => {
    let timer = setInterval(() => {
      if (isResend) {
        if (timeLeft > 0) {
          setTimeLeft(timeLeft - 1);
        } else {
          clearInterval(timer);
          setIsResend(false);
          setTimeLeft(30);
        }
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      mobile: "",
      emergencyContact: "",
      email: "",
      addressOne: "",
      addressTwo: "",
      pincode: "",
      dob: new Date(),
      gender: "0",
      maritalStatus: "0",
      country: "India",
      state: "Delhi",
      city: "New Delhi",
    },

    onSubmit: (values, helpers) => {
      if (values.firstName === "") {
        return showError("First name is mandatory!");
      } else if (values.lastName === "") {
        return showError("Last name is mandatory!");
      } else if (values.mobile === "") {
        return showError("Mobile is mandatory!");
      } else if (values.email === "") {
        return showError("Email is mandatory!");
      } else if (values.dob === "") {
        return showError("DOB is mandatory!");
      } else if (values.gender === "") {
        return showError("Gender is mandatory!");
      } else if (values.maritalStatus === "") {
        return showError("Marital Status is mandatory!");
      } else if (values.addressOne === "") {
        return showError("Address 1 is mandatory!");
      } else if (values.addressTwo === "") {
        return showError("Address 2 is mandatory!");
      } else if (values.country === "") {
        return showError("Country is mandatory!");
      } else if (values.state === "") {
        return showError("State is mandatory!");
      } else if (values.city === "") {
        return showError("City is mandatory!");
      }

      let payload = {
        first_name: values.firstName,
        last_name: values.lastName,
        gender: values.gender,
        dob: dateFormatIntoDDMMYYYY(DOB),
        isd_code: "91",
        mobile_no: values.mobile,
        marital_status: values.maritalStatus,
        emergency_contact: values.emergencyContact,
        email: values.email,
        address_1: values.addressOne,
        address_2: values.addressTwo,
        country: values.country,
        state: values.state,
        city: values.city,
        pin_code: values.pincode,
      };
      setPayload(payload)
      registerOTP(payload)
        .then(function (response) {
          let res = response.data;

          if (res && res.errors) {
            res.errors.map((e) => {
              return showError(e.message);
            });
            return false;
          }
          setIsResend(true);
          setTimeLeft(30);
          setShowOTP(true);
          setIsVerify(true);
          toast.success("Registrated successfully! Please enter OTP to login");
        })
        .catch(function (err) {});
    },
  });

  function showError(str) {
    toast.error(str, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
    });
  }

  useEffect(() => {
    if (formik.values.country) {
      if (countries) {
        let countryIndex = countries
          .map((e) => e.n)
          .indexOf(formik.values.country);
        setAllState(countries[countryIndex]["s"]);
      }
    }
  }, [formik.values.country]);
  useEffect(() => {
    let timer = setInterval(() => {
      if (isResend) {
        if (timeLeft > 0) {
          setTimeLeft(timeLeft - 1)
        } else {
          clearInterval(timer);
          setIsResend(false);
          setTimeLeft(30);
        }
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  });
  useEffect(() => {
    if (allState) {
      let countryIndex = countries
        .map((e) => e.n)
        .indexOf(formik.values.country);
      let stateIndex = allState.map((e) => e.n).indexOf(formik.values.state);
      setAllCity(countries[countryIndex]["s"][stateIndex]["c"]);
    }
  }, [allState, formik.values.state]);

  // function stateList(e) {
  //   formik.setFieldValue("country", e.target.value);
  //   if (countries && e.target.value) {
  //     let countryIndex = countries.map(e => e.n).indexOf(e.target.value);
  //     setAllState(countries[countryIndex]['s']);
  //   }
  // }

  // function cityList(e) {
  //   formik.setFieldValue("state", e.target.value);
  //   if (countries && allState && e.target.value) {
  //     let countryIndex = countries.map(e => e.n).indexOf(formik.values.country);
  //     let stateIndex = allState.map(e => e.n).indexOf(e.target.value);
  //     setAllCity(countries[countryIndex]['s'][stateIndex]['c']);
  //   }
  // }
  const [uploadedFileName, setUploadedFileName] = useState(null);
  const [image,setImage] = useState('')
  const inputRef = useRef(null);
  const handleUpload = (e) => {
    inputRef.current?.click();
    // const [file] = e.target.files;
  };
  const handleDisplayFileDetails = () => {
    console.log('inputRef.current.files[0]: ', inputRef.current.files[0]);
    inputRef.current?.files &&
    setImage(URL.createObjectURL(inputRef.current.files[0]));
      setUploadedFileName(inputRef.current.files[0].name);
  };
  function handleOtpRequest() {
    setReOtpSend(true)

    registerOTP(payload)
      .then(function (x_response) {
    setReOtpSend(false)

        let x_res = x_response.data;

        if (x_res && x_res.errors) {
          x_res.errors.map((e) => {
            return showError(e.message);
          });
          return false;
        }

        setIsResend(true);
        setTimeLeft(30);
      toast.success("OTP sent successfully");

      })
      .catch(function () {
    setReOtpSend(false)

      });
  }
  return (
    <>
      <div className="container">
        {!isVerify ? (
          <form
            className="mhc-register-form py-4"
            onSubmit={formik.handleSubmit}
          >
            <h1 className="fs-4  text-center">Register</h1>
            <div className="row mt-4">
              <div className="col-12 text-center">
                <div className="mhc-user-profile">
                  <img src={image||UserProfile} />
                  <label className="mx-3">Choose file:</label>
                  
                </div>
                <div>
                <input
                    ref={inputRef}
                    onChange={handleDisplayFileDetails}
                    className="d-none"
                    type="file"
                  />
                  <button
                    onClick={handleUpload}
                    className={`btn btn-outline-primary`}
                    type='secondary'
                  >
                    {"Upload"}
                  </button>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <h1 className="fs-5 mb-4 text-center text-lg-start">
                  Patient Details
                </h1>
              </div>

              <div className="col-lg-3">
                <div className="form-floating mb-4">
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    required
                    autoComplete="off"
                    placeholder="First name"
                    onChange={formik.handleChange}
                    value={formik.values.firstName}
                  />
                  <label>
                    First Name <sup>*</sup>
                  </label>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="form-floating mb-4">
                  <input
                    type="text"
                    className="form-control"
                    required
                    id="lastName"
                    autoComplete="off"
                    placeholder="Last name"
                    onChange={formik.handleChange}
                    value={formik.values.lastName}
                  />
                  <label>
                    Last Name <sup>*</sup>
                  </label>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="form-floating mb-4">
                  <input
                    type="text"
                    className="form-control"
                    maxLength="10"
                    required
                    placeholder="Mobile"
                    name="mobile"
                    onChange={formik.handleChange}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    value={formik.values.mobile}
                  />
                  <label htmlFor="name">
                    Mobile <sup>*</sup>
                  </label>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="form-floating mb-4">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="email"
                    required
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  <label htmlFor="name">
                    Email <sup>*</sup>
                  </label>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="form-floating mhc-dob date mb-4">
                  <DatePicker
                    calendarClassName="mhc-input-date"
                    dateFormat="dd/MM/yy"
                    selected={DOB}
                    dropdownMode="select"
                    showYearDropdown
                    showMonthDropdown
                    useShortMonthInDropdown
                    closeOnScroll={true}
                    required
                    onChange={(date) => setDOB(date)}
                    calendarStartDay={1}
                    formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
                  />
                  <i className="mhc-icon icon-calendar"></i>
                  <label htmlFor="Dob" className="dob">
                    DOB <sup>*</sup>
                  </label>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="mhc-selectbox mb-4">
                  <select
                    name="gender"
                    className="form-select"
                    required
                    onChange={formik.handleChange}
                    value={formik.values.gender}
                  >
                    {genderList &&
                      genderList.map((item, index) => {
                        return (
                          <option key={item} value={index}>
                            {item}
                          </option>
                        );
                      })}
                  </select>
                  <label htmlFor="floatingSelect">
                    Gender <sup>*</sup>
                  </label>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="mhc-selectbox mb-4">
                  <select
                    name="maritalStatus"
                    className="form-select"
                    required
                    onChange={formik.handleChange}
                    value={formik.values.maritalStatus}
                  >
                    {maritalStatusList &&
                      maritalStatusList.map((item, index) => {
                        return (
                          <option key={item} value={index}>
                            {item}
                          </option>
                        );
                      })}
                  </select>
                  <label htmlFor="floatingSelect">
                    Marital Status <sup>*</sup>
                  </label>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="form-floating mb-4">
                  <input
                    type="text"
                    className="form-control"
                    id="emergencyContact"
                    placeholder="emergency Contact"
                    name="emergencyContact"
                    onChange={formik.handleChange}
                    value={formik.values.emergencyContact}
                  />
                  <label htmlFor="EmergencyContact">Emergency Contact</label>
                </div>
              </div>

              <div className="col-12">
                <h1 className="fs-5 mb-4 text-center text-lg-start">Address</h1>
              </div>

              <div className="col-lg-6">
                <div className="form-floating mb-4">
                  <input
                    type="text"
                    className="form-control"
                    id="addressOne"
                    required
                    placeholder="addressOne"
                    name="addressOne"
                    onChange={formik.handleChange}
                    value={formik.values.addressOne}
                  />
                  <label htmlFor="AddressLine">
                    Address Line 1 <sup>*</sup>
                  </label>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-floating mb-4">
                  <input
                    type="text"
                    className="form-control"
                    id="addressTwo"
                    placeholder="addressTwo"
                    required
                    name="addressTwo"
                    onChange={formik.handleChange}
                    value={formik.values.addressTwo}
                  />
                  <label htmlFor="AddressLine">Address Line 2</label>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="mhc-selectbox mb-4">
                  <select
                    name="country"
                    required
                    disabled
                    className="form-select"
                    // onChange={(e) => { stateList(e) }}
                    onChange={formik.handleChange}
                    value={formik.values.country}
                  >
                    {countries &&
                      countries.map((item) => {
                        const { n } = item;
                        return (
                          <option key={n} value={n}>
                            {n}
                          </option>
                        );
                      })}
                  </select>
                  <label htmlFor="floatingSelect">Country</label>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="mhc-selectbox mb-4">
                  <select
                    name="state"
                    required
                    className="form-select"
                    onChange={formik.handleChange}
                    // onChange={(e) => { cityList(e) }}
                    value={formik.values.state}
                  >
                    {allState &&
                      allState.map((item) => {
                        const { n } = item;
                        return (
                          <option key={n} value={n}>
                            {n}
                          </option>
                        );
                      })}
                  </select>
                  <label htmlFor="floatingSelect">State</label>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="mhc-selectbox mb-4">
                  <select
                    name="city"
                    className="form-select"
                    required
                    onChange={formik.handleChange}
                    value={formik.values.city}
                  >
                    {allCity &&
                      allCity.map((item) => {
                        return (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        );
                      })}
                  </select>
                  <label htmlFor="floatingSelect">City</label>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="form-floating mb-4">
                  <input
                    type="text"
                    className="form-control"
                    id="pincode"
                    placeholder="pincode"
                    name="pincode"
                    onChange={formik.handleChange}
                    value={formik.values.pincode}
                  />
                  <label htmlFor="PinCode">Pincode</label>
                </div>
              </div>

              <div className="col-12">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault"
                    required
                    value={isTerm}
                    onChange={(e) => setIsTerm(e.target.checked)}
                  />
                  <label
                    className="form-check-label d-block d-sm-inline"
                    htmlFor="flexCheckDefault"
                  >
                    I have read and agree to the{" "}
                    <a className="text-primary text-decoration-none">
                      Terms and Conditions.
                    </a>
                  </label>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-12 text-center">
                  <button
                    className="btn btn-primary rounded-1"
                    type="submit"
                    disabled={!isTerm}
                  >
                    Register
                  </button>
                </div>
              </div>
            </div>
          </form>
        ) : (
          <>
          <form className="mhc-register-form py-4">
            <div className="row mt-4">
              <div className="col-12">
                <h1 className="fs-5 mb-4 text-center text-lg-start">
                  Verify OTP
                </h1>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-lg-3">
                <div className="form-floating mb-4">
                  <input
                    type="text"
                    className="form-control"
                    maxLength="4"
                    value={currentOTP}
                    id="OTP"
                    name="OTP"
                    placeholder="Enter OTP"
                    onChange={(e) => setCurrentOTP(e.target.value)}
                  />
                  <label>
                    OTP <sup>*</sup>
                  </label>
                </div>
              </div>
              <div className="col-lg-3">
                <button
                  className="btn btn-outline-primary rounded-1"
                  onClick={() => verifyOTP()}
                >
                  Verify OTP
                </button>
              </div>
            </div>

            <div className="row  mt-4">
              <div className="col-md-6 col-lg-6">
                <p className="m-0">
                  An OTP has been sent to your mobile number, please enter to
                  verify.
                </p>
              </div>

            </div>
          </form>
          {showOTP && <div className="col-md-6 col-lg-6 mt-3 mt-md-0">
                <div className="d-flex align-items-center justify-content-start justify-content-md-end">
                  {isResend ? (
                    <div className="anitClock">
                      <img src={antiClock} className="anti-img" alt="" />
                      <p className="otp-time">{timeLeft}</p>
                    </div>
                  ) : (
                    <button
                      onClick={() => handleOtpRequest()}
                      className="text-primary btn-link fs-6 text-decoration-none text-nowrap"
                      disabled={isResend}
                    >
                      {reOtpSend?'Sending OTP...':'Resend OTP'}
                    </button>
                  )}
                </div>
              </div>}
          </>
        )}

        <ToastContainer />
      </div>
    </>
  );
};

export default UserRegister;
