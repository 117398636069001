import React, { useState, useEffect, useLayoutEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import BookPackage from "./package";
import BookTest from "./test";

import Breadcrumb from "../../component/breadcrumb/index";
import ServiceAvailability from "../../component/serviceavailability/index";
import SearchTextWidget from "../../component/search/search-text-widget";
import RequestCallback from "../../component/request-callback/requestCallback";
import { clearVendorReducer } from "../../redux/slice/vendor";
import { useDispatch } from "react-redux";
import BreadcrumbWithUrl from "../../component/breadcrumb/breadcrumb-with-url";

const BookPackageOrTest = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const [pincode, setPincode] = useState("");
  const [serviceable, setServiceable] = useState(false);
  const [callingType, setCallingType] = useState("diagnostics");
  const [loading, setLoading] = useState(false);

  const [keyword, setKeyword] = useState("");

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  useEffect(async () => {
    let cType = location.pathname === "/book-package-or-test/test" ? "tests" : "diagnostics";
    setCallingType(cType);
  }, []);

  const breadCrumbData = [
    { type: 1, url: '/', name: 'Home' },
    { type: 2, url: '', name: 'Book Package & Test' },
    { type: 3, url: '', name: callingType === "tests" ? 'Tests' : 'Packages' }
  ];

  return (
    <>
      <section>
        <div className="container">
          <BreadcrumbWithUrl breadcrumbs={breadCrumbData} />

          <div className="row">
            <div className="col-lg-6 ">
              <h1 className="fs-4 mb-1">Diagnostics</h1>
              <p className="m-0 fs-6">Book Package &amp; Test</p>
            </div>
            <div className="col-lg-6 mt-4 mt-lg-0">
              <SearchTextWidget
                placeHolder={`Search ${callingType}`}
                btnDisplay={true}
                setKeyword={setKeyword} />
            </div>
          </div>
          <div className="mhc-tabs position-relative mt-3 mt-lg-4">
            <div className="row justify-content-between">
              <div className="col-auto">
                <RequestCallback />
              </div>
              <div className="col-auto">
                <ServiceAvailability
                  pincode={pincode}
                  serviceable={serviceable}
                  setPincode={(pincode)=>setPincode(pincode)}
                  setServiceable={setServiceable}
                  setLoading={(val)=>setLoading(val)}
                  loading={loading}
                />
              </div>
            </div>

            <Tabs activeKey={callingType} className="mb-3" onSelect={(k) => {
              setCallingType(k);
              setKeyword("");
              dispatch(clearVendorReducer());
              window.history.replaceState(
                {},
                document.getElementsByTagName('title')[0].innerHTML,
                `/book-package-or-test/${k === 'tests' ? 'test' : 'package'}`
              );
            }}>
              <Tab eventKey="diagnostics" title="Packages">
                <BookPackage
                  pincode={pincode}
                  serviceable={serviceable}
                  type={callingType === "diagnostics" ? 2 : 0}
                  keyword={keyword}
                  loading={loading}
                  setLoading={setLoading}
                />
              </Tab>
              <Tab eventKey="tests" title="Tests">
                <div className="mt-0 mt-lg-3">
                  <BookTest
                    pincode={pincode}
                    serviceable={serviceable}
                    type={callingType === "tests" ? 1 : 0}
                    keyword={keyword}
                  />
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </section>
    </>
  );
}

export default BookPackageOrTest;