import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { getBoardMembers, getDoctorReviews } from "../../actions/get-calls";
const settings = {
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 1000,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
// https://betacms.myhealthcare.co/api/txts/2/5
const AboutusTestimonials = () => {
  const [reviews, setReviews] = useState([]);
  const fetchAboutUsData = async () => {
    const response = await getDoctorReviews();
    console.log("response: ", response);
    setReviews(response.data);
  };
  useEffect(() => {
    fetchAboutUsData();
  }, []);
  return (
    <section className="about-testimonials">
      <div className="container">
        <h1 class="text-left text-lg-center fs30">
          Loved by patients, trusted by doctors
        </h1>
        <div className="row mt-4">
          <div className="col-12">
            <Slider {...settings}>
              {reviews &&
                Array.isArray(reviews) &&
                reviews.map((review) => {
                  return (
                    <div className="testimonials-card">
                      <p className="text-center">
                        <div dangerouslySetInnerHTML={{__html:review.text}} />
                      </p>
                      <p className="text-center">
                        <span className="fw-bolder">{review.heading}</span>
                        <br />
                        {review.speciality}
                      </p>
                    </div>
                  );
                })}

            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutusTestimonials;
