import React, { Component, useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Breadcrumb from "../../component/breadcrumb";
import capcha from "../../assets/images/capcha.png";
import { useParams } from "react-router";
import { getAllBlogs, getJobDataBySlug } from "../../actions/get-calls";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { careerFormData, orderMedicine } from "../../actions/order-medicine";
import BreadcrumbWithUrl from "../../component/breadcrumb/breadcrumb-with-url";
const CareerSub = () => {
  const [data, setData] = useState({});
  const [errors,setErrors] = useState({})
  const { slug } = useParams();
  console.log(data,'>>>>>>>>>>>>>>>>>>>>>>>>>');
  const [jobData, setJobData] = useState({});
  console.log('jobData: ', jobData);
  const fetchJobData = useCallback(async () => {
    if (slug) {
      const response = await getJobDataBySlug(slug);
      if (response && response.data && Array.isArray(response.data)) {
        setJobData(response.data[0]);
      }
    }
  }, [slug]);
  useEffect(() => {
    fetchJobData();
  }, []);
  const changeHandler = (e) => {
    setErrors({
      ...errors,
      [e.target.name]:''
    })
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e)=>{
    e.preventDefault();
    console.log(data,"&&&&&&&&&&&&&&&&");
    // if(){

    // }

  }
  const formik = useFormik({
    initialValues: {
      username: "",
      mobile: "",
      email: "",
      captcha: "",
      cv: "",
    },

    onSubmit: ( helpers) => {
      console.log('values: ', data);
      let values = data
      let errors = {

      }
      if (!values.username) {
        errors.username = 'username is mandatory'
        
      } if (!values.mobile) {
        errors.mobile='Mobile is mandatory'
      } if (!values.email) {
        errors.email='Email is mandatory'
        
      } if (!values.cv) {
        errors.cv = 'CV is mandatory'
        
      } if (!values.captcha) {
        errors.captcha = 'Captcha is mandatory'
      }
      console.log(errors,'>>>>>>>>>>>>>>>>');
      setErrors(errors)
      if(!Object.keys(errors).length){
        const formData = new FormData();

        formData.append('career_id', jobData._id);
        formData.append('name', values.username);
        formData.append('email', values.email);
        formData.append('mobile_number', values.mobile);
        formData.append('updated_cv', values.cv);
        careerFormData(formData)
        .then(function (response) {
          console.log('response: ', response);
          let res = response.data;
          if (res && res.errors) {
            res.errors.map((error) => {
              // return showToast(error.message);
            })
          }
          
        }).catch(function (err) { });
      }
    },
  });
  function showError(str) {
    toast.error(str, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false
    });
  }
  const breadCrumbData = [
    { type: 1, url: "/", name: "Home" },
    { type: 1, url: "/career", name: "Career" },
    { type: 2, name: jobData.title },
  ];
  return (
    <React.Fragment>
      <Container id="career-sub">
      <BreadcrumbWithUrl breadcrumbs={breadCrumbData} />


        <div className="row mt-4">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="row">
              <div className="col-12 mb-2">
                <h3 className="text-dark">{jobData.title}</h3>
              </div>
              <div className="col-6 my-2">
                <h6 className="text-dark mb-1">Qualification:</h6>
                <span>{jobData.qualification}</span>
              </div>
              <div className="col-6 my-2">
                <h6 className="text-dark mb-1">Experience:</h6>
                <span>{jobData.experience} Years</span>
              </div>
              <div className="col-6 my-2">
                <h6 className="text-dark mb-1">No. Of positions:</h6>
                <span>{jobData.no_of_positions}</span>
              </div>
              <div className="col-6 my-2">
                <h6 className="text-dark mb-1">Location:</h6>
                <span>{jobData.location}</span>
              </div>
              {/* <div className='col-6 my-2'>
                                <h6 className='text-dark mb-1'>Job Description:</h6>
                                <span>Senior customer service executive</span>
                            </div> */}
              <div className="col-12 my-2">
                <h6 className="text-dark mb-1">Requirements & Skills:</h6>
                <span>{jobData.requirement}</span>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-12">
            <div className="container-fluid upload_career_cont px-0">
              <div className="row">
                <div className="col-12">
                  <h6 className="text-dark upload_top mb-0">Upload Details</h6>
                </div>
              </div>
              <hr className="my-0" />
              <div className="container-fluid form_container">
                <div className="row">
                  <form onSubmit={formik.handleSubmit}>
                  <div className="col-12">
                    <div className="form-floating mt-2 mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        autoComplete="off"
                        placeholder="Name"
                        name='username'
                        value={data.username}
                        onChange={changeHandler}
                      />
                      <label htmlFor="name">
                        {" "}
                        Name<sup className="text-primary">*</sup>{" "}
                      </label>
                      {errors.username && <p style={{color:'red'}}>{errors.username}</p>}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        autoComplete="off"
                        placeholder="Email Address"
                        onChange={changeHandler}
                        name='email'
                        value={data.email}
                      />
                      <label htmlFor="email">
                        {" "}
                        Email Address<sup className="text-primary">*</sup>{" "}
                      </label>
                      {errors.email && <p style={{color:'red'}}>{errors.email}</p>}

                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 ">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="pos_apply"
                        autoComplete="off"
                        placeholder="Position Applying For"
                        name='mobile'
                        value={data.mobile}
                        onChange={changeHandler}
                      />
                      <label htmlFor="pos_apply">
                        {" "}
                        Mobile Number<sup className="text-primary">*</sup>{" "}
                      </label>
                      {errors.mobile && <p style={{color:'red'}}>{errors.mobile}</p>}

                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="input-group enter-otp">
                      <input
                        type="file"
                        className="form-control input-group-text rounded-end text-primary px-3 upload_button"
                        placeholder="Upload your CV"
                        aria-label="Upload your CV"
                        aria-describedby="basic-addon1"
                        name='cv'
                        value={data.cv}
                        onChange={changeHandler}
                      />
                      {errors.cv && <p style={{color:'red'}}>{errors.cv}</p>}

                      {/* <button
                        type="submit"
                        className="input-group-text rounded-end text-primary px-3 upload_button"
                        id="basic-addon1"
                      >
                        Upload
                      </button> */}
                    </div>
                  </div>

                  <div className="col-xs-12 col-sm-8 col-lg-8 col-md-12 mt-2 mt-lg-0">
                    <div className="text-dark mb-1">
                      Please enter the characters you see in the box
                    </div>
                    <div className="captcha-text-career">
                      <img
                        className="form-control captcha_width-career"
                        width="84"
                        height="28"
                        alt="captcha"
                        src={capcha}
                      />
                    </div>
                    <span className="captcha-field">
                      <span className=" captch">
                        <input
                          type="text"
                          placeholder="Enter Captcha"
                          size="40"
                          className="form-control"
                          id="captcha"
                          name='captcha'
                        value={data.captcha}
                          onChange={changeHandler}
                        />
                      {errors.captcha && <p style={{color:'red'}}>{errors.captcha}</p>}

                      </span>
                    </span>
                  </div>
                  <div className="col-xs-12 col-sm-4 col-lg-4  col-md-12 text-center d-flex align-self-center">
                    <button
                    type="submit"
                      className="btn btn-primary rounded-1 mt-2 mt-mb-0 mb-2 mb-sm-0 "
                    >
                      Let's Connect
                    </button>
                  </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-12">
            {/* <h6 className='text-dark mb-0 roles_font'>Roles and Responsilbilities</h6> */}
            <h6 className="text-dark mb-0 roles_font">Job Description</h6>
          </div>
        </div>

        <div className="row mt-2 mb-2">
          <div className="col-12">
            {jobData ? (
              <div
                dangerouslySetInnerHTML={{ __html: jobData.job_description }}
              ></div>
            ) : (
              ""
            )}
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};
export default CareerSub;