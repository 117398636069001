import React, { useState, useEffect } from "react";
import SearchIcon from "../../assets/images/mhc-search-icon.svg";

const SearchTextWidget = ({
  placeHolder = "",
  setKeyword = "",
  btnDisplay = true || false,
  radiobutton,
  searchClickHandler
  
}) => {
  const [closebtn, setClosebtn] = useState(false);
  const [searchText, setSearchText] = useState("");
  useEffect(()=>{
    setSearchText('')
  },[radiobutton])
  function handleCloseDropdown() {
    setClosebtn(false);
    setSearchText("");
    setKeyword("");
  }

  function handleChange(e) {
    setSearchText(e.target.value);
    setKeyword(e.target.value);
  }

  return (
    <React.Fragment>
      <div className="Mhc-search w-100">
        <div className="input-group">
          <span className="input-group-text">
            <img src={SearchIcon} alt="" />
          </span>
          <input
            type="text"
            value={searchText}
            onChange={(e) => handleChange(e)}
            className="form-control"
            placeholder={placeHolder}
            onKeyUp={(e)=>{
              if (e.which === 13||e.target.value===''){
                searchClickHandler()
              }
            }}
          />
        </div>
        <button
          className={`btn btn-primary ${
            btnDisplay === true ? "d-block" : "d-none"
          }`}
          onClick={searchClickHandler}
        >
          Search
        </button>
        <button
          className={`search-close mrr ${
            closebtn === false ? "d-none" : "d-block"
          }`}
          onClick={() => handleCloseDropdown()}
          type='submit'
        >
          <i className="mhc-icon icon-cancel text-primary"></i>
        </button>
      </div>
    </React.Fragment>
  );
}

export default SearchTextWidget;
