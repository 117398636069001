import { tertiaryToken } from "../constants/app-constants";
import axiosClient from "./api-handlers";

//search pincode after 6 numbers
export async function pincodeService(pCode) {
  const response = await axiosClient.get(
    `/patients/ios/v2/website/get-onemgpincode?pincode=${pCode}
`
  );
  return response;
}

export async function orderMedicine(payload) {
  const response = await axiosClient.post(
    `/patients/android/v2/patients/create-medicine-order`,
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        authorization: `${tertiaryToken}`,
      },
    }
  );
  return response;
}
export async function careerFormData(payload) {
  
  const response = await axiosClient.post(
    'https://betacms.myhealthcare.co/api/store_careerformdata',
    payload,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        authorization: `${tertiaryToken}`,
      },
    }
  );
  return response;
}
