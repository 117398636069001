import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Shyatto from "../../assets/images/shyatto.webp";
import AneeshNair from "../../assets/images/Aneesh-Nair.webp";
import DivyaLaroyia from "../../assets/images/Divya-Laroyia.webp";
import KaranPalSingh from "../../assets/images/Karan-Pal-Singh.webp";
import nikhilVora from "../../assets/images/nikhil-vora.jpg";
import Hemchandra from "../../assets/images/Hemchandra-1.jpeg";
import { getBoardMembers } from "../../actions/get-calls";
const settings = {
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 1000,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const OurBoard = () => {
  const [boards, setBoards] = useState([]);
  const fetchAboutUsData = async () => {
    const response = await getBoardMembers();
    console.log("response: ", response);
    setBoards(response.data);
  };
  useEffect(() => {
    fetchAboutUsData();
  }, []);
  return (
    <section className="about-events">
      <div className="container">
        <h1 class="text-left text-lg-center fs30">Our Board</h1>
        <div className="row mt-4 justify-content-center">
          <div className="col-xxl-12 col-xl-12 ">
            <Slider {...settings}>
              {boards.map((board)=>(
              <div>
              <figure class="figure">
                <img src={board.image} />
                <figcaption class="figure-caption">
                  <strong>{board.title}</strong>
                  <br />
                  <small>
                  <div dangerouslySetInnerHTML={{ __html: board.text }}/>
                  </small>{" "}
                </figcaption>
              </figure>
            </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurBoard;
