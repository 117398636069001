import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { getAboutUs } from "../../actions/get-calls";
const settings = {
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 1000,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const INTheNews = () => {
  const [news, setNews] = useState([]);
  const fetchAboutUsData = async () => {
    const response = await getAboutUs();
    console.log("response: ", response);
    setNews(response.data);
  };
  useEffect(() => {
    fetchAboutUsData();
  }, []);
  console.log('news:................ ', news);
  return (
    <section className="about-news" id='inTheNews'>
      <div className="container">
        <h1 class="text-left text-lg-center fs30">In the News</h1>
        <div className="row mt-4 ">
          <div className="col-12">
            <Slider {...settings}>
              {news.map((newss) => {
                return (
                  <div className="news-card">
                    <h4>{newss.title}</h4>
                    <p className="text-primary mb-4 fw-bolder">
                      {" "}
                      ET HEALTHWORLD.COM
                    </p>
                    <a
                      href="#"
                      class="btn btn-outline-primary news-btn rounded-1  px-3 py-1"
                    >
                      Read More{" "}
                    </a>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default INTheNews;
