import React, { useState, useEffect } from "react";
import DownloadIcon from "../../assets/images/eye.svg";
import PdfIcon from "../../assets/dust/mhc-pdf.svg";
import moment from "moment";
//import { myPrescriptionHomecareCall } from "../../actions/dashboard-api";
import { saveAs } from "file-saver";

const PatientMyPrescriptionsHomeCare = ({ homecareList }) => {
    // console.log("Homecare reports", homecareList)

    const saveHomecarePdf = (e, homecare_url) => {
        saveAs(homecare_url, "homecare_summary.pdf");
    };

    const savePresPdf = (e, url) => {
        saveAs(url, "myprescription.pdf")
    };


    return (
        <React.Fragment>
            {/* <div className="predcription-dector-card">
                <div className="card-body">
                    <div className="row mb-4">
                        <div className="col-lg-6">
                            <p className="mb-0 fs-5">ReLiva Silver Package <i class="mhc-icon icon-mhc-info cursor-pointer text-primary"></i></p>
                        </div>
                        <div className="col-lg-6 text-start text-lg-end mt-2 mt-lg-0">
                            <p className="mb-0 text-primary">Day 15/15</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-11" >
                            <div className="d-flex align-items-center">
                                <div><img src={PdfIcon} alt="PdfIcon" className="pdf-icon" /></div>
                                <div className="ms-3">
                                    <h4 className="mhc-tittle m-0">Case Summary</h4>
                                    <p className="m-0 text-primary">Fri, 13 Feb 2021 | 2:00 PM </p>
                                </div>

                            </div>

                        </div>
                        <div className="col-1 text-end ">
                            <img src={DownloadIcon} alt="DownloadIcon" className="download-icon" />
                        </div>
                    </div>
                    <div class="border-bottom border-light w-100 transition my-3 my-lg-4"></div>
                    <div className="row">
                        <div className="col-11" >
                            <div className="d-flex align-items-center">
                                <div><img src={PdfIcon} alt="PdfIcon" className="pdf-icon" /></div>
                                <div className="ms-3">
                                    <h4 className="mhc-tittle m-0">Prescription</h4>
                                    <p className="m-0 text-primary">Fri, 13 Feb 2021 | 2:00 PM </p>
                                </div>

                            </div>

                        </div>
                        <div className="col-1 text-end ">

                        <img src={DownloadIcon} alt="DownloadIcon" className="download-icon" />
                        </div>
                    </div>
                </div>
            </div> */}

            {homecareList.map((item, i) => {
                let case_date = moment(item.date).format("DD MMM, YYYY");
                let case_time = moment(item.date).format("LT");

                let pres_date = moment(item.prescription_date).format("DD MMM, YYYY");
                let pres_time = moment(item.prescription_date).format("LT");

                let homecare_url = item.pdf_document;
                let url = item.upload_prescription;

                return (
                    <>
                        <div className="predcription-dector-card">
                            <div className="card-body">
                                <div className="row mb-4">
                                    <div className="col-lg-6">
                                        <p className="mb-0 fs-5">
                                            {item.packageName} &nbsp;
                                            <i class="mhc-icon icon-mhc-info cursor-pointer text-primary"></i>
                                        </p>
                                    </div>
                                    <div className="col-lg-6 text-start text-lg-end mt-2 mt-lg-0">
                                        <p className="mb-0 text-primary">Day {item.days}/{item.duration}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-11">
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <img src={PdfIcon} alt="PdfIcon" className="pdf-icon" />
                                            </div>
                                            <div className="ms-3">
                                                <h4 className="mhc-tittle m-0">Case Summary</h4>
                                                <p className="m-0 custom-text">
                                                    {case_date} | {case_time}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-1 text-end ">
                                        <a
                                            onClick={(e) => saveHomecarePdf(e, homecare_url)}
                                            download={item.e_prescription}>
                                            <img
                                                src={DownloadIcon}
                                                alt={DownloadIcon}
                                                className="download-icon"
                                            />
                                        </a>
                                    </div>
                                </div>
                                {/* <div class="border-bottom border-light w-100 transition my-3 my-lg-4"></div>
                                <div className="row">
                                    <div className="col-11">
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <img src={PdfIcon} alt="PdfIcon" className="pdf-icon" />
                                            </div>
                                            <div className="ms-3">
                                                <h4 className="mhc-tittle m-0">Prescription</h4>
                                                <p className="m-0 custom-text">
                                                    {pres_date} | {pres_time}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-1 text-end ">
                                        <a
                                            onClick={(e) => savePresPdf(e, url)}
                                            download={item.upload_prescription}>
                                            <img
                                                src={DownloadIcon}
                                                alt={DownloadIcon}
                                                className="download-icon"
                                            />
                                        </a>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </>
                );
            })}




        </React.Fragment>
    );
};
export default PatientMyPrescriptionsHomeCare;