import React, { useEffect, useState } from "react";
import homeWalletSliderPic from "../../assets/images/home_wallet_slider_pic.webp";
import googlePlay from "../../assets/images/google-play.png";
import appstore from "../../assets/images/app-store.png";
import { Link } from "react-router-dom";
import { getDownloadURLs } from "../../actions/get-calls";
const DownloadApp = ()=>{
    const [data,setData] = useState([]);
    const fetchData = async ()=>{
        const response = await getDownloadURLs();
        console.log('response:download app ', response);
        if(response && response.data && Array.isArray(response.data) && response.data.length){
            setData(response.data);
        }
    }
    useEffect(()=>{
        fetchData()
    },[])
    return (
        <React.Fragment>
          <section className={`mhc-download-mobile Home-footer-margin `}>
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-lg-6 col-xl-5">
                  <p className="mt-5">Download MyHealthcare </p>
                  <h3>{data.length>0 && data[0].title}</h3>
                  {data.length && data[0] && data[0].text && <div dangerouslySetInnerHTML={{ __html: data[0].text.substring(0,100) }}></div>}

                  <div className="d-flex mt-4">
                    {data.map((download)=>{
                     return <div>
                      <a
                        href={download.play_store_url?download.play_store_url:download.app_store_url}
                        target="_blank"
                      >
                        <img src={download.play_store_url?googlePlay:appstore} className="icon-downlod" style={{marginRight:'10px'}}/>
                      </a>{" "}
                    </div>
                    })}
                  </div>
                </div>
                <div className="col-lg-6 col-xl-5 d-none d-lg-block">
                  <img src={homeWalletSliderPic} className="icon " />
                </div>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
}
export default DownloadApp;
