import React, { useState, useEffect, useLayoutEffect } from "react";

import DownloadApp from "../../component/download-app/downloadApp";
import INTheNews from "./inTheNews";
import AboutusTestimonials from "./testimonials";
import OurTeam from "./ourTeam";
import OurInvestors from "./OurInvestors";
import OurBoard from "./OurBoard";

import { getTextFromCMS } from "../../actions/get-calls";
import BreadcrumbWithUrl from "../../component/breadcrumb/breadcrumb-with-url";
import { Container } from "react-bootstrap";
import Banner from "./Banner";

const About = () => {
  const [content, setContent] = useState(null);

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  useEffect(async () => {
    // Content
    const _setContent = await getTextFromCMS(2, 3);
    if (_setContent) setContent(_setContent.data[0]);
  }, []);
  const breadCrumbData = [
    { type: 1, url: "/", name: "Home" },
    { type: 2, name: 'About Us' },
  ];
  return (
    <React.Fragment>
      <Container>

      <BreadcrumbWithUrl breadcrumbs={breadCrumbData} />
      <Banner />


      <OurBoard />
      <OurInvestors />
      <INTheNews />
      <AboutusTestimonials />
      <OurTeam />
      <DownloadApp />
      </Container>
    </React.Fragment>
  )
}

export default About;