import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Tabs, Tab } from "react-bootstrap";
import Breadcrumb from "../../component/breadcrumb";
import dashboard from "../../assets/images/dashboard.svg";
import { myReportsCall, myReportsDateFilter } from "../../actions/dashboard-api";
import { saveAs } from 'file-saver'

import { Link } from "react-router-dom";
import SwitchProfile from "../../component/login-register/switchProfile";
import PatientMyReportData from "../patientMyReport/PatientMyReportData";
import InfiniteScroll from 'react-infinite-scroll-component';
import LodingComponent from "../../component/loding";
import NoMoreData from "../../component/no-more-data/noMoreData";
import moment from 'moment';
import DatePicker from "react-datepicker";
import MyReportComingSoon from "../../component/myReportComingSoon/myReportComingSoon";

const breadcrumArrry=[
    {
      name:'My Dashboard',
      url:'/patient-dashboard'
    }, 
    {
        name:'My Reports',
        url:''
      }, 
  ]
const PatientMyReport = () => {
    const switchToken = useSelector((state) => state.switchToken.value);
    const [myReports, setMyReports] = useState();
    const [pagination, setPagination] = useState();
    const [hasMore, setHasMore] = useState(true);
    let [myReportsPage, setMyReportsPage] = useState(1)
    const [myReportsLoader, setMyReportsLoader] = useState(false);

    const [reportsPathology, setReportsPathology] = useState([]);
    const [reportsRadiology, setReportsRadiology] = useState([]);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    let PlacholderDate =moment().toDate()
    let formatePlaceHolderDate = moment(PlacholderDate).format("DD/MM/YYYY");

    
    useEffect(() => {

        myReportsCall(myReportsPage, switchToken).then(function (response) {
            setPagination(response.data.pagination);
            setMyReports(response.data.reports); 

            let newreps= response.data.reports
            //console.log("report data",newreps)
            let reports = newreps?.filter(testfilt =>{
                if (testfilt.report_type === 0){
                    setReportsPathology((response.data.reports))
                }
                else if(testfilt.report_type === 1){
                    setReportsRadiology((response.data.reports))
                    
                    }
                    
            })

        })
            .catch(function (err) {
                console.log(err);
            });
            


    }, []);


    const fetchMoreData = () => {
        setMyReportsLoader(true); 
        let nextPage;
        if (myReportsPage < pagination.total_pages) {
            nextPage = myReportsPage + 1;
            setMyReportsPage(nextPage)
            //console.log(">>next page", nextPage)

            myReportsCall(nextPage, switchToken).then(function (response) {
                setMyReports(myReports.concat(response.data.reports));
                setMyReportsLoader(false); 
            })
                .catch(function (err) {
                    console.log(err);
                });

        } else {
            setHasMore(false);
        }
    }
    const handleDateApi=(date)=>{
        setStartDate(date)        
        setEndDate(date)
    }
    const handleDateApi1=(date)=>{ 
        setEndDate(date);
    }

    const getFilterData=()=>{
        let getStartDate=moment(startDate).format("YYYY-MM-DD")
        let getEndDate=moment(endDate).format("YYYY-MM-DD")
        setMyReports()
        myReportsDateFilter(getStartDate, getEndDate, switchToken).then(function (response) {   
            // console.log("response", response)  
            setMyReports(response.data.reports);
        })
        // console.log(myReports)
    }
    
    const refreshData=()=>{
        setStartDate();
        setEndDate()
    }

    return (
        <React.Fragment>
            <Container className="min-body-height">
                <div className="row  align-items-center breadcrumb_sticky">
                    <div className="col-md-9">
                    <Breadcrumb textUrl={breadcrumArrry} />   
                    </div>
                    <div className="col-md-3 d-none d-md-block">
                        <Link to="/patient-dashboard" className="mhc-patient-back">
                            <div className="d-flex align-items-center justify-content-start justify-content-md-end">
                                <div>
                                    <img src={dashboard} alt="" />
                                </div>
                                <div className="fw-bolder ms-2">My Dashboard</div>
                            </div>
                        </Link>
                    </div>
                </div>
                <SwitchProfile />
                <div className="row mt-4">
                    <div className="col-12">
                        <h1 className="fs-4 mb-1 text-secondary">My Reports</h1>
                        <p className="m-0 fs-6">Search for Lab and Radiology reports</p>
                    </div>
                </div>
                <div className="mhc-tabs position-relative mt-4">
                    <Tabs
                        defaultActiveKey="All"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                       >
                        <Tab eventKey="All" title="All">
                        <div className="row mt-3">
                <div className="col-lg-3 col-md-7 col-sm-9">
                    <div className="d-flex flex-column flex-sm-row">
                        <div className="form-floating mhc-dob date mb-4">
                        <DatePicker    calendarClassName="mhc-input-date"   dateFormat="dd/MM/yyyy" selected={startDate}  onChange={(date) => handleDateApi(date)}
                                    // minDate={moment().toDate()}
                                
                                        maxDate={moment().toDate('dd/MM/yyyy')}
                                calendarStartDay={1}
                                formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
                                dropdownMode="select"
                                showMonthDropdown
                                showYearDropdown
                                adjustDateOnChange
                                />
                            <i className="mhc-icon icon-calendar"></i>
                            <label htmlFor="Dob"  className={`${startDate!=null?'dob':''}`}>Start Date</label>
                        </div>
                        <div className="form-floating mhc-dob date mb-4 ms-0 ms-sm-3">
                        <DatePicker    calendarClassName="mhc-input-date"   dateFormat="dd/MM/yyyy" selected={endDate}  onChange={(date) => handleDateApi1(date)}
                                    minDate={startDate}
                                    // maxDate={moment().toDate()}
                                    calendarStartDay={1}  
                                    formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)} 
                                    dropdownMode="select"
                                    showMonthDropdown
                                    showYearDropdown
                                    adjustDateOnChange
                                />
                            <i className="mhc-icon icon-calendar"></i>
                            <label htmlFor="Dob" className={`${endDate!=null?'dob':''}`}>End Date</label>
                        </div>
                    </div>

                </div>
                <div className="col-lg-3 col-md-5 col-sm-3">
                    <div className="d-flex flex-row">
                      <button type="submit" className="btn btn-outline-primary" onClick={()=>getFilterData()}><i className="mhc-icon icon-next"></i></button>
                      <button type="submit" className="btn btn-outline-primary ms-2" onClick={()=>refreshData()}><i className="mhc-icon icon-refresh"></i></button>
                        </div>
                        </div>
                </div>
    
                            {   
                                myReports?.length == 0 || myReports == undefined  ? <MyReportComingSoon />  :
                                myReports === undefined ? <LodingComponent className="mhc-Loding-minHeight" /> : <InfiniteScroll
                                    dataLength={myReports.length}
                                    next={fetchMoreData}
                                    hasMore={hasMore}
                                    loader={myReportsLoader==true?<LodingComponent className="text-center" />:''}
                                    endMessage={pagination.total_pages>1 ? <NoMoreData className="text-center mb-3" />:''}
                                >
                                    {
                                        <PatientMyReportData reportList={myReports} />
                                    }

                                </InfiniteScroll>
                                
                            }

                        </Tab>
                        <Tab eventKey="Pathology" title="Laboratory">
                        {   reportsPathology?.length == 0 ? <MyReportComingSoon />  : 
                                reportsPathology === undefined ? <LodingComponent className="mhc-Loding-minHeight" /> : <InfiniteScroll
                                    dataLength={reportsPathology.length}
                                    next={fetchMoreData}
                                    hasMore={hasMore}
                                    loader={myReportsLoader==true?<LodingComponent className="text-center" />:''}
                                    endMessage={pagination.total_pages>1 ? <NoMoreData className="text-center mb-3" />:''}
                                >
                                    {
                                        <PatientMyReportData reportList={reportsPathology} />
                                    }

                                </InfiniteScroll>
                            }
                        </Tab>
                        <Tab eventKey="Radiology" title="Radiology">
                            {/* message
                        <h6 className="text-center mt-2 mb-4">No Data Found</h6> */}
                        {   reportsRadiology?.length == 0 ? <MyReportComingSoon />  : 
                                reportsRadiology === undefined ? <LodingComponent className="mhc-Loding-minHeight" /> : <InfiniteScroll
                                    dataLength={reportsRadiology.length}
                                    next={fetchMoreData}
                                    hasMore={hasMore}
                                    loader={myReportsLoader==true?<LodingComponent className="text-center" />:''}
                                    endMessage={pagination.total_pages>1 ? <NoMoreData className="text-center mb-3" />:''}
                                >
                                    {
                                        <PatientMyReportData reportList={reportsRadiology} />
                                    }
                                </InfiniteScroll>
                            }
                        </Tab>
                    </Tabs>
                </div>
            </Container>
        </React.Fragment>
    );
};

export default PatientMyReport;