import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { thyrocareList } from "../../../actions/get-calls";
import { clearPackageDetailReducer, setPackageDetailReducer } from "../../../redux/slice/package-detail";

const BookTest = ({
    keyword = "",
    pincode,
    serviceable,
    type,
}) => {

    const dispatch = useDispatch();
    const [thyroTests, setThyroTests] = useState(null);
    const [packageDetail, setPackageDetail] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(async () => {
        if (type === 1) {
            if (serviceable && pincode.toString().length === 6) {
                setLoading(true);
                const _thyroTests = await thyrocareList(type, '', pincode, 1, keyword);
                if (_thyroTests && _thyroTests.data) {
                    setThyroTests(_thyroTests.data.TESTS);
                } else {
                    setThyroTests(null);
                }
                setLoading(false);
            }
        }
    }, [type, pincode, serviceable, keyword]);

    function setCompare(e, item) {
        let tempArr = [];

        if (packageDetail) {
            tempArr = [...packageDetail];
        }

        if (e.target.checked) {
            tempArr.push(item);
        } else {
            for (const key in tempArr) {
                if (tempArr[key].id === item.id) {
                    tempArr.splice(key, 1);
                }
            }
        }

        setPackageDetail(tempArr);
    }

    useEffect(() => {
        dispatch(clearPackageDetailReducer());
        dispatch(setPackageDetailReducer(packageDetail));
    }, [packageDetail])

    function setCheckedMember(id) {
        if (packageDetail && packageDetail.filter(e => e.id === id).length > 0) {
            return true;
        }
        return false;
    }

    return (
        <React.Fragment>
            {
                !loading ? (
                    <div className="row mt-2 mb-4">
                        {
                            thyroTests ? thyroTests.map((item) => (
                                <div className="col-md-6 col-lg-4 mb-4" key={item.id}>
                                    <div className="form-check mhc-test-bordered-checkbox h-100">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={window.btoa(item.name.toLowerCase()).replaceAll("=", "")}
                                            value={item.id}
                                            checked={setCheckedMember(item.id)}
                                            onChange={(e) => setCompare(e, item)}
                                        />
                                        <label className="form-check-label d-block d-sm-inline " htmlFor={window.btoa(item.name.toLowerCase()).replaceAll("=", "")}>
                                            {item.name}
                                        </label>
                                    </div>
                                </div>
                            )) : (
                                <div className="col-12">
                                    <div className="alert alert-danger text-center">No tests available!</div>
                                </div>
                            )
                        }
                    </div>
                ) : (
                    <div className="mhc-Loding text-center my-5">
                        <img src="../loader.gif" alt="loader" />
                    </div>
                )
            }

            {
                packageDetail ?
                    <div className="row">
                        <div className="col-12 text-center mb-4">
                            <Link to="/test-booking" className="btn btn-primary rounded-1">Go to Cart ({packageDetail.length})</Link>
                        </div>
                    </div>
                    : ''
            }
        </React.Fragment>
    )
}

export default BookTest;