import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { requestCallback } from "../../actions/post-call";
const Iprops = {
  position: true || false,
};
const RequestCallback = ({ position }) => {
  const [modal, setModal] = useState(false);
  const [fullName, setFullName] = useState("");
  const [mobile, setMobile] = useState("");
  const [query, setQuery] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, showMessage] = useState(false);
  const handleShow = () => {
    setModal(true);
  };
  const handleClose = () => {
    setModal(false);
  };
  const handleSubmit = async () => {
    if (!fullName || !mobile || !query) {
      setErrors({
        ...errors,
        fullName: !fullName ? "Please enter full name" : "",
        mobile: !mobile
          ? "Please enter mobile number"
          : (!/^[0-9]{0,9}$/.test(mobile) || mobile.length < 9 || mobile.length>15)
          ? "Please enter valid mobile number"
          : "",
        query: !query ? "Please enter query" : "",
      });
    } else {
      setErrors({});
      let payload = {
        type: "2",
        name: fullName,
        message: query,
        phone: mobile,
      };
      setLoading(true);
      await requestCallback(payload)
        .then(() => {
          setLoading(false);
          showMessage(true);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  const resetModalData = () => {
    showMessage(false);
    setFullName("");
    setMobile("");
    setQuery("");
    setErrors({});
  };
  return (
    <React.Fragment>
      <p
        className={`fs-6 text-secondary ${
          position === true ? "callback" : ""
        } `}
      >
        Couldn’t find what you are looking for?{" "}
        <span onClick={handleShow} className="text-primary cursor-pointer">
          {" "}
          Request a Callback
        </span>
      </p>
      <Modal
        show={modal}
        onHide={handleClose}
        size="md"
        onShow={resetModalData}
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-6">Request a Callback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row ">
            {!message ? (
              <>
                <div className="col-lg-6">
                  <div className="form-floating mb-4">
                    <input
                      type="text"
                      className="form-control"
                      id="fullName"
                      placeholder=""
                      value={fullName}
                      onChange={(e) => {
                        let fullName = e.target.value
                        setErrors({ ...errors, fullName: !fullName ? "Please enter full name" : "" });
                        setFullName(e.target.value);
                      }}
                    />
                    <label htmlFor="name">
                      Full Name <sup>*</sup>
                    </label>
                    {errors.fullName && (
                      <div style={{ color: "red" }}>{errors.fullName}</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-floating mb-4">
                    <input
                      type="text"
                      className="form-control"
                      id="Mobile"
                      placeholder="Mobile"
                      value={mobile}
                      onChange={(e) => {
                        let mobile = e.target.value
                        setErrors({ ...errors, mobile: !mobile
                          ? "Please enter mobile number"
                          : (!/^[0-9]{0,9}$/.test(mobile) || mobile.length < 9 || mobile.length>15)
                          ? "Please enter valid mobile number"
                          : "", });

                        setMobile(e.target.value);
                      }}
                    />
                    <label htmlFor="name">
                      Mobile <sup>*</sup>
                    </label>
                    {errors.mobile && (
                      <div style={{ color: "red" }}>{errors.mobile}</div>
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-floating mb-4">
                    <textarea
                      className="form-control"
                      placeholder="Leave a comment here"
                      id=""
                      value={query}
                      onChange={(e) => {
                        let query= e.target.value
                        setErrors({ ...errors, query: !query ? "Please enter query" : "", });

                        setQuery(e.target.value);
                      }}
                    />
                    <label htmlFor="floatingTextarea2">Query</label>
                    {errors.query && (
                      <div style={{ color: "red" }}>{errors.query}</div>
                    )}
                  </div>
                </div>
                <div className="col-12 text-center">
                  <button
                    className="btn btn-primary rounded-1 "
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {loading ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </>
            ) : (
              <div className="col-12 text-center">
                {message && (
                  <>
                    <p className="text-primary  mt-4">
                      {" "}
                      Thank you for your query. Our Patient Services team will
                      get back to you shortly.
                    </p>
                    <button
                      className="btn btn-primary rounded-1 "
                      onClick={handleClose}
                    >
                      OK
                    </button>
                  </>
                )}
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
export default RequestCallback;
