import React, { useCallback, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { clinicSearchAPI, fetchClinicList } from "../../actions/get-calls.js";
import { searchTextAPI } from "../../actions/post-call.js";
import { encodeString } from "../../constants/app-constants.js";
import Doctors from "../consult-doctor/doctors.jsx";
const ClinicsTab = ({ clinicList, keyword, callingType }) => {
  const [specialities, setSpecialities] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [loader, setLoader] = useState(false);
  const getClinicsList = useCallback(async () => {
    setLoader(true);
    const response = await fetchClinicList();

    setLoader(false);
    setSpecialities(response.data.specialities);
  }, []);
  useEffect(() => {
    getClinicsList();
  }, [getClinicsList]);
  useEffect(() => {
    if (keyword.length === 0 && callingType == "clinics") {
      getClinicsList();
    } else if (keyword.length > 2 && callingType == "clinics") {
      searchClinics();
    }
  }, [keyword]);
  function searchClinics() {
    if (keyword === "" || keyword.length < 3) return false;
    try {
      const delayDebounceFn = setTimeout(async () => {

      
        setLoader(true);
        const searchedData = await clinicSearchAPI(keyword, "clinics");
        console.log('searchedData: ', searchedData);
        setLoader(false);
        setSpecialities(searchedData.data.specialities||[]);
        setDoctors(searchedData.data.doctors||[]);
        console.log('searchedData.data.doctors: ', searchedData.data.doctors);
  
      }, 500);
  
      return () => clearTimeout(delayDebounceFn);
    } catch (error) {
      setLoader(false)
    }
    
  }
  return (
    <>
      <div className="mb-4 hospital-tab">
        <div className="row mb-4 hospital-tab">
          {loader ? (
            <div className="mhc-Loding text-center my-5">
              <img src="../loader.gif" alt="loader" />
            </div>
          ) : (
            <>
            {specialities.map((clinic) => {
              return (
                <div
                  className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4"
                  key={clinic.id}
                >
                  <Link
                    className="text-decoration-none"
                    to={`/book-consult/clinics/${clinic.slug}/15/${
                      clinic.id
                    }`}
                  >
                    <Card className="hospital-card">
                      <div className="row pt-3">
                        <div className="col-12 d-flex justify-content-center align-items-center">
                          <Card.Img
                            variant="top"
                            className="hospital-img"
                            src={clinic.icon}
                            alt={clinic.name}
                          />
                        </div>
                      </div>
                      <Card.Body>
                        <div className="row">
                          <div className="col-12 d-flex justify-content-center">
                            <Card.Text>
                              <div className="d-flex justify-content-center text-center">
                                <span className="text-dark">
                                  {clinic.name}
                                  {/* <br />{" "}
                                  <span className="text-secondary">
                                    Gurugram
                                  </span> */}
                                </span>
                              </div>
                            </Card.Text>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>
              );
            })}
            <Doctors doctors={doctors}  consultType={'0'} callingType={'doctors'}/>
            </>
          )}

        </div>
      </div>
    </>
  );
};

export default ClinicsTab;
