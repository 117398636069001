import { render } from "@testing-library/react";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Container, Accordion } from "react-bootstrap";
import Breadcrumb from "../../component/breadcrumb";

import { Card, Button, Modal } from "react-bootstrap";
import patient from "../../assets/dust/Scroll Group 28.webp";
import patientblog from "../../assets/dust/rounded-rectangle.webp";
import { Link } from "react-router-dom";
import DownloadApp from "../../component/download-app/downloadApp";
import { getAllBlogs } from "../../actions/get-calls";
import { decodeString, encodeString } from "../../constants/app-constants";
import BreadcrumbWithUrl from "../../component/breadcrumb/breadcrumb-with-url";

const Blog = () => {
  const [blogs,setBlogs] = useState([])
  const fetchBlogs = async () => {
    const response = await getAllBlogs();
    if(response && response.data && Array.isArray(response.data)){
      setBlogs(response.data)
    }
    console.log('response: ', response);
  };
  useEffect(() => {
    fetchBlogs();
  }, []);
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  const breadCrumbData = [
    { type: 1, url: "/", name: "Home" },
    { type: 2, name: 'Health Blogs' },
  ];
  return (
    <React.Fragment>
      <Container>
      <BreadcrumbWithUrl breadcrumbs={breadCrumbData} />

        <h5 className="">Health Blogs</h5>
        <p>Stay updated with MyHealthcare</p>
        <div className="row ">
          <div className="col-lg-12">
            <div className="row">
              {blogs.map((item) => (
                <div className="col-3 mb-4">
                  <Card className="mhc-blog-card">
                    <Card.Img variant="top" src={item.blog_img} />

                    <Card.Body>
                      <Card.Title> {item.meta_title}</Card.Title>
                      <Card.Text className="text-secondary">
                        <div dangerouslySetInnerHTML={{ __html: item.blog.substring(0,100) }}></div>
                      </Card.Text>

                      <Link
                        to={`/health-blogs/${item.slug}/${encodeString(item.meta_title||'')}`}
                        className="btn btn-outline-primary rounded-1  "
                      >
                        Read More
                      </Link>
                    </Card.Body>
                  </Card>
                </div>
              ))}
            </div>
          </div>

          {/* <div className="col-lg-4">
            <h4 className="fs-4 text-secondary">By Specialities</h4>
            <ul className="list-unstyled d-flex flex-wrap mt-4">
              {blogtag.map((item) => (
                <li>
                  <Link
                    className="btn btn-outline-secondary rounded-1  mb-3 me-3"
                    to=""
                  >
                    {item.tag}
                  </Link>
                </li>
              ))}
            </ul>

            <Card className="mhc-recent-post">
              <Card.Header>
                <h4 className="fs-4 text-secondary">Recent Posts</h4>
                <div className="d-flex mt-2">
                  <Link className="btn btn-primary rounded-1  px-5" to="">
                    Latest
                  </Link>
                  <Link
                    className="btn btn-outline-primary rounded-1  px-5 ms-3"
                    to=""
                  >
                    Popular
                  </Link>
                </div>
              </Card.Header>
              <Card.Body>
                {blogpost.map((item) => (
                  <div className="blog-post">
                    <div>
                      <img src={patientblog} alt="" />
                    </div>
                    <div className="ms-2">
                      <Link to="">{item.tittle}</Link>
                    </div>
                  </div>
                ))}
              </Card.Body>
            </Card>
          </div> */}
        </div>
      </Container>

      <DownloadApp />
    </React.Fragment>
  );
};
export default Blog;
