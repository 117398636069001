import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tabs, Tab, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";

import antiClock from "../../assets/images/anticlock.png";
import { getProfile, getProfileWithPayload } from "../../actions/get-calls";
import { switchPatient, bookAppointmentHospital, getOTPWithPayloadTokenBased, addFamilyMember, verifyMobileOTP, verifyMobileOTPTokenBased, updateFamilyMember } from "../../actions/post-call";
import countries from "../../assets/data/countries.json";

import { setSwitchTokenReducer } from "../../redux/slice/switch-token";
import { setSelectedUserReducer } from "../../redux/slice/selected-user";
import { setUserProfileReducer } from "../../redux/slice/user-profile";
import { calculateAge, dateFormatIntoDDMMYYYY, genderList, maritalStatusList, relationList } from "../../constants/app-constants";
import { clearSelectedMembersReducer, setSelectedMembersReducer } from "../../redux/slice/selected-members";
import { clearSelectedMemberIdReducer, setSelectedMemberIdReducer } from "../../redux/slice/selected-member-id";

const diaTabs = { FamilyMemberY: "Yes", FamilyMemberN: "No", };
const Iprops = { btnDisplay: true || false };

const FamilyMember = ({
  props = Iprops,
  consultT,
  timeSlot,
  docId,
  hospitalId,
  setProfileSelect,
  setMemberSelect,
  setBookSuccess,
  setBookAppointRes,
  setBookSuccessTime,
  resetBooking,
  setIsPackageSaved,
  mode = null,
  disableConfirm
}) => {

  const [currentUserId, setCurrentUserId] = useState(null);
  const [currentMemberId, setCurrentMemberId] = useState(null);

  const [addToken, setAddToken] = useState(null);
  const [patientId, setPatientId] = useState(null);
  const [currentFamilyMembers, setCurrentFamilyMembers] = useState(null);

  const [callToken, setCallToken] = useState(null);
  const [switchToken, setSwitchToken] = useState(null);
  const [loggedUsers, setLoggedUsers] = useState(null);

  const [selectedLocalUser, setSelectedLocalUser] = useState(null);

  const dispatch = useDispatch();
  const callKey = useSelector((state) => state.callToken.value);
  const switchKey = useSelector((state) => state.switchToken.value);
  const allUsers = useSelector((state) => state.allUsers.value);
  const selectedUser = useSelector((state) => state.selectedUser.value);
  const _members = useSelector((state) => state.selectedMembers.value);
  const _memberId = useSelector((state) => state.selectedMemberId.value);

  useEffect(() => {
    if (callKey) {
      setCallToken(callKey);
    }
  }, [callKey]);

  useEffect(() => {
    if (switchKey) {
      setSwitchToken(switchKey);
    }
  }, [switchKey]);

  useEffect(() => {
    if (allUsers) {
      setCurrentUserId(allUsers[0].id);
      setLoggedUsers(allUsers);

      if (mode) {
        if (_memberId) {
          setCurrentMemberId(_memberId);
        } else {
          setCurrentMemberId(allUsers[0].id);
        }
      } else {
        setCurrentMemberId(allUsers[0].id);
      }
    }
  }, [allUsers]);

  useEffect(() => {
    if (selectedUser) {
      setSelectedLocalUser(selectedUser);
      setCurrentUserId(selectedUser.id);
      setCurrentFamilyMembers(selectedUser.dependents);
      if (mode) {
        if (!_members) {
          setMemberInArray(selectedUser);
        }
      } else {
        setCurrentMemberId(selectedUser.id);
        setMemberSelect(selectedUser);
        resetBooking();
      }
    }
  }, [selectedUser]);

  const [modal, setModal] = useState(false);
  const [familyTab, setFamilyTab] = useState(diaTabs.FamilyMemberY);
  const [error, setError] = useState();
  const [errorflag, setErrorFlag] = useState(false);

  const [profile, setProfile] = useState(null);
  const [DOB, setDOB] = useState(new Date());
  const [allState, setAllState] = useState(null);
  const [allCity, setAllCity] = useState(null);

  const profileHandle = (e) => {
    const file = e.target.files[0];
    setProfile(file);
  };

  const [showForm, setShowForm] = useState('search');
  const [isUpdate, setIsUpdate] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [currentOTP, setCurrentOTP] = useState('');
  const [searchedUserList, setSearchedUserList] = useState([]);

  function handleShow() { setModal(true); }

  function handleClose() {
    setModal(false);
    setShowForm('search');

    setFamilyTab(diaTabs.FamilyMemberY);
    setSearchText('');
    setCurrentOTP('');

    setAddToken(null);
    setPatientId(null);

    setTimeLeft(30);
    resetForm();

    setIsUpdate(false);
    setIsResend(false);

    setSearchedUserList([]);
    setDOB(new Date());
  }

  function handletabs(e) {
    setFamilyTab(e.target.value);
    if (e.target.value === diaTabs.FamilyMemberN) {
      setShowForm('manage');
    } else {
      setShowForm('search');
    }
    resetForm();
  }

  function resetForm() {
    formik.setFieldValue('family_member_id', 0);
    formik.setFieldValue('firstName', '');
    formik.setFieldValue('lastName', '');
    formik.setFieldValue('mobile', '');
    formik.setFieldValue('email', '');
    formik.setFieldValue('addressOne', '');
    formik.setFieldValue('addressTwo', '');
    formik.setFieldValue('pincode', '');
    formik.setFieldValue('gender', "0");
    formik.setFieldValue('marital_status', "Single");
    formik.setFieldValue('dob', new Date());
    formik.setFieldValue('relation', "Father");
    formik.setFieldValue('country', "India");
  }

  function selectSwitch(id) {
    setCurrentUserId(id);
    setCurrentMemberId(id);

    switchPatient({ patient_id: id }, callToken)
      .then(function (switch_response) {
        let switch_token = switch_response.headers.authorization;

        dispatch(setSwitchTokenReducer(switch_token));
        dispatch(setSelectedUserReducer(switch_response.data.user));
        dispatch(clearSelectedMembersReducer());
        dispatch(clearSelectedMemberIdReducer());

        getProfile(switch_token)
          .then(function (profile_response) {
            if (profile_response && profile_response.data) {
              dispatch(setUserProfileReducer(profile_response.data.user));
              if (mode) {
                setMemberInArray(profile_response.data.user);
              } else {
                setMemberSelect(profile_response.data.user);
                resetBooking();
              }
            }
          }).catch(function (err) { });
      }).catch(function (err) { });
  }

  function getOTP() {
    let token = null;
    if (switchToken) {
      token = switchToken;
    } else {
      token = callToken;
    }

    getOTPWithPayloadTokenBased({ isd_code: 91, uid: searchText ? searchText : formik.values.mobile, secondary_request: 1 }, token)
      .then(function (otp_response) {
        let otp_res = otp_response.data;

        if (otp_res) {
          if (otp_res.errors && otp_res.errors.length > 0) {
            return toast.error(otp_res.errors[0].message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false
            });
          } else {
            setShowForm('otp');
            setIsResend(true);
            setTimeLeft(30);
          }
        }
      })
      .catch(function (error) { });
  }

  function verifyOTP() {
    let accessToken = null;
    if (addToken !== null) {
      accessToken = addToken;
    } else {
      if (switchToken) {
        accessToken = switchToken;
      } else {
        accessToken = callToken;
      }
    }

    if (switchToken !== undefined || switchToken !== null) {
      let vPayload = null;
      if (patientId) {
        vPayload = {
          isd_code: "91",
          otp: currentOTP,
          uid: searchText ? searchText : formik.values.mobile,
          family_member_id: patientId,
          secondary_request: 1
        }
      } else {
        vPayload = {
          isd_code: "91",
          otp: currentOTP,
          uid: searchText ? searchText : formik.values.mobile,
          parent_id: currentUserId,
          secondary_request: 1
        }
      }

      verifyMobileOTPTokenBased(vPayload, accessToken)
        .then(function (verify_response) {
          let verify_res = verify_response.data;

          if (verify_res && verify_res.errors) {
            return toast.error(verify_res.errors[0].message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false
            });
          }

          if (showForm === 'last_otp') {
            if (!isUpdate && (verify_res && verify_res.users)) {
              // let tempLocalUser = { ...selectedLocalUser };
              // tempLocalUser.dependents = verify_res.users[0].dependents;
              dispatch(setSelectedUserReducer(verify_res.users[0]));
            }
            // dispatch(setSelectedUserReducer(tempLocalUser));
            setModal(false);
            handleClose();
          } else {
            setAddToken(verify_response.headers.authorization);
            setSearchedUserList(verify_res.search_object);
            setShowForm('search_list');
          }

          setCurrentOTP('');
        }).catch(function (error) { });
    } else {
      verifyMobileOTP({ isd_code: "91", otp: currentOTP, uid: searchText })
        .then(function (verify_response) {
          let verify_res = verify_response.data;

          if (verify_res && verify_res.errors) {
            return toast.error(verify_res.errors[0].message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false
            });
          }

          if (showForm === 'last_otp') {
            if (!isUpdate && (verify_res && verify_res.users)) {
              // let tempLocalUser = { ...selectedLocalUser };
              // tempLocalUser.dependents = [...tempLocalUser.dependents, { ...verify_res.users[0].dependents }];
              dispatch(setSelectedUserReducer(verify_res.users[0]));
            }
            // dispatch(setSelectedUserReducer(tempLocalUser));
            setModal(false);
            handleClose();
          } else {
            setAddToken(verify_response.headers.authorization);
            setSearchedUserList(verify_res.search_object);
            setShowForm('search_list');
          }

          setCurrentOTP('');
        }).catch(function (error) { });
    }
  }

  function setFormForAddMember(id) {
    let payload = `?family_member_id=` + id + `&dependence=true`;
    getProfileWithPayload(payload, addToken)
      .then(function (profile_response) {
        let profile_res = profile_response.data;

        if (profile_res) {
          if (profile_res.errors) {
            return toast.error(profile_res.errors[0].message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false
            });
          }

          if (profile_res.user) {
            formik.setFieldValue('family_member_id', profile_res.user.id);
            formik.setFieldValue('firstName', profile_res.user.first_name);
            formik.setFieldValue('lastName', profile_res.user.last_name);
            formik.setFieldValue('mobile', profile_res.user.mobile_number);
            // formik.setFieldValue('emergencyContact', profile_res.user.emergency_contact);
            setDOB(profile_res.user.dob ? new Date(profile_res.user.dob) : new Date());
            formik.setFieldValue('email', profile_res.user.email);
            formik.setFieldValue('addressOne', profile_res.user.address_1);
            formik.setFieldValue('addressTwo', profile_res.user.address_2);
            formik.setFieldValue('pincode', profile_res.user.pin_code);
            formik.setFieldValue('gender', profile_res.user.gender ? genderList.indexOf(profile_res.user.gender) : 0);
            formik.setFieldValue('marital_status', profile_res.user.marital_status);
            formik.setFieldValue('country', profile_res.user.country_name);
            formik.setFieldValue('state', profile_res.user.state_name);
            formik.setFieldValue('city', profile_res.user.city_name);

            // stateList(profile_res.user.country_name, 'direct');
            // cityList(profile_res.user.state_name, 'direct');

            setShowForm('manage');
            setIsUpdate(true);
          }
        }
      }).catch(function (err) { });
  }

  function getJWTtoken() {
    if (typeof window !== "undefined") {
      if (switchToken) {
        return switchToken;
      } else {
        return callToken;
      }
    } else if (callToken === undefined) {
      return null;
    } else {
      return null;
    }
  }

  function bookAppointmentHandle() {
    function splitAtFirstSpace(str) {
      if (!str) return [];
      var i = str.indexOf(" ");
      if (i > 0) {
        return [str.substring(0, i), str.substring(i + 1)];
      } else return [str];
    }

    let timeSlotFormated = splitAtFirstSpace(timeSlot[0]);
    let timeSlotAccurate = timeSlotFormated[0];

    let JWTresponse = getJWTtoken();

    let payload = (currentMemberId === selectedLocalUser.id) ? {
      booking_date: timeSlotAccurate,
      consult_type: consultT,
      doctor_id: docId,
      hospital_id: hospitalId,
      timeslot_id: timeSlot[1],
    } : {
      booking_date: timeSlotAccurate,
      consult_type: consultT,
      doctor_id: docId,
      family_member_id: currentMemberId,
      hospital_id: hospitalId,
      timeslot_id: timeSlot[1],
    }

    bookAppointmentHospital(payload, JWTresponse)
      .then(function (response) {
        let res = response.data;
        if (res && res.errors && res.errors.length > 0) {
          return toast.error(res.errors[0].message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false
          });
        }

        setBookSuccess(true);
        setBookAppointRes(res);
        setErrorFlag(false);
        setBookSuccessTime(true);
      })
      .catch(function (err) { });
  }

  const formik = useFormik({
    initialValues: {
      family_member_id: 0,
      firstName: "",
      lastName: "",
      mobile: "",
      emergencyContact: "",
      email: "",
      addressOne: "",
      addressTwo: "",
      pincode: "",
      dob: new Date(),
      gender: "0",
      relation: "Father",
      marital_status: "Single",
      country: "India",
      state: "Delhi",
      city: "New Delhi",
    },

    onSubmit: (values, helpers) => {
      if (isUpdate) {
        let payload = {
          family_member_id: values.family_member_id,
          first_name: values.firstName,
          last_name: values.lastName,
          full_name: values.firstName + ' ' + values.lastName,
          gender: values.gender,
          dob: dateFormatIntoDDMMYYYY(DOB),
          isd_code: "91",
          mobile_no: values.mobile,
          marital_status: values.maritalStatus,
          relation: values.relation,
          emergency_contact: values.emergencyContact,
          email: values.email,
          address_1: values.addressOne,
          address_2: values.addressTwo,
          country: values.country,
          state: values.state,
          city: values.city,
          pin_code: values.pincode,
          // profile_picture: profile,
        };

        updateFamilyMember(payload, addToken)
          .then(function (x_response) {
            let x_res = x_response.data;

            if (x_res.errors) {
              x_res.errors.map((e) => {
                return toast.error(e.message, {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: false
                });
              })
              return false;
            }

            if (x_res && x_res.user) {
              // let tempLocalUser = { ...selectedLocalUser };
              // tempLocalUser.dependents = [...tempLocalUser.dependents, { ...x_res.user }];
              getProfile(addToken)
                .then(function (profile_response) {
                  if (profile_response && profile_response.data) {
                    dispatch(setSelectedUserReducer(profile_response.data));
                  }
                }).catch(function (err) { });

              setModal(false);
              handleClose();
            }
          }).catch(function (err) { });
      } else {
        let payload = {
          first_name: values.firstName,
          last_name: values.lastName,
          full_name: values.firstName + ' ' + values.lastName,
          gender: values.gender,
          dob: dateFormatIntoDDMMYYYY(DOB),
          isd_code: "91",
          mobile_no: values.mobile,
          marital_status: values.maritalStatus,
          relation: values.relation,
          emergency_contact: values.emergencyContact,
          email: values.email,
          address_1: values.addressOne,
          address_2: values.addressTwo,
          country: values.country,
          state: values.state,
          city: values.city,
          pin_code: values.pincode,
          // profile_picture: profile,
        };

        let token = null;
        if (switchToken) {
          token = switchToken;
        } else {
          token = callToken;
        }

        addFamilyMember(payload, token)
          .then(function (x_response) {
            let x_res = x_response.data;

            if (x_res.errors) {
              x_res.errors.map((e) => {
                return toast.error(e.message, {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: false
                });
              })
              return false;
            }

            setShowForm('last_otp');
            setPatientId(x_res.patient_id);
          }).catch(function (err) { });
      }
    },
  });

  useEffect(() => {
    if (countries) {
      let countryIndex = countries.map(x => x.n).indexOf(formik.values.country);
      setAllState(countries[countryIndex]['s']);
    }
  }, [formik.values.country])

  useEffect(() => {
    if (allState) {
      let countryIndex = countries.map(e => e.n).indexOf(formik.values.country);
      let stateIndex = allState.map(e => e.n).indexOf(formik.values.state);
      setAllCity(countries[countryIndex]['s'][stateIndex]['c']);
    }
  }, [allState, formik.values.state])

  const [timeLeft, setTimeLeft] = useState(30);
  const [isResend, setIsResend] = useState(false);

  useEffect(() => {
    let timer = setInterval(() => {
      if (isResend) {
        if (timeLeft > 0) {
          setTimeLeft(timeLeft - 1)
        } else {
          clearInterval(timer);
          setIsResend(false);
          setTimeLeft(30);
        }
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  });

  function setMemberInArray(item, event = null) {
    setIsPackageSaved(false);

    if (!event) {
      dispatch(setSelectedMemberIdReducer(currentMemberId));
    } else {
      if (event.target.checked) {
        dispatch(setSelectedMemberIdReducer(parseInt(event.target.value)));
      }
    }

    let tempData = item, otherModification = { packages: item.packages ? item.packages : [] };
    tempData = { ...tempData, ...otherModification };
    let allSelectedMembers = [];

    if (_members) {
      if (event) {

        if (mode === 'checkbox') {
          if (event.target.checked) {
            allSelectedMembers = [..._members, { ...tempData }];
          } else {
            allSelectedMembers = [..._members];
            for (const key in allSelectedMembers) {
              if (allSelectedMembers[key].id === tempData.id) {
                allSelectedMembers.splice(key, 1);
              }
            }
          }
        } else {
          allSelectedMembers = [{ ...tempData }];
        }

        dispatch(setSelectedMembersReducer(allSelectedMembers));
      }
    } else {
      allSelectedMembers.push(tempData);
      dispatch(setSelectedMembersReducer(allSelectedMembers));
    }
  }

  function setCheckedMember(id) {
    if (_members && _members.filter(e => e.id === id).length > 0) {
      return true;
    }
    return false;
  }

  return (
    <React.Fragment>
      <div className="mhc-tabs mt-3">
        <div className="selectProfile">
          <div className="profileList">
            <Tabs className="mb-3" activeKey={currentUserId} onSelect={(k) => { selectSwitch(k); resetBooking() }}>
              {
                loggedUsers?.map((item) => (
                  <Tab key={item.id} eventKey={item.id} title={item.full_name}>
                    <div className="row">
                      {errorflag === true ? (<h6 style={{ color: "red" }}>*{error}</h6>) : null}
                    </div>
                  </Tab>
                ))
              }
            </Tabs>
          </div>

          <div className="row">
            {
              selectedLocalUser &&
              (<div className="col-lg-6 mb-3" key={selectedLocalUser.id}>
                <div className="form-check mhc-bordered-checkbox">
                  <input
                    className="form-check-input"
                    type={!mode ? 'radio' : (mode === 'radio') ? 'radio' : 'checkbox'}
                    name="familyMember"
                    value={selectedLocalUser.id}
                    checked={mode ? setCheckedMember(selectedLocalUser.id) : (currentMemberId === selectedLocalUser.id)}
                    onChange={(event) => {
                      if (mode) {
                        setMemberInArray(selectedLocalUser, event);
                      } else {
                        setCurrentMemberId(selectedLocalUser.id);
                        setMemberSelect(selectedLocalUser);
                        resetBooking();
                      }
                    }}
                  />
                  <label className="form-check-label" htmlFor={selectedLocalUser.id}>
                    <div className="d-flex align-items-center conent">
                      <div className="imgholder">
                        <img src={selectedLocalUser.profile_image} alt="" />
                      </div>
                      <p className="m-0">
                        <span className="fw-bold">{selectedLocalUser.full_name}</span>
                        <br />
                        <small>{selectedLocalUser.gender}, {calculateAge(selectedLocalUser.dob)} (Self)</small>
                      </p>
                    </div>
                  </label>
                </div>
              </div>)
            }

            {
              currentFamilyMembers &&
              currentFamilyMembers.map((item) => (
                <div className="col-lg-6 mb-3" key={item.id}>
                  <div className="form-check mhc-bordered-checkbox">
                    <input
                      className="form-check-input"
                      type={!mode ? 'radio' : (mode === 'radio') ? 'radio' : 'checkbox'}
                      name="familyMember"
                      value={item.id}
                      checked={mode ? setCheckedMember(item.id) : (currentMemberId === item.id)}
                      onChange={(event) => {
                        if (mode) {
                          setMemberInArray(item, event);
                        } else {
                          setCurrentMemberId(item.id);
                          setMemberSelect(item);
                          resetBooking();
                        }
                      }}
                    />
                    <label className="form-check-label" htmlFor={item.id}>
                      <div className="d-flex align-items-center conent">
                        <div className="imgholder">
                          <img src={item.profile_image} alt={item.full_name} />
                        </div>
                        <p className="m-0">
                          <span className="fw-bold">{item.first_name} {item.last_name}</span>
                          <br />
                          <small>{item.gender}, {calculateAge(item.dob)} ({item.relation})</small>
                        </p>
                      </div>
                    </label>
                  </div>
                </div>
              ))
            }
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <button className="btn btn-outline-secondary rounded-1" onClick={handleShow}>Add Family Member</button>
          {
            !mode ? (
              (currentMemberId && consultT && timeSlot) === undefined ? (
                <button className="btn btn-primary rounded-1 ms-2" disabled>Confirm</button>
              ) : (
                <button className={`btn btn-primary rounded-1 ms-2 ${props.btnDisplay === true ? "d-block" : "d-none"}`}
                    onClick={() => {
                      setProfileSelect(true);
                      bookAppointmentHandle();
                    }}>Confirm</button>
              )
            ) :
              <button className="btn btn-primary rounded-1 ms-2" disabled={ (!currentMemberId ? true : false ) || (disableConfirm)} onClick={() => setProfileSelect(true)}>Confirm</button>
          }
        </div>
      </div>

      <Modal show={modal} onHide={handleClose} size="lg">
        <Modal.Body>
          <h4 className="fs-7 text-secondary">Add New Family Member</h4>
          <div className="d-flex">
            <p className="m-0 fs-6 text-secondary fw-bold">Is this an existing MyHealthcare patient?</p>
            <div className="form-check ms-3">
              <input
                className="form-check-input"
                name="existingMember"
                type="radio"
                value={diaTabs.FamilyMemberY}
                id="yes"
                onChange={handletabs}
                defaultChecked
              />
              <label className="form-check-label" htmlFor="yes">
                {diaTabs.FamilyMemberY}
              </label>
            </div>

            <div className="form-check ms-3">
              <input
                className="form-check-input"
                name="existingMember"
                type="radio"
                value={diaTabs.FamilyMemberN}
                id="no"
                onClick={handletabs}
              />

              <label className="form-check-label" htmlFor="no">
                {diaTabs.FamilyMemberN}
              </label>
            </div>
          </div>

          {(showForm === 'search') && (
            <div className="row mt-4">
              <div className="col-md-7 col-lg-6 col-xl-5 ">
                <div className="input-group mb-3">
                  <input type="text" className="form-control" maxLength="10" placeholder="9876543210" onChange={(e) => setSearchText(e.target.value)} />
                  <button className="input-group-text btn btn-primary" onClick={() => getOTP()}>Search</button>
                </div>
              </div>
            </div>
          )}

          {(showForm === 'search_list') && (
            <div className="row">
              <div className="col-md-12">
                <hr />
                <p className="fs-6 fw-bold my-0">Search results</p>
                <p className="fs-6">Select a user to add as your patient.</p>
                <div className="row">
                  {
                    searchedUserList &&
                    searchedUserList.map((item) => (
                      <div className="col-md-6 mb-3" key={item.id}>
                        <div className="form-check mhc-bordered-checkbox">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="searched_user"
                            value={item.id}
                            id={"searched_user_" + item.id}
                            onChange={(e) => setFormForAddMember(e.target.value)}
                          />
                          <label className="form-check-label">
                            <div className="d-flex align-items-center conent">
                              <div className="imgholder">
                                <img src={item.profile_image} alt={item.full_name} />
                              </div>
                              <p className="m-0">
                                <span className="fw-bold">{item.full_name}</span>
                                <br />
                                <small>{calculateAge(item.dob)} Years</small>
                              </p>
                            </div>
                          </label>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          )}

          {(showForm === 'otp' || showForm === 'last_otp') && (
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex align-items-start align-items-sm-center flex-column flex-sm-row mt-4">
                  <p className="fs-6 fw-bold mb-0">An OTP has been sent to your mobile number, please enter to verify.</p>
                  <div className="d-flex align-items-center justify-content-start">
                    <div className="input-group mb-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="XXXX"
                        maxLength="4"
                        onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                        onChange={(e) => setCurrentOTP(e.target.value)}
                      />
                      <button className="input-group-text btn btn-primary" onClick={verifyOTP}>Verify OTP</button>
                    </div>
                    <div className="d-flex align-items-center justify-content-start ms-3">
                      {
                        isResend ? (
                          <div className="anitClock">
                            <img src={antiClock} className="anti-img" alt="" />
                            <p className="otp-time">{timeLeft}</p>
                          </div>
                        ) : (
                          <button onClick={() => getOTP()} className="btn-link text-primary fs-6 text-decoration-none text-nowrap" disabled={isResend}>Resend OTP</button>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {(showForm === 'manage') && (
            <form onSubmit={formik.handleSubmit}>
              <div className="mhc-register-form">
                <div className="row">
                  <div className="col-12">
                    <h1 className="fs-5 mb-3 mt-4 text-center text-lg-start">
                      Patient Details
                    </h1>
                  </div>

                  {/* Full Name */}
                  <div className="col-lg-6">
                    <div className="form-floating mb-4">
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        autoComplete="off"
                        placeholder="name"
                        onChange={formik.handleChange}
                        value={formik.values.firstName} />
                      <label htmlFor="firtName">
                        First Name <sup>*</sup>
                      </label>
                    </div>
                  </div>

                  {/* Last Name */}
                  <div className="col-lg-6">
                    <div className="form-floating mb-4">
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        autoComplete="off"
                        placeholder="last Name"
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                      />
                      <label htmlFor="lastName">
                        Last Name <sup>*</sup>
                      </label>
                    </div>
                  </div>

                  {/* Mobile */}
                  <div className="col-lg-6">
                    <div className="form-floating mb-4">
                      <input
                        type="text"
                        className="form-control"
                        id="mobile"
                        maxLength="10"
                        placeholder="Mobile"
                        onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                        onChange={formik.handleChange}
                        value={formik.values.mobile}
                      />
                      <label htmlFor="mobile">
                        Mobile <sup>*</sup>
                      </label>
                    </div>
                  </div>

                  {/* Email */}
                  <div className="col-lg-6">
                    <div className="form-floating mb-4">
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        placeholder="Email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />
                      <label htmlFor="email">
                        Email <sup>*</sup>
                      </label>
                    </div>
                  </div>

                  {/* DOB */}
                  <div className="col-lg-3">
                    <div className="form-floating mhc-dob date mb-4">
                      <DatePicker
                        calendarClassName="mhc-input-date"
                        dateFormat="dd/MM/yy"
                        closeOnScroll={true}
                        selected={DOB}
                        onChange={(date) => setDOB(date)}
                        calendarStartDay={1}
                        formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
                        showMonthDropdown
                        showYearDropdown
                        adjustDateOnChange
                      />
                      <i className="mhc-icon icon-calendar"></i>
                      <label htmlFor="Dob" className="dob">DOB <sup>*</sup></label>
                    </div>
                  </div>

                  {/* Gender */}
                  <div className="col-lg-3">
                    <div className="mhc-selectbox mb-4">
                      <select
                        name="gender"
                        className="form-select"
                        onChange={formik.handleChange}
                        value={formik.values.gender}>
                        {
                          genderList && genderList.map((item, index) => {
                            return (<option key={item} value={index}>{item}</option>);
                          })
                        }
                      </select>
                      <label htmlFor="floatingSelect">Gender</label>
                    </div>
                  </div>

                  {/* Relation */}
                  <div className="col-lg-3">
                    <div className="mhc-selectbox mb-4">
                      <select
                        name="relation"
                        className="form-select"
                        onChange={formik.handleChange}
                        value={formik.values.relation}>
                        {
                          relationList && relationList.map((item) => {
                            return (<option key={item} value={item}>{item}</option>);
                          })
                        }
                      </select>
                      <label htmlFor="floatingSelect">Relation</label>
                    </div>
                  </div>

                  {/* Marital Status */}
                  <div className="col-lg-3">
                    <div className="mhc-selectbox mb-4">
                      <select
                        name="marital_status"
                        className="form-select"
                        onChange={formik.handleChange}
                        value={formik.values.maritalStatus}>
                        {
                          maritalStatusList && maritalStatusList.map((item) => {
                            return (<option key={item} value={item}>{item}</option>);
                          })
                        }
                      </select>
                      <label htmlFor="floatingSelect">Marital Status</label>
                    </div>
                  </div>

                  {/* Emergency Contact */}
                  <div className="col-lg-6">
                    <div className="form-floating mb-4">
                      <input
                        type="text"
                        className="form-control"
                        id="emergencyContact"
                        placeholder="Emergency Contact"
                        onChange={formik.handleChange}
                        maxLength="10"
                        onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                        value={formik.values.emergencyContact}
                      />
                      <label htmlFor="EmergencyContact">Emergency Contact</label>
                    </div>
                  </div>

                  {/* Profile Picture */}
                  <div className="col-lg-6">
                    <div className=" mb-3 mhc-upload">
                      <input
                        type="file"
                        className="form-control"
                        id="profile"
                        onChange={profileHandle}
                      />
                      <label htmlFor="upload" className="profile-pic">Profile Picture</label>
                    </div>
                  </div>

                  <div className="col-12">
                    <h1 className="fs-5 mb-3 text-center text-lg-start">
                      Address
                    </h1>
                  </div>

                  {/* A1 */}
                  <div className="col-lg-12">
                    <div className="form-floating mb-4">
                      <input
                        type="text"
                        className="form-control"
                        id="addressOne"
                        placeholder="AddressLine"
                        onChange={formik.handleChange}
                        value={formik.values.addressOne}
                      />
                      <label htmlFor="AddressLine">
                        Address Line 1 <sup>*</sup>
                      </label>
                    </div>
                  </div>

                  {/* A2 */}
                  <div className="col-lg-12">
                    <div className="form-floating mb-4">
                      <input
                        type="text"
                        className="form-control"
                        id="addressTwo"
                        placeholder="AddressLine"
                        onChange={formik.handleChange}
                        value={formik.values.addressTwo}
                      />
                      <label htmlFor="AddressLine">
                        Address Line 2
                      </label>
                    </div>
                  </div>

                  {/* Country */}
                  <div className="col-lg-6">
                    <div className="mhc-selectbox mb-4">
                      <select
                        name="country"
                        className="form-select"
                        disabled
                        onChange={formik.handleChange}
                        // onChange={(e) => { stateList(e, 'indirect') }}
                        value={formik.values.country}>
                        {
                          countries && countries.map((item) => {
                            const { n } = item;
                            return (<option key={n} value={n}>{n}</option>);
                          })
                        }
                      </select>
                    </div>
                  </div>

                  {/* State */}
                  <div className="col-lg-6">
                    <div className="mhc-selectbox mb-4">
                      <select
                        name="state"
                        className="form-select"
                        onChange={formik.handleChange}
                        // onChange={(e) => { cityList(e, 'indirect') }}
                        value={formik.values.state}>
                        {
                          allState && allState.map((item) => {
                            const { n } = item;
                            return (<option key={n} value={n}>{n}</option>);
                          })
                        }
                      </select>
                    </div>
                  </div>

                  {/* City */}
                  <div className="col-lg-6">
                    <div className="mhc-selectbox mb-4">
                      <select
                        name="city"
                        className="form-select"
                        onChange={formik.handleChange}
                        value={formik.values.city}>
                        {
                          allCity && allCity.map((item) => {
                            return (<option key={item} value={item}>{item}</option>);
                          })
                        }
                      </select>
                    </div>
                  </div>

                  {/* PinCode */}
                  <div className="col-lg-6">
                    <div className="form-floating mb-4">
                      <input
                        type="text"
                        className="form-control"
                        id="pincode"
                        placeholder="PinCode"
                        maxLength="6"
                        onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                        onChange={formik.handleChange}
                        value={formik.values.pincode}
                      />
                      <label htmlFor="pincode">PIN Code</label>
                    </div>
                  </div>

                  <div className="col-12 text-center">
                    <button className="btn btn-primary rounded-1" type="submit">Confirm</button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Modal.Body>
      </Modal>

      <ToastContainer />
    </React.Fragment>
  );
};

export default FamilyMember;