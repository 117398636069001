import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";
import { Accordion } from "react-bootstrap";

import { doctorTimeSlots } from "../../actions/get-calls";
import LoginUser from "../../component/login-register/login";
import VerifyRegister from "../../component/login-register/VerifyRegister";
import FamilyMember from "../../component/family-member/familyMember";
import BookingSummaryPayment from "../../component/booking-summary-payment/bookinSummaryPayment";
import NewUserRegister from "../../component/login-register/register";
import SelectDateTime from "../../component/select-date-time/selectDateTime";
import { setRemoveWalletReducer } from "../../redux/slice/remove-wallet";
import { removeWallet } from "../../actions/post-call";

const BookingSection = ({ hospitalId, availableDays,setConsultId,docProfile }) => {
  
  const removeWalletData = useSelector((state) => state.removeWallet.value);

  const { dId, hId, sId, cId } = useParams();

  const [consultT, setConsultT] = useState(cId);
  const [docTime, setDocTime] = useState([]);
  const [timeload, setTimeLoad] = useState(false);
  const [consultLoad, setConsultLoad] = useState(false);
  const [dateLoad, setDateLoad] = useState(false);

  const [registered, setRegistered] = useState(true);
  const [verifyOTP, setVerifyOTP] = useState(false);
  const [mobile, setMobile] = useState("");
  const [otpVerified, setOtpVerified] = useState(false);

  const [profileSelect, setProfileSelect] = useState(false);
  const [memberSelect, setMemberSelect] = useState(null);
  const [timeSlot, setTimeSlot] = useState();
  const [bookSuccess, setBookSuccess] = useState(false);
  const [bookAppointRes, setBookAppointRes] = useState(null);
  const [bookSuccessTime, setBookSuccessTime] = useState(false);
  const [cKey, setCKey] = useState("0");
  const dispatch = useDispatch();
  const [selectedDay, setSelectedDay] = useState(new Date());
  const selectedUser = useSelector((state) => state.selectedUser.value);
  

  const callToken = useSelector((state) => state.callToken.value);
  const switchToken = useSelector((state) => state.switchToken.value);
  const [consultType,setConsultType] = useState('0')
  
  useEffect(() => {
    removeWallet(
      {
        booking_id: removeWalletData ? removeWalletData.booking_id : null,
        wallet_id:
          removeWalletData && removeWalletData.wallet
            ? removeWalletData.wallet.id
            : null,
      },
      JWTresponse
    )
      .then(function (response) {
        dispatch(setRemoveWalletReducer(null));
        let res = response.data;
        if (res && res.errors && res.errors.length > 0) {
          // return showErr(res.errors[0].message);
        }
      })
      .catch(function (err) {});
  }, []);
  useEffect(() => {
    // setConsultT(cId);
    if(docProfile.is_active_vc||(docProfile.is_active_opd && docProfile.is_active_vc) ){
      setConsultType('0')
    }else if(docProfile.is_active_opd){
      setConsultType('1')
      
    }
    console.log('cId: ##################', cId);
    // if (cId === '0') {
    //   setConsultId("1");
    // } else {
    //   setConsultT(cId);
    // }
  }, []);

  useEffect(() => {
    setConsultId(consultType);
  }, [hospitalId,consultType]);

  const resetBooking = () => {
    setProfileSelect(false);
    setBookSuccessTime(false);
    setTimeSlot(undefined);
  };

  function getJWTtoken() {
    if (typeof window !== "undefined") {
      if (switchToken) {
        return switchToken;
      } else {
        return callToken;
      }
    } else if (callToken === undefined) {
      return null;
    } else {
      return null;
    }
  }

  let JWTresponse = getJWTtoken();

  function checkToken() {
    if (JWTresponse !== null) {
      return true;
    } else {
      return false;
    }
  }

  const checkingToken = checkToken();

  useEffect(async () => {
    resetBooking();
    // window.history.replaceState(
    //   {},
    //   document.getElementsByTagName('title')[0].innerHTML,
    //   `/doctor-profile/${dId}/${hospitalId}/${sId}/${consultT}`
    // );

    if (!selectedDay) {
      setDateLoad(true);
      return;
    }

    if (!consultType) {
      setConsultLoad(true);
      return;
    } else {
      setTimeLoad(false);
      const dateNew = moment(selectedDay).format("DD/MM/YYYY");
      const response = await doctorTimeSlots(
        dId,
        hospitalId,
        dateNew,
        consultType
      );
      if (response && response.data && response.data.time_slots.length !== 0) {
        setDocTime(response.data.time_slots);
        let slots = response.data.time_slots.all;
        let firstAvailableSlot = slots.find((slot) => {
          if (
            !(slot.blocked || slot.booked || slot.is_blocked || slot.lapsed)
          ) {
            return true;
          }
        });

        if (firstAvailableSlot) {
          setTimeSlot([firstAvailableSlot.slot, firstAvailableSlot.id]);
        }
        setTimeLoad(true);
        setConsultLoad(false);
        setDateLoad(false);
      }
    }
  }, [hospitalId, selectedDay, consultType]);

  const displaySlotTime = () => {
    let content = (
      <div className="alert alert-danger text-center">
        No time slots available!
      </div>
    );
    if (!docTime) return content;

    if (timeload && docTime && docTime.all !== []) {
      return (content = (
        <>
          <h6 className="fs-6 mb-3 mt-4 date-time">Select Time Slot</h6>
          <ul className="list-unstyled d-flex flex-wrap mb-5">
            {docTime.all.map((item) => {
              return (
                <li key={item.id}>
                  <button
                    disabled={
                      item.blocked ||
                      item.booked ||
                      item.is_blocked ||
                      item.lapsed
                    }
                    className={`btn time-slot-btn rounded-1 ${
                      item.blocked ||
                      item.booked ||
                      item.is_blocked ||
                      item.lapsed
                        ? "selected"
                        : ""
                    } ${timeSlot && timeSlot[1] === item.id ? "active" : ""}`}
                    onClick={() => setTimeSlot([item.slot, item.id])}
                  >
                    {item.slot.substring(item.slot.indexOf(" ") + 1)}
                  </button>
                </li>
              );
            })}
          </ul>
        </>
      ));
    }

    if (docTime && docTime.all === []) return content;
  };

  useEffect(() => {
    setBookSuccess(false);
    setBookAppointRes(null);
    setBookSuccessTime(false);
  }, [selectedDay, timeSlot]);

  useEffect(() => {
    if (bookAppointRes !== null) {
      setCKey("1");
    } else {
      setCKey("0");
    }
  }, [bookAppointRes]);

  function toggleAccordion(index) {
    let parentEl = document.querySelectorAll("#summary-tabs .accordion-item")[
      index
    ];
    let child = parentEl.firstElementChild || parentEl.firstChild;
    let secondChild = child.firstElementChild || child.firstChild;
    secondChild.click();
  }

  return (
    <>
      <div className="row mt-4">
        <div className="col-lg-6">
          <div className="d-flex flex-column flex-lg-row justify-content-start align-items-lg-center">
            <span className="fw-bold ">Consult Type</span>
            <ul className="list-unstyled mb-0 mt-4 mt-lg-0 d-flex align-items-center">
              {/* {(!!docProfile.is_active_opd && !!docProfile.is_active_vc) && <li>
                <div className="form-check mt-1 ms-3 ms-sm-4">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="consultType"
                    id="All"
                    value="0"


                    checked={consultT === "0"}
                    onChange={(e) => setConsultT(e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="All">
                    All
                  </label>
                </div>
              </li>} */}
              {docProfile.is_active_vc &&<li>
                <div className="form-check mt-1 ms-3 ms-sm-4">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="consultType"
                    id="Virtual"
                    value="0"
                    checked={consultType=='0'}
                    onChange={(e) => setConsultType(e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="Virtual">
                    Virtual
                  </label>
                </div>
              </li>}
              { !!docProfile.is_active_opd &&<li>
                <div className="form-check mt-1 ms-3 ms-sm-4">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="consultType"
                    id="Hospital"
                    value="1"
                    checked={consultType=='1'}
                    // checked={docProfile.is_active_opd}
                    onChange={(e) => setConsultType(e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="Hospital">
                    At Hospital/Clinic
                  </label>
                </div>
              </li>}
            </ul>
          </div>

          <div>
            {consultLoad && (
              <h6 style={{ color: "red" }}>
                *Please select a consult type first
              </h6>
            )}
          </div>
        </div>

        <div className="col-lg-6 mt-4 mt-lg-0">
          <h6 className="fs-6 mb-0 date-time">Select Date &amp; Time</h6>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 order-1 order-lg-0">
          <Accordion
            className="mt-4 mhc-profile-tabs"
            id="summary-tabs"
            activeKey={cKey}
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header>1. Login/ Register</Accordion.Header>

              <Accordion.Body className="py-3">
                {((registered && !checkingToken) || !selectedUser) && (
                  <LoginUser
                    registeredState={setRegistered}
                    setMobile={setMobile}
                    setOtpVerified={setOtpVerified}
                  />
                )}

                {(!registered || verifyOTP) && !otpVerified && (
                  <NewUserRegister
                    setVerifyOTP={setVerifyOTP}
                    mobile={mobile}
                    setOtpVerified={setOtpVerified}
                  />
                )}

                {/* {verifyOTP && (
                  <VerifyRegister
                    mobile={mobile}
                    setOtpVerified={setOtpVerified}
                  />
                )} */}

                {(otpVerified || checkingToken) && selectedUser&& (
                  <FamilyMember
                    consultT={consultType}
                    timeSlot={timeSlot}
                    docId={dId}
                    hospitalId={hospitalId}
                    btnDisplay={false}
                    setProfileSelect={setProfileSelect}
                    setMemberSelect={setMemberSelect}
                    setBookSuccess={setBookSuccess}
                    setBookAppointRes={setBookAppointRes}
                    setBookSuccessTime={setBookSuccessTime}
                    resetBooking={resetBooking}
                  />
                )}
              </Accordion.Body>
            </Accordion.Item>

            {bookAppointRes ? (
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  2. Booking Summary &amp; Payment
                </Accordion.Header>
                <Accordion.Body className="py-3">
                  <BookingSummaryPayment
                    consultT={consultType}
                    memberSelect={memberSelect}
                    bookAppointRes={bookAppointRes}
                    setBookAppointRes={setBookAppointRes}
                    setBookSuccess={setBookSuccess}
                    setBookSuccessTime={setBookSuccessTime}
                  />
                </Accordion.Body>
              </Accordion.Item>
            ) : (
              ""
            )}
          </Accordion>
        </div>

        <div className="col-lg-6 order-0 order-lg-1">
          <SelectDateTime
            selectedDay={selectedDay}
            setSelectedDay={setSelectedDay}
            minDate={moment().toDate()}
            availableDays={availableDays || []}
          />

          {timeSlot === false ? (
            <div className="col-12 position-relative text-center mt-3">
              <img
                src="../../../../loader.gif"
                style={{ height: "30px" }}
                alt="Loading..."
              />
            </div>
          ) : docTime ? (
            <>{displaySlotTime()}</>
          ) : (
            <div className="mx-2 mt-3 alert alert-danger text-center">
              No time slots available!
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BookingSection;
