import React, { useState, useEffect, useLayoutEffect } from "react";
import { Container, Accordion, useAccordionButton } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Breadcrumb from "../../component/breadcrumb";
import MultiImageUploader from "../../component/multi-image-uploader";
import tataOneMg from "../../assets/dust/tata-onemg.webp";
import dicount from "../../assets/dust/tenOff.svg";
import { useFormik } from "formik";
import { orderMedicine } from "../../actions/order-medicine";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setDeliveryReducer } from "../../redux/slice/delivery-address";

import countries from "../../assets/data/countries.json";
import { getOneMGPincode, getOrderMedicinePincode } from "../../actions/get-calls";
import BreadcrumbWithUrl from "../../component/breadcrumb/breadcrumb-with-url";

const OrderMedicine = () => {
  const dispatch = useDispatch();
  const delivery = useSelector((state) => state.delivery.value);
  const locationData = useSelector((state)=>state.location.value)
  
  const [selectedCountry, setSelectedCountry] = useState('India');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');

  const [allState, setAllState] = useState('');
  const [allCity, setAllCity] = useState('');
  const [pincode, setPincode] = useState('');
  const [isServiceable, setIsServiceable] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isTermAgreed, setIsTermAgreed] = useState(false);
  const [saveForFuture, setSaveForFuture] = useState(false);
  const [isDeliveryAdded, setIsDeliveryAdded] = useState(false);

  const [vendorId, setVendorId] = useState(null);
  const [vendors, setVendors] = useState(null);

  const [prescription, setPrescription] = useState(null);
  const [isConsentSigned, setIsConsentSigned] = useState(false);

  const [orderDetail, setOrderDetail] = useState(null);
  const selectedUser = useSelector((state) => state.selectedUser.value);

  const formik = useFormik({
    initialValues: {
      mobile: "",
      email: "",
      addressOne: "",
      addressTwo: "",
      name: "",
    },

    onSubmit: (values, helpers) => {
      if (values.name === "") {
        return showToast("Full name is mandatory!");
      } else if (values.mobile === "") {
        return showToast("Mobile is mandatory!");
      } else if (values.email === "") {
        return showToast("Email is mandatory!");
      } else if (values.addressOne === "") {
        return showToast("Address Line 1 is mandatory!");
        // } else if (selectedState) {
        //   return showToast("State is mandatory!");
        // } else if (selectedCity) {
        //   return showToast("City is mandatory!");
        // } else if (pincode) {
        //   return showToast("Pincode is mandatory!");
      } else if (!isConsentSigned) {
        return;
      } else if (!isTermAgreed) {
        return showToast("Please check terms & conditions!");
      } else if (!prescription) {
        return showToast("Please upload prescriptions!");
      }

      const formData = new FormData();

      formData.append('vendor_id', vendorId);
      formData.append('name', values.name);
      formData.append('contact_number', values.mobile);
      formData.append('email', values.email);
      formData.append('street1', values.addressOne);
      formData.append('street2', values.addressTwo);
      formData.append('city', selectedCity);
      formData.append('state', selectedState);
      formData.append('country', selectedCountry);
      formData.append('building', ".");
      formData.append('pincode', pincode);

      if (prescription) {
        for (const key in prescription) {
          formData.append('files[]', new Blob([prescription[key]], {
            type: 'application/json'
          }));
        }
      }

      orderMedicine(formData)
        .then(function (response) {
          let res = response.data;
          if (res && res.errors) {
            res.errors.map((error) => {
              return showToast(error.message);
            })
          }
          
          setOrderDetail(res);
        }).catch(function (err) { });
    },
  });
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  useEffect(()=>{
    if(locationData){
      setPincode(locationData.pin_code)
    }
  },[locationData])
  useEffect(()=>{
    if(selectedUser){
      formik.setFieldValue('name', selectedUser.full_name);
      formik.setFieldValue('email', selectedUser.email);
      formik.setFieldValue('mobile', selectedUser.mobile_number);
      formik.setFieldValue('addressOne', selectedUser.address);
      formik.setFieldValue('addressTwo', selectedUser.address_2);
    }
  },[])
  useEffect(() => {
    if (delivery) {
      formik.setFieldValue('name', delivery.name);
      formik.setFieldValue('email', delivery.email);
      formik.setFieldValue('mobile', delivery.mobile);
      formik.setFieldValue('addressOne', delivery.addressOne);
      formik.setFieldValue('addressTwo', delivery.addressTwo);
    }
  }, [delivery]);

  useEffect(() => {
    if (countries) {
      let countryIndex = countries.map(e => e.n).indexOf(selectedCountry);
      setAllState(countries[countryIndex]['s']);
    }
  }, [selectedCountry]);

  useEffect(async () => {
    setIsServiceable(0);
    resetStep(1);

    if (pincode && pincode.toString().length === 6) {
      setIsLoading(true);
      const matchingResponse = await getOneMGPincode(pincode);

      if (matchingResponse && matchingResponse.data) {
        setIsLoading(false);

        if (matchingResponse.data.pincode.length === 0) {
          setIsServiceable(1);
        } else {
          let pincodeData = matchingResponse.data.pincode[0];

          setIsServiceable(2);
          setSelectedState(pincodeData.state);

          if (countries && pincodeData.state) {
            let countryIndex = countries.map(e => e.n).indexOf(selectedCountry);
            let stateIndex = allState.map(e => e.n).indexOf(pincodeData.state);
            setAllCity(countries[countryIndex]['s'][stateIndex]['c']);
          }
          setSelectedCity(pincodeData.city);
        }
      }
    }
  }, [pincode]);

  useEffect(async () => {
    if (isDeliveryAdded) {
      if (saveForFuture) {
        dispatch(
          setDeliveryReducer({
            name: formik.values.name,
            email: formik.values.email,
            mobile: formik.values.mobile,
            addressOne: formik.values.addressOne,
            addressTwo: formik.values.addressTwo,
          })
        );
      }

      const vendorResponse = await getOrderMedicinePincode(pincode);
      if (vendorResponse && vendorResponse.data && vendorResponse.data.pincode && vendorResponse.data.pincode.vendors) {
        if (vendorResponse.data.pincode.vendors.length !== 0) {
          // setVendorId(vendorResponse.data.pincode.vendors[0].id);
          setVendors(vendorResponse.data.pincode.vendors);
        }
      }
    } else {
      resetStep(2);
    }
  }, [isDeliveryAdded]);


  function cityList(e) {
    setSelectedState(e.target.value);
    if (countries && allState && e.target.value) {
      let countryIndex = countries.map(e => e.n).indexOf(selectedCountry);
      let stateIndex = allState.map(e => e.n).indexOf(e.target.value);
      setAllCity(countries[countryIndex]['s'][stateIndex]['c']);
    }
  }

  function resetStep(step) {
    if (step === 1) {
      setIsTermAgreed(false);
      setSaveForFuture(false);
    }

    if ((step === 1) || (step === 2)) {
      setIsDeliveryAdded(false);
      setVendorId(null);
    }

    if ((step === 1) || (step === 2) || (step === 3)) {
      setPrescription(null);
      setIsConsentSigned(false);
    }

    if ((step === 1) || (step === 2) || (step === 3) || (step === 4)) {
      setOrderDetail(null);
    }
  }

  function showToast(str) {
    toast.error(str, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false
    });
  }

  function ToggleStepTwo({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      setIsDeliveryAdded(true);
    });

    return (<button type="button" className="btn btn-outline-primary rounded-1" onClick={decoratedOnClick}>{children}</button>);
  }

  function ToggleStepThree({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      setVendorId(children.id);
      resetStep(3);
    });

    return (<input className="form-check-input" type="radio" name="vendor" value={children.id} checked={vendorId === children.id} onChange={decoratedOnClick} />);
  }

  function ToggleStepFour({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      
    });

    return (<button className="btn btn-primary rounded-1" disabled={!isConsentSigned} type={(!isConsentSigned) ? "button" : "submit"} onClick={decoratedOnClick}>{children}</button>);
  }
  const breadCrumbData = [
    { type: 1, url: '/', name: 'Home' },
    { type: 3, name: 'Order Medicine' },
];
  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <Container>
        <BreadcrumbWithUrl breadcrumbs={breadCrumbData} />

          <h5 className="fs-7 text-secondary">Order Medicine</h5>

          <div className="row my-4">

            {/* Pincode */}
            <div className="col-md-4">
              <div className="form-floating position-relative">
                <input
                  type="text"
                  className="form-control"
                  required
                  maxLength="6"
                  value={pincode}
                  autoComplete="off"
                  placeholder="Enter pincode"
                  onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                  onChange={(e) => setPincode(e.target.value)}
                />
                {isLoading && (
                  <div className="ajax-loader">
                    <img src="../loader.gif" className="w-100" alt="Loding Image" />
                  </div>
                )}

                <label>Pincode <sup>*</sup></label>
              </div>
              {(isServiceable !== 0) && <p className="mb-0 mt-1 text-primary">Your location is {(isServiceable === 2) ? 'servicable' : 'not servicable'}.</p>}
            </div>

            {/* State */}
            <div className="col-md-4 mt-4 mt-md-0">
              <div className="mhc-selectbox">
                <select
                  name="state"
                  required
                  className="form-select"
                  onChange={(e) => { cityList(e) }}
                  value={selectedState}>
                  {
                    allState && allState.map((item) => {
                      const { n } = item;
                      return (<option key={n} value={n}>{n}</option>);
                    })
                  }
                </select>
                <label htmlFor="floatingSelect">State*</label>
              </div>
            </div>

            {/* City */}
            <div className="col-md-4 mt-4 mt-md-0">
              <div className="mhc-selectbox">
                <select
                  name="city"
                  required
                  className="form-select"
                  onChange={(e) => { setSelectedCity(e.target.value) }}
                  value={selectedCity}>
                  {
                    allCity && allCity.map((item) => {
                      return (<option key={item} value={item}>{item}</option>);
                    })
                  }
                </select>
                <label htmlFor="floatingSelect">City*</label>
              </div>
            </div>
          </div>

          {
            (isServiceable === 2 && selectedState && selectedCity) ? (
              <div className="row accordion_orderMed">
                <div className="col-12">
                  <Accordion defaultActiveKey="0" className="mhc-profile-tabs">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Delivery Address</Accordion.Header>
                      <Accordion.Body className="py-3">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-floating mb-4">
                              <input
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                placeholder="Enter full name"
                                name="name"
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                required
                              />
                              <label htmlFor="name">Full Name <sup>*</sup></label>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-floating mb-4">
                              <input
                                type="text"
                                className="form-control"
                                name="mobile"
                                autoComplete="off"
                                placeholder="mobile"
                                maxLength="10"
                                required
                                onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                                onChange={formik.handleChange}
                                value={formik.values.mobile}
                              />
                              <label htmlFor="mobile">Mobile <sup>*</sup>
                              </label>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-floating mb-4">
                              <input
                                type="text"
                                className="form-control"
                                name="email"
                                autoComplete="off"
                                placeholder="Enter email"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                required
                              />
                              <label>Email <sup>*</sup></label>
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="form-floating mb-4">
                              <input
                                type="text"
                                className="form-control"
                                name="addressOne"
                                autoComplete="off"
                                placeholder="AddressLine"
                                onChange={formik.handleChange}
                                value={formik.values.addressOne}
                                required
                              />
                              <label>Address Line 1 <sup>*</sup></label>
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="form-floating mb-4">
                              <input
                                type="text"
                                className="form-control"
                                name="addressTwo"
                                autoComplete="off"
                                placeholder="AddressLine"
                                onChange={formik.handleChange}
                                value={formik.values.addressTwo}
                              />
                              <label htmlFor="AddressLine">Address Line 2</label>
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                autoComplete="off"
                                required
                                value={isTermAgreed}
                                onChange={(e) => { setIsTermAgreed(e.target.checked); resetStep(2); }}
                              />
                              <label className="form-check-label d-block d-sm-inline">
                                I have read and agree to the <a href="/#" className="text-primary text-decoration-none">Terms and Conditions.</a>
                              </label>
                            </div>
                          </div>

                          <div className="col-12 mt-3">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                autoComplete="off"
                                value={saveForFuture}
                                onChange={(e) => { setSaveForFuture(e.target.checked); resetStep(2); }}
                              />
                              <label className="form-check-label d-block d-sm-inline">Save For Future Deliveries</label>
                            </div>
                          </div>

                          {
                            (
                              ((formik.values.email &&
                                formik.values.mobile &&
                                formik.values.name &&
                                formik.values.addressOne) === "") || !isTermAgreed) ?
                              (
                                <div className="col-12 mt-3 text-center">
                                  <button disabled className="btn btn-outline-primary rounded-1">Continue</button>
                                </div>
                              ) : (
                                <div className="col-12 mt-3 text-center">
                                  <ToggleStepTwo eventKey="1">Continue</ToggleStepTwo>
                                </div>
                              )
                          }
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    {
                      isDeliveryAdded ? (
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>Select Vendor</Accordion.Header>
                          <Accordion.Body className="py-3">
                            <div className="row">
                              {
                                vendors && vendors.map((vendorItem) => {
                                  return (
                                    <div className="col-md-6 mb-xs-3" key={vendorItem.id}>
                                      <div className="form-check mhc-vender-checkbox">
                                        <ToggleStepThree eventKey="2">{vendorItem}</ToggleStepThree>
                                        <label className="form-check-label w-100" htmlFor="tata">
                                          <div className="d-flex align-items-center justify-content-between w-100 ms-4">
                                            <div className="imgholder">
                                              <img src={vendorItem.logo} className="brand-name" alt={vendorItem.vendor_name} />
                                              <p className="fs-13 m-0">{vendorItem.requirement}</p>
                                            </div>
                                            <img src={dicount} alt="" className="discount-icon" />
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      ) : ''
                    }

                    {
                      vendorId ? (
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>Upload Prescription</Accordion.Header>
                          <Accordion.Body className="py-3">
                            <div className="row">
                              <div className=" col-md-6 mhc-multiImage">
                                <div className="mb-3">
                                  <MultiImageUploader setPrescri={setPrescription} />
                                </div>

                                <div>
                                  <h6>Patient Consent</h6>
                                  <p>By confirming the order, I consent to my prescription being shared with MyHealthcare Pharmacy patners for home delivery of medicines</p>
                                </div>

                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={isConsentSigned}
                                    disabled={!prescription}
                                    onChange={(e) => setIsConsentSigned(e.target.checked)} />
                                  <label className="form-check-label">I Agree</label>
                                </div>

                                <div className="mt-3">
                                  {
                                    prescription ?
                                      <ToggleStepFour eventKey="3">Confirm Order</ToggleStepFour> :
                                      <button className="btn btn-primary rounded-1" disabled type="button">Confirm Order</button>
                                  }
                                </div>
                              </div>

                              <div className="col-md-6 mt-4 mt-md-0 ">
                                <div className="bg-white mhc-payment-details div_ul_name">
                                  <div className="details_prescription">
                                    <h6>Prescription should contain</h6>
                                    <ul className="ul_name">
                                      <li className="li_name">
                                        Government regulation require a prescription for
                                        ordering some medicines
                                      </li>
                                      <li className="li_name">
                                        Order will confirmed only after receiving the
                                        prescription
                                      </li>
                                      <li className="li_name">
                                        Don't crop out any part of the image
                                      </li>
                                      <li className="li_name">
                                        Include doctor &amp; patient name as well as
                                        clinic/hospital visit date
                                      </li>
                                      <li className="li_name">
                                        Medicines will be dispensed as per prescription
                                      </li>
                                      <li className="li_name">
                                        Supported files type: jpeg , jpg , png
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      ) : ''
                    }

                    {
                      orderDetail ? (
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>Order Confirmation</Accordion.Header>
                          <Accordion.Body className="p-3">
                            <div className="row">
                              <div className="col-md-12">
                                <h1 className="fs-5 text-primary">Order Confirmed</h1>
                                <p className="mhc-dector-profile-text">
                                  Thank you, Amarendra Singh for entrusting your health
                                  in our hands. Your Order Details are below.
                                </p>
                              </div>
                            </div>
                            <div className="row order_confirmed_details mt-4">
                              <div className="col-md-6 d-flex mt-3">
                                <p className="mhc-dector-profile-text">Order ID</p>
                                <h4 className="fs-7 text-secondary ms-4 ">
                                  BA2U651XN8IKDE2
                                </h4>
                              </div>
                              <div className="col-md-6 mt-3 mt-lg-0 text-start text-md-end">
                                <img src={tataOneMg} alt="" className="lab-icon" />
                              </div>
                            </div>
                            <div className="mhc-patient-details mt-4 p-0">
                              <div className="row">
                                <div className="col-lg-4 text-center text-lg-start ">
                                  <div className="mhc-details-border p-3">
                                    <p className="mhc-dector-profile-text">
                                      AMARENDRA SINGH
                                    </p>
                                    <p className="mhc-dector-profile-text">
                                      9810681199
                                    </p>
                                    <p className="mhc-dector-profile-text">
                                      amarendra@myhealthcare.life
                                    </p>
                                    <p className="mhc-dector-profile-text">
                                      Khanpur, New Delhi
                                    </p>
                                  </div>
                                </div>

                                <div className="col-lg-8 text-center">
                                  <div className="p-3">
                                    <p>
                                      Your order id PO21542740042 has been submitted
                                      successfully
                                    </p>
                                    <p className="m-0">
                                      Our pharmacy patner PharmEasy will call you back
                                      to process your order
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      ) : ''
                    }
                  </Accordion>
                </div>
              </div>
            ) : ''
          }
        </Container>
      </form>

      <ToastContainer />
    </React.Fragment>
  );
};

export default OrderMedicine;
