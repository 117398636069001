import React, { useCallback, useLayoutEffect, useState } from "react";
import { useEffect } from "react";
import { Container, Row, Col, Nav, Tab, Accordion } from "react-bootstrap";
import { getAllBlogs, getAllFAQs } from "../../actions/get-calls";
import Breadcrumb from "../../component/breadcrumb";
import BreadcrumbWithUrl from "../../component/breadcrumb/breadcrumb-with-url";

function FAQ() {
  const [key, setKey] = useState("");
  const [currentFaq, setCurrentFaq] = useState({});
  console.log("currentFaq: ", currentFaq);
  const [faqs, setFaqs] = useState([]);
  const fetchFAQS = useCallback(async () => {
    const response = await getAllFAQs();
    console.log("response: ", response);
    if (response.data && Array.isArray(response.data)) {
      setFaqs(response.data || []);
      if (response.data.length) {
        setKey(response.data[0].heading);
        setCurrentFaq(response.data[0]);
      }
    }
  }, []);
  useEffect(() => {
    fetchFAQS();
  }, []);
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});
  const handleOnSelect = (kk) => {
    console.log(kk);
    setKey(kk);
  };
  const breadCrumbData = [
    { type: 1, url: "/", name: "Home" },
    { type: 2, name: 'FAQ' },
  ];
  return (
    <React.Fragment>
      <Container>
      <BreadcrumbWithUrl breadcrumbs={breadCrumbData} />

        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col lg={3}>
              <div className="faq-container">
                <Nav
                  variant="pills"
                  className="flex-column "
                  activeKey={key}
                  onSelect={(k) => handleOnSelect(k)}
                >
                  {faqs.map((item) => {
                    return (
                      <Nav.Item>
                        <Nav.Link
                          eventKey={item.heading}
                          active={item.heading === key ? true : false}
                          style={{ cursor: "pointer" }}
                        >
                          {item.heading}
                        </Nav.Link>
                      </Nav.Item>
                    );
                  })}
                </Nav>
              </div>
            </Col>
            <Col lg={9}>
              <Tab.Content>
                {faqs.map((item,index) => {
                  return (
                    <Tab.Pane
                      eventKey={item.heading}
                      className={key === item.heading ? "active show" : ""}
                    >
                      <Accordion
                        defaultActiveKey={index}
                        flush
                        className="faq-container"
                      >
                        {item.faqsdata.map((fitem,index) => {
                          return (
                            <Accordion.Item eventKey={index}>
                              <Accordion.Header>
                                {fitem.question}{" "}
                              </Accordion.Header>
                              <Accordion.Body>
                                <div
                                  className="blog-details-body"
                                  dangerouslySetInnerHTML={{
                                    __html: fitem.answer,
                                  }}
                                ></div>
                              </Accordion.Body>
                            </Accordion.Item>
                          );
                        })}
                      </Accordion>
                    </Tab.Pane>
                  );
                })}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>

        <h1 className="text-center mt-5 ">Still have a question?</h1>
        <p className="text-center fs-5">
          If you can’t find the answer in our FAQs, <br /> contact us and we’ll
          get back to you shortly!
        </p>
      </Container>
      <br />
      <br />
    </React.Fragment>
  );
}

export default FAQ;
