import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Tabs, Tab } from "react-bootstrap";
import Breadcrumb from "../../component/breadcrumb";
import dashboard from '../../assets/images/dashboard.svg'
import DoctorConsult from '../patientMyBooking/doctorConsult'
import PatientHomeCare from '../patientMyBooking/patientHomeCare'
import PatientDiagnostics from '../patientMyBooking/patientDiagnostics'
import { Link, useNavigate } from "react-router-dom";
import SwitchProfile from "../../component/login-register/switchProfile";
import PatientMedicines from "../patientMyBooking/PatientMedicines";
import { appointmentCall, homeCareCall, diagnosticsCall, medicinesCall } from "../../actions/dashboard-api";
import InfiniteScroll from "react-infinite-scroll-component";
import LodingComponent from "../../component/loding";
import NoMoreData from "../../component/no-more-data/noMoreData";

import moment from 'moment';
import DatePicker from "react-datepicker";
import NoDataFounded from "../../component/no-data-found/index";


const breadcrumArrry=[
    {
      name:'My Dashboard',
      url:'/patient-dashboard'
    }, 
    {
        name:'My Bookings',
        url:''
      }, 
  ]
const PatientMyBooking = () => {

    const switchToken = useSelector((state) => state.switchToken.value);
    const selectedUserCard = useSelector((state) => state.selectedUserCard.value);
    const [currentTab, setCurrentTab] = useState("DoctorConsult");
    
    // For Doctor Consult Hooks
    const [appointmentData, setAppointment] = useState();
    const [apppagination, setAppPagination] = useState();
    const [appHasMore, setAppHasMore] = useState(true);
    const [appAppointmentsPage, setAppAppointmentsPage] = useState(1);
    const [appAppointmentsLoader, setAppAppointmentsLoader] = useState(false);

    // For Home Care Hooks
    const [homeCareData, sethomeCare] = useState();
    const [homeCarePagination, setHomeCarePagination] = useState();
    const [homeCareHasMore, setHomeCareHasMore] = useState(true);
    const [homeCarePage, setHomeCarePage] = useState(1);
    const [homeCareLoder, setHomeCareLoder] = useState(false);

    // For diagnostics Hooks 
    const [diagnosticsData, setDiagnosticsData] = useState();
    const [diagnosticsPagination, setDiagnosticsPagination] = useState()
    const [diagnosticsHaseMore, setDiagnosticsHaseMore] = useState(true)
    const [diagnosticsPage, setDiagnosticsPage] = useState(1)
    const [diagnosticsLoder, setDiagnosticsLoder] = useState(false)

    // For medicines Hooks
    const [medicinesData, setMedicinesData] = useState();
    const [medicinesPagination, setMedicinesPagination] = useState();
    const [medicinesHasMore, setMedicinesHasMore] = useState(true)
    const [medicinesPage, setMedicinesPage] = useState(1)
    const [medicinesLoder, setMedicinesLoder] = useState(false)
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    // console.log("apppagination", apppagination)

    const allUsers = useSelector((state) => state.allUsers.value);
    const navigate = useNavigate();
      useEffect(() => {
        if (!allUsers) {
          navigate('/');
        }
      },[]);

    useEffect(() => {
        let isFamilyMember = selectedUserCard.card == "member" ? '&family_member_id='+selectedUserCard.id:'';
        // For Doctor Consult        
        appointmentCall(appAppointmentsPage, null, null, isFamilyMember, switchToken).then(function (response) {
            setAppPagination(response.data.pagination)
            setAppointment(response.data.appointments);

        })
            .catch(function (err) {
                console.log(err);
            });

        // For Homecare
        homeCareCall(homeCarePage, isFamilyMember, switchToken).then(function (response) {
            setHomeCarePagination(response.data.pagination)
            sethomeCare(response.data.appointments);
        })
            .catch(function (err) {
                console.log(err);
            });

        // For Diagnostic
        diagnosticsCall(diagnosticsPage, isFamilyMember, switchToken).then(function (response) {
            setDiagnosticsPagination(response.data.pagination)
            setDiagnosticsData(response.data.appointments)
        })
            .catch(function (err) {
                console.log(err);
            });

        // For Medicine
        medicinesCall(medicinesPage, isFamilyMember, switchToken).then(function (response) {
            setMedicinesPagination(response.data.pagination)
            setMedicinesData(response.data.order)

        })
            .catch(function (err) {
                console.log(err);
            });
            
    }, [switchToken, selectedUserCard]);

   
 // For Doctor Consult More Data
 const appFetchMoreData = () => {
    if (currentTab === "DoctorConsult" && appAppointmentsPage < apppagination.total_pages) {
        setAppAppointmentsLoader(true);
        let nextPage;
        nextPage = appAppointmentsPage + 1;


        let getStartDate=moment(startDate).format("YYYY-MM-DD")
        let getEndDate=moment(endDate).format("YYYY-MM-DD")

        let isFamilyMember = selectedUserCard.card == "member" ? '&family_member_id='+selectedUserCard.id:'';
        setAppAppointmentsPage(nextPage); 
            appointmentCall(nextPage, null, null, isFamilyMember, switchToken).then(function (response) {
            setAppointment(appointmentData.concat(response.data.appointments));
            setAppAppointmentsLoader(false)
        })
    }
    else {
        setAppHasMore(false)
    }
}


const handleDateApi=(date)=>{
    setStartDate(date)
    setEndDate(date)
}
const handleDateApi1=(date)=>{ 
    setEndDate(date);
}

const getFilterData=()=>{
    let getStartDate=moment(startDate).format("YYYY-MM-DD")
    let getEndDate=moment(endDate).format("YYYY-MM-DD")
    setAppointment()
    setAppAppointmentsLoader(false);
    let isFamilyMember = selectedUserCard.card == "member" ? '&family_member_id='+selectedUserCard.id:'';
    appointmentCall(appAppointmentsPage, getStartDate, getEndDate, isFamilyMember, switchToken).then(function (response) {     
        setAppointment(response.data.appointments);
    })
}

const refreshData=()=>{
    setStartDate();
    setEndDate()
    setAppointment()
    setAppAppointmentsLoader(false);
    setAppAppointmentsPage(1)
    let isFamilyMember = selectedUserCard.card == "member" ? '&family_member_id='+selectedUserCard.id:'';
    appointmentCall(appAppointmentsPage, null, null, isFamilyMember, switchToken).then(function (response) {     
        setAppointment(response.data.appointments);
    })  
}



    // For Home Care Scroll More Data
    const HomeCareFatchData = () => {
   
        if (currentTab === "Homecare" && homeCarePage < homeCarePagination.total_pages) {
            setHomeCareLoder(true)        
             let pageNumber;
            pageNumber = homeCarePage + 1
            setHomeCarePage(pageNumber)
            let isFamilyMember = selectedUserCard.card == "member" ? '&family_member_id='+selectedUserCard.id:''; 
            homeCareCall(pageNumber, isFamilyMember, switchToken).then(function (response) {
                sethomeCare(homeCareData.concat(response.data.appointments));
                setHomeCareLoder(false)

            })
        }
        else {
            setHomeCareHasMore(false)
        }
    }


    // For diagnostics Scroll More Data
    const diagnosticsFatchData = () => {
        if (currentTab === "Diagnostics" && diagnosticsPage < diagnosticsPagination.total_pages) {
            setDiagnosticsLoder(true)    
            let diaPageNumber;
            diaPageNumber = diagnosticsPage + 1
            setDiagnosticsPage(diaPageNumber)
    
            let isFamilyMember = selectedUserCard.card == "member" ? '&family_member_id='+selectedUserCard.id:''; 
            diagnosticsCall(diaPageNumber, isFamilyMember, switchToken).then(function (response) {
                setDiagnosticsData(diagnosticsData.concat(response.data.appointments));
                setDiagnosticsLoder(false)
            })
        }
        else {
            setDiagnosticsHaseMore(false)
        }
    }



    // For madicines Scroll More Data
    const madicinesFatchData = () => {       
        if (currentTab === "Medicines" && medicinesPage < medicinesPagination.total_pages) {
            setMedicinesLoder(true)
            let medPageNumber;
            medPageNumber = medicinesPage + 1
            setMedicinesPage(medPageNumber)   
            let isFamilyMember = selectedUserCard.card == "member" ? '&family_member_id='+selectedUserCard.id:'';      
            medicinesCall(medPageNumber, isFamilyMember, switchToken).then(function (response) {
                setMedicinesData(medicinesData.concat(response.data.order));
                setMedicinesLoder(false)
            })
        }
        else {
            setMedicinesHasMore(false)
        }

    }

    return (
        <React.Fragment>
            <Container className="min-body-height">

                <div className="row  align-items-center breadcrumb_sticky">
                    <div className="col-md-9">                 <Breadcrumb textUrl={breadcrumArrry} />     </div>
                    <div className="col-md-3 d-none d-md-block">
                        <Link to="/patient-dashboard" className="mhc-patient-back">
                            <div className="d-flex align-items-center justify-content-start justify-content-md-end">
                                <div><img src={dashboard} alt="" /></div>
                                <div className="fw-bolder ms-2">My Dashboard</div>
                            </div>
                        </Link>

                    </div>

                </div>
                <SwitchProfile />

                <div className="row mt-4">
                    <div className="col-12">
                        <h1 className="fs-4 mb-1 text-secondary">My Bookings</h1>
                        <p className="m-0 fs-6">View your upcoming or previous appointments</p>
                    </div>
                </div>
                <div className="mhc-tabs position-relative mt-4">
            
                    <Tabs                 
                     activeKey={currentTab}
                     onSelect={(key) => setCurrentTab(key)}
                    id="controlled-tab-example" className="mb-3">
                        <Tab eventKey="DoctorConsult" title="Doctor Consult">
                        <div className="row mt-3 d-none">
                <div className="col-lg-3">
                    <div className="d-flex flex-column flex-lg-row">
                        <div className="form-floating mhc-dob date mb-4">
                        <DatePicker    calendarClassName="mhc-input-date"   dateFormat="dd/MM/yyyy" selected={startDate}  onChange={(date) => handleDateApi(date)}
        // minDate={moment().toDate()}
        maxDate={moment().toDate()}
  calendarStartDay={1}
   formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
   placeholderText={new Date()}     
  />
                            <i className="mhc-icon icon-calendar"></i>
                            <label htmlFor="Dob" className="dob">Start Date</label>
                        </div>
                        <div className="form-floating mhc-dob date mb-4 ms-0 ms-lg-3">
                        <DatePicker    calendarClassName="mhc-input-date"   dateFormat="dd/MM/yyyy" selected={endDate}  onChange={(date) => handleDateApi1(date)}
        minDate={startDate}
        // maxDate={moment().toDate()}
        calendarStartDay={1}  
        formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)} 
        placeholderText={new Date()}     
       />
                            <i className="mhc-icon icon-calendar"></i>
                            <label htmlFor="Dob" className="dob">End Date</label>
                        </div>
                    </div>

                </div>
                <div className="col-lg-3">
                    <div className="d-flex flex-column flex-lg-row">
                      <button type="submit" className="btn btn-primary" onClick={()=>getFilterData()}>Go</button>
                      <button type="submit" className="btn btn-primary ms-2" onClick={()=>refreshData()}>Refresh</button>
                        </div>
                        </div>
                </div>
                            {
                            appointmentData?.length == 0 ? <h6 className="text-center mt-2 mb-4">No Data Found</h6> :
                            appointmentData === undefined ? <LodingComponent className="mhc-Loding-minHeight" /> :  <div id="scrollableDiv" className="mhc-scrollDiv"><InfiniteScroll
                                dataLength={appointmentData.length}
                                next={appFetchMoreData}
                                hasMore={appHasMore}
                                loader={appAppointmentsLoader == true ? <LodingComponent className="text-center" /> : ''}
                                scrollableTarget="scrollableDiv"
                                endMessage={apppagination.total_pages > 1 ? <NoMoreData className="text-center mb-3" /> : ''}
                    
                           >
                                {
                                    <DoctorConsult dectorConsultData={appointmentData} />
                                }
                        
                            </InfiniteScroll>
                            </div> 
                            }

                        </Tab>


                        <Tab eventKey="Homecare" title="Homecare">
                            {
                            homeCareData?.length == 0 ? <h6 className="text-center mt-2 mb-4">No Data Found</h6> :
                            homeCareData === undefined ? <LodingComponent className="mhc-Loding-minHeight" /> : <div id="scrollableDiv1" className="mhc-scrollDiv"><InfiniteScroll
                                dataLength={homeCareData.length}
                                next={HomeCareFatchData}
                                hasMore={homeCareHasMore}
                                loader={homeCareLoder == true ? <LodingComponent className="text-center" /> : ''}
                                scrollableTarget="scrollableDiv1"
                                endMessage={homeCarePagination.total_pages > 1 ? <NoMoreData className="text-center mb-3" /> : ''}
                            >
                                {
                                    <PatientHomeCare PatientHomeCareData={homeCareData} />
                                }

                            </InfiniteScroll>
                          </div>
                            
                            }
                   
                        </Tab>


{/*                         
                        <Tab eventKey="Diagnostics" title="Diagnostics" >

                            {
                            diagnosticsData?.length == 0  ? <h6 className="text-center mt-2 mb-4">No Data Found</h6> : 
                            diagnosticsData === undefined ? <LodingComponent className="mhc-Loding-minHeight" /> : <div id="scrollableDiv2" className="mhc-scrollDiv"><InfiniteScroll
                                dataLength={diagnosticsData.length}
                                next={diagnosticsFatchData}
                                hasMore={diagnosticsHaseMore}
                                loader={diagnosticsLoder == true ? <LodingComponent className="text-center" /> : ''}
                                scrollableTarget="scrollableDiv2"
                                endMessage={diagnosticsPagination.total_pages > 1 ? <NoMoreData className="text-center mb-3" /> : ''}
                            >
                                {
                                    <PatientDiagnostics diagnosticsData={diagnosticsData} />
                                }

                            </InfiniteScroll>

                                </div>
                            }

                        </Tab>
                        <Tab eventKey="Medicines" title="Medicines" >
                            {
                            medicinesData === undefined || medicinesData?.length == 0 ? <h6 className="text-center mt-2 mb-4">No Data Found</h6> :
                            medicinesData === undefined ? <LodingComponent className="mhc-Loding-minHeight" /> : <div id="scrollableDiv3" className="mhc-scrollDiv"><InfiniteScroll
                                dataLength={medicinesData.length}
                                next={madicinesFatchData}
                                hasMore={medicinesHasMore}
                                loader={medicinesLoder == true ? <LodingComponent className="text-center" /> : ''}
                                scrollableTarget="scrollableDiv3"
                                endMessage={<NoMoreData className="text-center mb-3" />}
                            >
                                {
                                    <PatientMedicines PatientMedicines={medicinesData} />
                                }

                            </InfiniteScroll>

                            </div>
                            }

                        </Tab> */}

                        
                    </Tabs>


                </div>

            </Container>
        </React.Fragment>
    )
}
export default PatientMyBooking;